import React, { useEffect, useState } from 'react';
import DefaultLayout from '../components/DefaultLayout';
import styled from 'styled-components';
import { Tree, TreeNode } from 'react-organizational-chart';
import Loading from '../components/Loading';
import LoadingSpinner from '../components/LoadingSpinner';
import LoadingSpinner2 from '../components/LoadingSpinner2';
import ScaleLoader from 'react-spinners/ScaleLoader';
import { Col, Modal, Row } from 'antd';
import { FaPlus, FaTimes } from 'react-icons/fa';
import { BiUpArrow } from 'react-icons/bi';
import '../style/binary.css';
import { useDispatch, useSelector } from 'react-redux';
import { getUpTopIdUplineUser, reset } from '../features/users/usersSlice';

import { useNavigate, useParams } from 'react-router-dom';

import imgTester from '../assets/icon/user-tester.png';
import ImgAssitant from '../assets/icon/assitant.png';
import ImgDirector from '../assets/icon/director.png';
import ImgPresident from '../assets/icon/president.png';
import ImgUserMember1 from '../assets/icon/user1.png';
import ImgUserMember2 from '../assets/icon/user2.png';
import ImgUserMobile from '../assets/icon/siamprai-icon2.png';
import ImgUserMember3 from '../assets/icon/user3.png';
import ImgUserMember7 from '../assets/icon/user7.png';

import { ImArrowDownLeft2, ImArrowDownRight2 } from 'react-icons/im';

import { BsFillArrowUpCircleFill } from 'react-icons/bs';
import { useMediaQuery } from 'react-responsive';

import SearchForBinary from '../components/form/SearchForBinary';
import commaNumber from 'comma-number';
import {
   CheckDownLine,
   getLiveScore,
   getLiveScore1,
   getLiveScore2,
   getLiveScore4,
   getLiveScore5,
   getLiveScore6,
   getLiveScore7,
   getUpline1,
   getUpline2,
   getUpline3,
   getUpline4,
   getUpline5,
   getUserBottomL,
   getUserBottomR,
   reset as uplineReset,
} from '../features/upline/uplineSlice';

const BinaryView3 = () => {
   const navigate = useNavigate();
   const [lastTouchTime, setLastTouchTime] = useState(0);

   const [modal, setModal] = useState(false);

   const [cursorPosition, setCursorPosition] = useState({ x: 0, y: 0 });

   const { userId, username } = useParams();

   const isValidUserId = !isNaN(userId);

   const [screenWidth, setScreenWidth] = useState(window.innerWidth);

   localStorage.removeItem('recomName');
   localStorage.removeItem('recomId');

   const [loadingPage, setLoadingPage] = useState(true);

   const dispatch = useDispatch();
   const { userInfo } = useSelector((state) => state.auth);

   let userIdUp = '';

   if (userId) {
      userIdUp = userId;
   }

   const sendDataToParent = (i) => {
      userIdUp = i.userId;

      navigate(`/binary/${i.userId}/${i.name}/${i.parentId}`);
      window.location.reload();
   };

   const isLaptop = useMediaQuery({ minWidth: 768, maxWidth: 1024 });
   const isTablet = useMediaQuery({ maxWidth: 770 });
   const isMobile = useMediaQuery({ maxWidth: 539 });

   const {
      isLiveScoreLoading,
      isLiveScoreLoading2,
      isLiveScoreLoading1,
      userBottom,
      upline1,
      upline2,
      upline3,
      upline4,
      upline5,
      liveScore,
      liveScore1,
      liveScore2,
      liveScore4,
      liveScore5,
      liveScore6,
      liveScore7,
      isLoadingUpline1,
      isLoadingUpline2,
      isLoadingUpline3,
      isLoadingUpline4,
      isLoadingUpline5,
      isError,
      message,
   } = useSelector((state) => state.upline);

   const { UpTopIdUplineUser } = useSelector((state) => state.user);

   useEffect(() => {
      dispatch(CheckDownLine(userIdUp));
   }, [dispatch]);

   useEffect(() => {
      if (isError) {
         setModal(true);
      }
      if (userBottom?.userId) {
         navigate(
            `/binary/${userBottom?.userId}/${userBottom?.name}/${userBottom?.parentId?.userId}`
         );
         window.location.reload();
      }
   }, [userBottom, navigate, isError]);

   const handleGoBackOneStep = () => {
      if (upline1?.uplineData?.user?.userId) {
         if (
            UpTopIdUplineUser?.userId !== undefined &&
            UpTopIdUplineUser !== null
         ) {
            if (upline1?.uplineData?.user?.userId !== userInfo?.userId) {
               navigate(
                  `/binary/${UpTopIdUplineUser.userId}/${UpTopIdUplineUser.name}`
               );
               window.location.reload();
            }
         }
      }
   };

   useEffect(() => {
      const handleMouseMove = (event) => {
         setCursorPosition({ x: event.clientX, y: event.clientY });
      };

      document.addEventListener('mousemove', handleMouseMove);

      return () => {
         document.removeEventListener('mousemove', handleMouseMove);
      };
   }, []);

   useEffect(() => {
      if (!userInfo) {
         navigate('/login');
      }
      if (loadingPage) {
         setTimeout(() => {
            setLoadingPage(false);
         }, 3000);
      }

      const handleWindowWidth = () => {
         setScreenWidth(window.innerWidth);
         window.addEventListener('resize', handleWindowWidth);

         return () => {
            window.removeEventListener('resize', handleWindowWidth);
         };
      };

      dispatch(getUpline1(userIdUp));
      dispatch(getLiveScore(userId));

      return () => {
         dispatch(reset());
         dispatch(uplineReset());
      };
   }, [dispatch]);

   useEffect(() => {
      if (upline1.uplineLeftData?.user?.userId) {
         const userId = upline1.uplineLeftData?.user?.userId;
         dispatch(getLiveScore1(userId));
      }

      dispatch(getUpTopIdUplineUser(userId));

      if (upline1.uplineRightData?.user?.userId) {
         const userId = upline1.uplineRightData?.user?.userId;
         dispatch(getLiveScore2(userId));
      }

      if (upline1.uplineLeftDataLevel31?.user?.userId) {
         const userId = upline1.uplineLeftDataLevel31?.user?.userId;
         dispatch(getLiveScore4(userId));
      }
      if (upline1.uplineLeftDataLevel32?.user?.userId) {
         const userId = upline1.uplineLeftDataLevel32?.user?.userId;
         dispatch(getLiveScore5(userId));
      }

      if (upline1.uplineRightDataLevel31?.user?.userId) {
         const userId = upline1.uplineRightDataLevel31?.user?.userId;
         dispatch(getLiveScore6(userId));
      }
      if (upline1.uplineRightDataLevel32?.user?.userId) {
         const userId = upline1.uplineRightDataLevel32?.user?.userId;
         dispatch(getLiveScore7(userId));
      }

      if (upline1.uplineLeftDataLevel31?.user.userId) {
         const userId = upline1.uplineLeftDataLevel31.user.userId;

         dispatch(getUpline2(userId));
      }

      if (upline1.uplineLeftDataLevel32?.user.userId) {
         const userId = upline1.uplineLeftDataLevel32.user.userId;

         dispatch(getUpline3(userId));
      }

      if (upline1.uplineRightDataLevel31?.user.userId) {
         const userId = upline1.uplineRightDataLevel31.user.userId;

         dispatch(getUpline4(userId));
      }

      if (upline1.uplineRightDataLevel32?.user.userId) {
         const userId = upline1.uplineRightDataLevel32.user.userId;

         dispatch(getUpline5(userId));
      }
   }, [upline1, dispatch]);

   let userLevel2Left = {
      userId: '',
      name: '',
      pvLivePointLeft: 0,
      pvLivePointRight: 0,
      pvPointPreviosLeft: 0,
      pvPointPreviosRight: 0,
      recommendedBy: '',
   };
   let userLevel2Right = {
      userId: '',
      name: '',
      pvLivePointLeft: 0,
      pvLivePointRight: 0,
      pvPointPreviosLeft: 0,
      pvPointPreviosRight: 0,
      recommendedBy: '',
   };

   const renderImage = (props) => {
      if (props.position === 'Assistant') {
         return (
            <img
               alt='img'
               style={{
                  borderRadius: '50%',
               }}
               src={ImgAssitant}
            />
         );
      } else if (props.position === 'Director') {
         // Add the rendering logic for Director
         return (
            <>
               <img
                  alt='img'
                  style={{
                     borderRadius: '50%',
                  }}
                  src={ImgDirector}
               />
            </>
         );
      } else if (props.position === 'President') {
         // Add the rendering logic for President
         return (
            <img
               alt='img'
               style={{
                  borderRadius: '50%',
               }}
               src={ImgPresident}
            />
         );
      } else {
         return (
            <>
               {props.userId ? (
                  <>
                     {props.role === 'Member' ? (
                        <img
                           alt='img'
                           style={{
                              borderRadius: '50%',
                           }}
                           src={ImgUserMember2}
                        />
                     ) : props.role === 'Holder' ? (
                        <img
                           alt='img'
                           style={{
                              borderRadius: '50%',
                           }}
                           src={
                              props.maxMatching === 10
                                 ? ImgUserMember1
                                 : props.maxMatching === 15
                                 ? ImgUserMember3
                                 : props.maxMatching === 20
                                 ? ImgUserMember7
                                 : props.isMobileGetPaid
                                 ? ImgUserMobile
                                 : props.maxMatching === 25
                                 ? ImgUserMember1
                                 : props.maxMatching === 8
                                 ? ImgUserMember2
                                 : imgTester
                           }
                        />
                     ) : (
                        <img
                           alt='img'
                           style={{
                              borderRadius: '50%',
                           }}
                           src={imgTester}
                        />
                     )}
                  </>
               ) : (
                  <img
                     alt='img'
                     style={{
                        borderRadius: '50%',
                     }}
                     src={ImgUserMember1}
                  />
               )}
            </>
         );
      }
   };

   const card = (props) => (
      <>
         <div
            className='styleNode'
            style={{ opacity: props.expired > 0 ? '1' : '0.5' }}
         >
            <Row>
               <Col span={24}>
                  <Row>
                     <Col span={1}>
                        {' '}
                        <p
                           style={{ fontSize: '10px' }}
                           className='text-danger mt-3'
                        >
                           {props.matching}/{props.maxMatching}
                        </p>
                        <p style={{ fontSize: '10px' }}>{props.expired} วัน</p>
                     </Col>
                     <Col span={23}>
                        {' '}
                        {upline1.uplineData ? (
                           <></>
                        ) : (
                           <>
                              <FaPlus />
                           </>
                        )}
                        <b style={{ fontSize: '12px' }}> {props.userId} </b>
                        {renderImage(props)}
                     </Col>
                  </Row>
               </Col>

               <Col span={24} style={{ marginTop: '-10px' }}>
                  <b style={{ fontSize: '8px' }}>{props.name}</b>
               </Col>
            </Row>
         </div>
      </>
   );

   let test2 = false;
   let NameOfLevel2 = '';
   let userIdOfLevel2 = '';
   let IdOfLevel2 = '';
   const cardLevel2 = ({
      name,
      userId,
      side,
      isMobileGetPaid,
      user_id,
      memberKeys,
      pvLeft,
      pvRight,
      pvPrevRight,
      pvPrevLeft,
      userSideId,
      userSideName,
      depth,
      sumLeft,
      sumRight,
      userRec,
      userRecName,
      matching,
      maxMatching,
      expired,
      position,
      isPaid,
      role,
      isBanned,
   }) => {
      if (name) {
         test2 = true;
         NameOfLevel2 = name;
         userIdOfLevel2 = userId;
      }

      const parentId = userIdUp;
      const parentName = username;

      let userSideId2 = 'null';
      let userSideName2 = 'null';
      if (userSideId && userSideName) {
         userSideId2 = userSideId;
         userSideName2 = userSideName;
      }

      return (
         <>
            <div
               id='hover1'
               style={{ opacity: expired > 0 ? '1' : '0.5' }}
               // style={{ border: name === '' ? '0.5px solid black' : '' }}
               className={`styleNode-2`}
               // onTouchStart={() => handleTouchStart(userId,name,user_id,memberKeys,userIdOfLevel2)}
               onTouchStart={(event) => {
                  //   console.log('1');
                  const currentTime = new Date().getTime();
                  const touchTime = currentTime - lastTouchTime;
                  if (touchTime < 500) {
                     // if the previous touch was less than 500ms ago, treat as double-click
                     handleDoubleClick(event);
                  }
                  setLastTouchTime(currentTime);

                  function handleDoubleClick(event) {
                     // handle the double-click event here
                     navigate(`/binary/${userId}/${name}/${parentId}`);
                     window.location.reload();
                  }
               }}
               onClick={() => {
                  if (isMobile) {
                     if (name) {
                     } else {
                        navigate(
                           `/register/${parentId}/${parentName}/${side}/${userSideId2}/${userSideName2} `
                        );
                     }
                  } else {
                     if (name) {
                        navigate(`/binary/${userId}/${name}/${parentId}`);
                        window.location.reload();
                     } else {
                        navigate(
                           `/register/${parentId}/${parentName}/${side}/${userSideId2}/${userSideName2} `
                        );
                     }
                  }
               }}
            >
               <Row style={{ marginTop: name ? '3px' : '' }}>
                  <Col span={24}>
                     <Row style={{ marginTop: name ? '-7px' : '15px' }}>
                        <Col span={5}>
                           {' '}
                           {matching >= 0 ? (
                              <p
                                 style={{
                                    fontSize: '8px',
                                    margin: '0',
                                 }}
                                 className='text-danger mt-3'
                              >
                                 {matching}/{maxMatching}
                              </p>
                           ) : null}
                           {depth >= 0 ? (
                              <p
                                 style={{
                                    fontSize: '6px',
                                    color: 'blue',
                                    margin: '0',
                                 }}
                              >
                                 {depth + 1}
                              </p>
                           ) : null}
                           {expired ? (
                              <p
                                 style={{
                                    fontSize: '7px',
                                    margin: '0',
                                    marginBottom: '3px',
                                 }}
                              >
                                 {' '}
                                 {expired}วัน
                              </p>
                           ) : (
                              <p
                                 style={{
                                    fontSize: '7px',
                                    margin: '0',
                                    marginBottom: '3px',
                                 }}
                              >
                                 {' '}
                                 {expired}
                              </p>
                           )}
                        </Col>
                        <Col span={19}>
                           {' '}
                           {name ? (
                              <></>
                           ) : (
                              <>
                                 <FaPlus />
                              </>
                           )}
                           <b style={{ fontSize: '14px' }}> {userId} </b>
                           {isBanned && <FaTimes color='red' size={64} />}
                           {userId ? (
                              <>
                                 {renderImage({
                                    userId,
                                    role,
                                    position,
                                    maxMatching,
                                    isMobileGetPaid,
                                 })}
                              </>
                           ) : (
                              <></>
                           )}
                           {name ? (
                              <>
                                 <p className='Level2Name'>{name}</p>
                              </>
                           ) : null}
                        </Col>
                     </Row>
                  </Col>
               </Row>
            </div>
            {name && (
               <div
                  id='popuphover1'
                  className='subData text-start'
                  style={{
                     top: cursorPosition.y + 20,
                     left: isMobile ? '30%' : cursorPosition.x + 20,
                  }}
               >
                  <p>
                     สมาชิก: {userId} [{name}]
                  </p>
                  <p>รหัส: {role}</p>
                  <p>อายุ: {expired} วัน</p>
                  <p>
                     ผู้แนะนำ: {userRec} {userRecName}{' '}
                  </p>
                  {depth ? <p> ชั้นที่ {depth + 1} </p> : null}

                  <p>
                     ซ้ายเก่า: {pvPrevLeft > 1 ? pvPrevLeft : 0},ขวาเก่า:{' '}
                     {pvPrevRight > 1 ? pvPrevRight : 0}
                  </p>
                  <p className='text-primary'>
                     ซ้ายใหม่: {pvLeft > 1 ? pvLeft : 0} ,ขวาใหม่:
                     {pvRight > 1 ? pvRight : 0}
                  </p>
                  <p className='text-danger'>
                     ซ้ายรวม: {pvLeft + pvPrevLeft} ,ขวารวม:{' '}
                     {pvRight + pvPrevRight}{' '}
                  </p>
               </div>
            )}
         </>
      );
   };

   let test3 = false;
   let NameOfLevel3 = '';
   let userIdOfLevel3 = '';
   let idOfLevel3 = '';
   const cardLevel3 = (props) => {
      let name = '';
      let userId = '';

      if (props.side === 'right') {
         name = userLevel2Right.name;
         userId = userLevel2Right.userId;
      } else if (props.side === 'left') {
         name = userLevel2Left.name;
         userId = userLevel2Left.userId;
      }

      if (props.name) {
         test3 = true;
         NameOfLevel3 = props.name;
         userIdOfLevel3 = props.userId;
      }
      let userSideId = 'null';
      let userSideName = 'null';
      if (props.userSideId && props.userSideName) {
         userSideId = props.userSideId;
         userSideName = props.userSideName;
      }

      return (
         <>
            <div
               style={{
                  pointerEvents: props.parentName ? '' : 'none',
                  opacity: props.expired > 0 ? '1' : '0.5',
               }}
               className={`styleNode-3 ${
                  props.parentName ? 'cursor-pointer' : ''
               } `}
               onTouchStart={(event) => {
                  const currentTime = new Date().getTime();
                  const touchTime = currentTime - lastTouchTime;
                  if (touchTime < 500) {
                     // if the previous touch was less than 500ms ago, treat as double-click
                     handleDoubleClick(event);
                  }
                  setLastTouchTime(currentTime);

                  function handleDoubleClick(event) {
                     // handle the double-click event here

                     navigate(
                        `/binary/${props.userId}/${props.name}/${props.parentId}`
                     );
                     window.location.reload();
                  }
               }}
               onClick={() => {
                  if (isMobile) {
                     if (props.name) {
                     } else {
                        navigate(
                           `/register/${props.parentId}/${props.parentName}/${props.side}/${userSideId}/${userSideName} `
                        );
                     }
                  } else {
                     if (props.name) {
                        navigate(
                           `/binary/${props.userId}/${props.name}/${props.parentId}`
                        );
                        window.location.reload();
                     } else {
                        navigate(
                           `/register/${props.parentId}/${props.parentName}/${props.side}/${userSideId}/${userSideName} `
                        );
                     }
                  }
               }}
            >
               <Row>
                  <Col span={24}>
                     <Row style={{ marginTop: props.name ? '-7px' : '15px' }}>
                        <Col span={4}>
                           {' '}
                           {props.matching >= 0 ? (
                              <p
                                 style={{ fontSize: '8px', margin: '0' }}
                                 className='text-danger mt-3'
                              >
                                 {props.matching}/{props.maxMatching}
                              </p>
                           ) : (
                              <>
                                 {props.name && (
                                    <p
                                       style={{ fontSize: '8px', margin: '0' }}
                                       className='text-danger mt-3'
                                    >
                                       0/0
                                    </p>
                                 )}
                              </>
                           )}
                           {props.depth >= 0 ? (
                              <p
                                 style={{
                                    fontSize: '6px',
                                    color: 'blue',
                                    margin: '0',
                                 }}
                              >
                                 {props.depth + 1}
                              </p>
                           ) : null}
                           {props.expired ? (
                              <p
                                 style={{
                                    fontSize: '7px',
                                    margin: '0',
                                    marginBottom: '3px',
                                 }}
                              >
                                 {' '}
                                 {props.expired}วัน
                              </p>
                           ) : (
                              <p
                                 style={{
                                    fontSize: '7px',
                                    margin: '0',
                                    marginBottom: '3px',
                                 }}
                              >
                                 {' '}
                                 {props.expired}
                              </p>
                           )}
                        </Col>
                        <Col span={20}>
                           {' '}
                           {props.name ? (
                              <></>
                           ) : (
                              <>
                                 <FaPlus />
                              </>
                           )}
                           <b style={{ fontSize: '12px' }}> {props.userId} </b>
                           {props.userId ? <>{renderImage(props)}</> : <></>}
                           {props.name ? (
                              <>
                                 <p className='level3Name'>{props.name}</p>
                              </>
                           ) : null}
                        </Col>
                        {/* <Col
                         span={24}
                         style={{
                            marginTop: props.depth >= 0 ? '-10px ' : '0',
                         }}></Col> */}
                     </Row>
                  </Col>
               </Row>
            </div>

            {props.name && (
               <div
                  className='subData text-start'
                  style={{
                     top: cursorPosition.y + 20,
                     // left: cursorPosition.x + 20,
                     left: isMobile
                        ? '30%'
                        : props.farRight
                        ? cursorPosition.x + -200
                        : cursorPosition.x + -150,
                  }}
               >
                  <p>
                     สมาชิก: {props.userId} {props.name}{' '}
                  </p>
                  <p>รหัส: {props.role}</p>
                  <p>อายุ: {props.expired} วัน</p>
                  <p>
                     อัพไลน์: {props.parentId} {props.parentName}
                  </p>

                  <p>
                     รหัสแนะนำ: <span> {props.userRec} </span>
                  </p>
                  <p>
                     ขื่อแนะนำ: <span> {props.userRecName} </span>
                  </p>

                  {/* <p> {props.depth} </p>
                  {props.depth ? <p> ชั้นที่ {props.depth + 1} </p> : null} */}

                  <p>
                     {' '}
                     ซ้ายเก่า:{props.pvPrevLeft > 1 ? props.pvPrevLeft : 0}{' '}
                     ,ขวาเก่า:
                     {props.pvPrevRight > 1 ? props.pvPrevRight : 0}{' '}
                  </p>
                  <p className='text-primary'>
                     {' '}
                     ซ้ายใหม่:{props.pvLeft > 1 ? props.pvLeft : 0} ,ขวาใหม่:
                     {props.pvRight > 1 ? props.pvRight : 0}{' '}
                  </p>
                  <p className='text-danger'>
                     ซ้ายรวม: {props.pvLeft + props.pvPrevLeft} ,ขวารวม:{' '}
                     {props.pvRight + props.pvPrevRight}{' '}
                  </p>
               </div>
            )}
         </>
      );
   };

   let test5 = false;
   let NameOfLevel4 = '';
   let userIdOfLevel4 = '';
   let idOfLevel4 = '';
   const cardLevel4 = (props) => {
      if (props.name) {
         test5 = true;
         NameOfLevel4 = props.name;
         userIdOfLevel4 = props.userId;
      }
      let userSideId = 'null';
      let userSideName = 'null';
      if (props.userSideId && props.userSideName) {
         userSideId = props.userSideId;
         userSideName = props.userSideName;
      }

      return (
         <>
            <div
               style={{
                  pointerEvents: props.parentName ? '' : 'none',
                  opacity: props.expired > 0 ? '1' : '0.5',
               }}
               className={`styleNode-4 ${
                  props.parentName ? 'cursor-pointer' : ''
               } `}
               onTouchStart={(event) => {
                  const currentTime = new Date().getTime();
                  const touchTime = currentTime - lastTouchTime;
                  if (touchTime < 500) {
                     // if the previous touch was less than 500ms ago, treat as double-click
                     handleDoubleClick(event);
                  }
                  setLastTouchTime(currentTime);

                  function handleDoubleClick(event) {
                     // handle the double-click event here
                     navigate(
                        `/binary/${props.userId}/${props.name}/${props.parentId}`
                     );
                     window.location.reload();
                  }
               }}
               onClick={() => {
                  if (isMobile) {
                     if (props.name) {
                     } else {
                        navigate(
                           `/register/${props.parentId}/${props.parentName}/${props.side}/${userSideId}/${userSideName} `
                        );
                     }
                  } else {
                     if (props.name) {
                        navigate(
                           `/binary/${props.userId}/${props.name}/${props.parentId}`
                        );
                        window.location.reload();
                     } else {
                        navigate(
                           `/register/${props.parentId}/${props.parentName}/${props.side}/${userSideId}/${userSideName} `
                        );
                     }
                  }
               }}
            >
               <Row style={{ marginTop: props.name ? '-3px' : '9px' }}>
                  <Col span={24}>
                     <Row style={{ marginTop: props.name ? '-3px' : '' }}>
                        {isMobile ? null : (
                           <Col span={4}>
                              {' '}
                              {props.matching >= 0 ? (
                                 <p
                                    style={{
                                       fontSize: '8px',
                                       margin: '0',
                                       zIndex: '99',
                                    }}
                                    className='text-danger mt-3'
                                 >
                                    {props.matching}/{props.maxMatching}
                                 </p>
                              ) : null}
                              {props.depth >= 0 ? (
                                 <p
                                    style={{
                                       fontSize: '6px',
                                       color: 'blue',
                                       margin: '0',
                                       zIndex: '99',
                                    }}
                                 >
                                    {props.depth + 1}
                                 </p>
                              ) : null}
                              {props.expired ? (
                                 <p
                                    style={{
                                       fontSize: '7px',
                                       margin: '0',
                                       marginBottom: '3px',
                                       zIndex: '99',
                                    }}
                                 >
                                    {' '}
                                    {props.expired}วัน
                                 </p>
                              ) : (
                                 <p
                                    style={{
                                       fontSize: '7px',
                                       margin: '0',
                                       marginBottom: '3px',
                                       zIndex: '99',
                                    }}
                                 >
                                    {' '}
                                    {props.expired}
                                 </p>
                              )}
                           </Col>
                        )}
                        {isMobile ? (
                           <Col
                              style={{ top: props.name ? '-7px' : '' }}
                              span={24}
                           >
                              {props.name ? (
                                 <></>
                              ) : (
                                 <>
                                    <FaPlus
                                       style={{ marginTop: '12px !important' }}
                                    />
                                 </>
                              )}

                              <b style={{ fontSize: '8px' }}>
                                 {' '}
                                 {props.userId}{' '}
                              </b>

                              {props.userId ? <>{renderImage(props)}</> : <></>}
                              {props.name ? (
                                 <>
                                    <p
                                       style={{
                                          fontSize: '7px',
                                       }}
                                       className='level4Name'
                                    >
                                       {props.name}
                                    </p>
                                 </>
                              ) : null}
                              <div className='subData'></div>
                           </Col>
                        ) : (
                           <Col
                              span={20}
                              style={{
                                 marginTop:
                                    screenWidth >= 768 && screenWidth <= 1024
                                       ? '-5px'
                                       : '5px',
                              }}
                           >
                              {props.name ? (
                                 <></>
                              ) : (
                                 <>
                                    <FaPlus
                                       className='mt-2'
                                       style={{ marginTop: '12px !important' }}
                                    />
                                 </>
                              )}

                              <b style={{ fontSize: '8px' }}>
                                 {' '}
                                 {props.userId}{' '}
                              </b>

                              <>
                                 {props.userId ? (
                                    <>{renderImage(props)}</>
                                 ) : (
                                    // <img
                                    //    alt='img'
                                    //    style={{
                                    //       borderRadius: '50%',
                                    //    }}
                                    //    src={ImgUserMember1}
                                    // />
                                    <></>
                                 )}
                              </>

                              <div className='subData'></div>
                           </Col>
                        )}
                     </Row>
                     {!isMobile && (
                        <Row>
                           <Col span={24}>
                              {' '}
                              {props.name ? (
                                 <>
                                    <p
                                       style={{
                                          fontSize: '7px',
                                       }}
                                    >
                                       {props.name}
                                    </p>
                                 </>
                              ) : (
                                 <></>
                              )}{' '}
                           </Col>
                        </Row>
                     )}
                  </Col>
               </Row>
            </div>
            {props.name && (
               <div
                  className='subData text-start'
                  style={{
                     top: cursorPosition.y + 20,
                     //     left: cursorPosition.x + 20,
                     left: isMobile
                        ? '30%'
                        : props.farRight
                        ? cursorPosition.x + -300
                        : cursorPosition.x + -150,
                  }}
               >
                  <p>
                     สมาชิก: {props.userId} [{props.name}]{' '}
                  </p>
                  <p>รหัส: {props.role}</p>
                  <p>อายุ: {props.expired} วัน</p>
                  <p>อัพไลน์: {props.parentId}</p>
                  <p>รหัสแนะนำ: {props.userRec}</p>
                  <p>ชื่อแนะนำ: {props.userRecName}</p>
                  {props.depth ? <p> ชั้นที่ {props.depth + 1} </p> : null}

                  <p>
                     คะแนนซ้าย: {props.pvPrevLeft > 1 ? props.pvPrevLeft : 0}{' '}
                     ,คะแนนขวา: {props.pvPrevRight > 1 ? props.pvPrevRight : 0}
                  </p>
               </div>
            )}
         </>
      );
   };

   const cardLevel5 = (props) => {
      let userSideId = 'null';
      let userSideName = 'null';
      if (props.userSideId && props.userSideName) {
         userSideId = props.userSideId;
         userSideName = props.userSideName;
      }

      return (
         <>
            <div
               style={{
                  pointerEvents: props.parentName ? '' : 'none',
                  opacity: props.expired > 0 ? '1' : '0.5',
               }}
               className={`styleNode-5 ${
                  props.parentName ? 'cursor-pointer' : ''
               } `}
               onTouchStart={(event) => {
                  const currentTime = new Date().getTime();
                  const touchTime = currentTime - lastTouchTime;
                  if (touchTime < 500) {
                     // if the previous touch was less than 500ms ago, treat as double-click
                     handleDoubleClick(event);
                  }
                  setLastTouchTime(currentTime);

                  function handleDoubleClick(event) {
                     // handle the double-click event here
                     navigate(
                        `/binary/${props.userId}/${props.name}/${props.parentId}`
                     );
                     window.location.reload();
                  }
               }}
               onClick={() => {
                  if (isMobile) {
                     if (props.name) {
                        // navigate(
                        //    `/binary/${props.userId}/${props.name}/${props.parentId}`
                        // );
                        // window.location.reload();
                     } else {
                        navigate(
                           `/register/${props.parentId}/${props.parentName}/${props.side}/${userSideId}/${userSideName} `
                        );
                     }
                  } else {
                     if (props.name) {
                        navigate(
                           `/binary/${props.userId}/${props.name}/${props.parentId}`
                        );
                        window.location.reload();
                     } else {
                        navigate(
                           `/register/${props.parentId}/${props.parentName}/${props.side}/${userSideId}/${userSideName} `
                        );
                     }
                  }
               }}
            >
               <Row style={{ marginTop: isLaptop && '-4px' }}>
                  <Row>
                     {isTablet ? null : (
                        <Col span={6}>
                           {props.depth >= 0 ? (
                              <p
                                 style={{
                                    fontSize: '10px',
                                    color: 'blue',
                                    marginTop: '7px',
                                    marginBottom: '3px',
                                 }}
                              >
                                 {props.depth + 1}
                              </p>
                           ) : (
                              <p style={{ opacity: '0', margin: '0' }}>0</p>
                           )}
                           {props.expired ? (
                              <p
                                 style={{
                                    fontSize: '7px',
                                    margin: '0',
                                    marginBottom: '3px',
                                 }}
                              >
                                 {' '}
                                 {props.expired}วัน
                              </p>
                           ) : (
                              <p style={{ opacity: '0', margin: '0' }}>0</p>
                           )}
                        </Col>
                     )}
                     <Col
                        style={{ marginTop: props.name ? '-8px' : '' }}
                        span={18}
                     >
                        {' '}
                        {props.name ? (
                           <> {null}</>
                        ) : (
                           <>
                              <FaPlus style={{ marginTop: '7px' }} />
                           </>
                        )}
                        <b style={{ fontSize: '8px' }}> {props.userId} </b>
                        {isTablet ? (
                           <>
                              {props.userId ? (
                                 <> {renderImage(props)}</>
                              ) : (
                                 <></>
                              )}
                           </>
                        ) : (
                           <>
                              {props.userId ? (
                                 <> {renderImage(props)}</>
                              ) : (
                                 <></>
                              )}
                           </>
                        )}
                     </Col>
                  </Row>
                  <Col
                     span={24}
                     style={{
                        marginTop: props.depth >= 0 ? '-7px' : '0',
                     }}
                  ></Col>
               </Row>
            </div>
            {props.name && (
               <div
                  className='subData text-start'
                  style={{
                     top: cursorPosition.y + 20,
                     //left: cursorPosition.x + 20,
                     left: isMobile
                        ? '30%'
                        : props.farRight
                        ? cursorPosition.x + -350
                        : cursorPosition.x + -150,
                  }}
               >
                  <p>
                     สมาชิก: {props.userId} [{props.name}]{' '}
                  </p>
                  <p>รหัส: {props.role}</p>
                  <p>อายุ: {props.expired} วัน</p>
                  <p>อัพไลน์: {props.parentId}</p>
                  <p>รหัสแนะนำ: {props.userRec}</p>
                  <p>ชื่อแนะนำ: {props.userRecName}</p>
                  {props.depth >= 0 ? <p> ชั้นที่ {props.depth + 1} </p> : null}

                  <p>
                     คะแนนซ้าย: {props.pvPrevLeft > 1 ? props.pvPrevLeft : 0}{' '}
                     ,คะแนนเก่า: {props.pvPrevLeft > 1 ? props.pvPrevLeft : 0}
                  </p>
               </div>
            )}
         </>
      );
   };

   return (
      <DefaultLayout>
         <>
            {isLoadingUpline1 ? (
               <>
                  <Loading />
               </>
            ) : (
               <>
                  {upline1.uplineData !== userInfo.userId && (
                     <>
                        {!upline1.uplineData ? (
                           <Loading />
                        ) : (
                           <div
                              className='mx-4 d-flex'
                              style={{ justifyContent: 'space-evenly ' }}
                           >
                              {upline1.uplineData?.parentId?.userId !==
                              userInfo.parentId ? (
                                 <h3 className='text-center '>
                                    <BsFillArrowUpCircleFill
                                       style={{ cursor: 'pointer' }}
                                       onClick={() => {
                                          navigate(
                                             `/binary/${userInfo.userId}/${userInfo.username}`
                                          );
                                          window.location.reload();
                                       }}
                                    />
                                 </h3>
                              ) : null}

                              {upline1.uplineData?.parentId?.userId ===
                              userInfo.parentId ? null : (
                                 <h3 className='text-center ml-4'>
                                    <BiUpArrow
                                       style={{ cursor: 'pointer' }}
                                       onClick={handleGoBackOneStep}
                                    />
                                 </h3>
                              )}
                           </div>
                        )}
                     </>
                  )}
                  {!upline1.uplineData ? (
                     <Loading />
                  ) : (
                     <>
                        <div
                           style={{
                              position: 'relative',
                              background: 'white',
                              // zIndex: '99999',
                              // paddingTop: '50px',
                              // right: '0',
                              // paddingRight: '50px',
                           }}
                        >
                           {' '}
                           <SearchForBinary
                              sendDataToParent={sendDataToParent}
                           />
                        </div>
                        <div
                           style={{
                              paddingTop: '100px',
                              backgroundColor: 'white',
                           }}
                        ></div>
                        <div
                           style={{
                              position: 'relative',
                              overflow: 'hidden',
                              background: '#fff',
                              height: '100vh',
                           }}
                        >
                           {upline1.uplineData ? (
                              <>
                                 <PointR1>
                                    <p className='m-1 mr-3'>
                                       เก่า =
                                       <span className='m-1 fw-bold'>
                                          {commaNumber(
                                             upline1?.uplineData?.pointRight
                                          )}
                                       </span>
                                    </p>
                                    {isLiveScoreLoading ? (
                                       <>
                                          <p>Loading...</p>
                                       </>
                                    ) : (
                                       <>
                                          <p
                                             onClick={() =>
                                                navigate(
                                                   '/liveScoreDetail/right'
                                                )
                                             }
                                             className='m-1 mr-3 cursor-pointer '
                                          >
                                             ใหม่ =
                                             <span className='m-1 fw-bold text-primary'>
                                                {commaNumber(
                                                   liveScore?.pvLivePointRight
                                                )}
                                             </span>
                                          </p>
                                          <p className='m-1 mr-3'>
                                             รวม =
                                             <span className='m-1 fw-bold text-danger'>
                                                {liveScore?.pvLivePointRight !==
                                                   undefined &&
                                                liveScore?.pvLivePointRight !==
                                                   null ? (
                                                   <span className='m-1 fw-bold text-danger'>
                                                      {commaNumber(
                                                         liveScore.pvLivePointRight +
                                                            (upline1?.uplineData
                                                               ?.pointRight ||
                                                               0)
                                                      )}
                                                   </span>
                                                ) : (
                                                   <span className='m-1 fw-bold text-danger'>
                                                      0
                                                   </span>
                                                )}
                                             </span>
                                          </p>
                                       </>
                                    )}
                                 </PointR1>
                                 <Tree
                                    lineWidth={'.5px'}
                                    lineHeight={'20px'}
                                    nodePadding={'1px'}
                                    lineColor={'black'}
                                    lineBorderRadius={'1px'}
                                    label={card({
                                       position:
                                          upline1.uplineData?.user.position,
                                       // isPaid: upline1.uplineData?.user.isPaid,
                                       name: upline1.uplineData?.user?.name,
                                       role: upline1.uplineData?.user?.role,
                                       expired:
                                          upline1.uplineData?.user?.expired,
                                       userId: upline1.uplineData?.user?.userId,
                                       memberKeys: upline1.uplineData
                                          ? upline1.uplineData.user.memberKeys
                                          : '',
                                       user_id: upline1.uplineData
                                          ? upline1.uplineData.user._id
                                          : '',
                                       isMobileGetPaid:
                                          upline1.uplineData?.user
                                             ?.isMobileGetPaid,
                                       // sumLeft: upline1.uplineData
                                       //    ? upline1.uplineData.sumLeft
                                       //    : 0,
                                       // sumRight: upline1.uplineData
                                       //    ? upline1.uplineData.sumRight
                                       //    : 0,
                                       maxMatching:
                                          upline1.uplineData?.maxMatching,
                                       matching: upline1.uplineData?.matching,
                                    })}
                                 >
                                    {/* LEVEL 2 */}
                                    {upline1.uplineLeftData?.user?.userId ? (
                                       <PointR2>
                                          <p className='m-1 mr-3'>
                                             {' '}
                                             เก่า ={' '}
                                             <span className='m-1 fw-bold '>
                                                {commaNumber(
                                                   upline1?.uplineLeftData
                                                      ?.pointRight
                                                )}
                                             </span>
                                          </p>
                                          {isLiveScoreLoading1 ? (
                                             <>
                                                <p>Loading...</p>
                                             </>
                                          ) : (
                                             <>
                                                <p className='m-1 mr-5'>
                                                   ใหม่ =
                                                   <span className='m-1 fw-bold text-primary'>
                                                      {commaNumber(
                                                         liveScore1.pvLivePointRight1
                                                      )}
                                                   </span>
                                                </p>
                                                <p className='m-1 mr-5'>
                                                   รวม =
                                                   <span className='m-1 fw-bold text-danger'>
                                                      {commaNumber(
                                                         liveScore1.pvLivePointRight1 +
                                                            upline1
                                                               ?.uplineLeftData
                                                               ?.pointRight
                                                      )}
                                                   </span>
                                                </p>
                                             </>
                                          )}
                                       </PointR2>
                                    ) : null}
                                    {upline1.uplineRightData ? <></> : null}
                                    <TreeNode
                                       label={cardLevel2({
                                          position:
                                             upline1.uplineLeftData?.user
                                                ?.position,
                                          //isPaid: upline1.uplineLeftData.user.isPaid,
                                          name: upline1.uplineLeftData?.user
                                             ?.name,
                                          role: upline1.uplineLeftData?.user
                                             ?.role,
                                          userId:
                                             upline1.uplineLeftData?.user
                                                ?.userId,
                                          isMobileGetPaid:
                                             upline1.uplineLeftData?.user
                                                ?.isMobileGetPaid,
                                          user_id:
                                             upline1.uplineLeftData?.user?._id,
                                          expired:
                                             upline1.uplineLeftData?.user
                                                ?.expired,
                                          memberKeys:
                                             upline1.uplineLeftData?.user
                                                ?.memberKeys,
                                          userRec:
                                             upline1.uplineLeftData?.recommendby
                                                ?.userId,
                                          userRecName:
                                             upline1.uplineLeftData?.recommendby
                                                ?.name,
                                          matching:
                                             upline1.uplineLeftData?.matching,

                                          maxMatching:
                                             upline1.uplineLeftData
                                                ?.maxMatching,
                                          // depth: 1,
                                          // depth: upline1.uplineLeftData.depth,
                                          // sumLeft: upline1.uplineLeftData
                                          //    ? upline1.uplineLeftData.sumLeft
                                          //    : 0,
                                          // sumRight: upline1.uplineLeftData
                                          //    ? upline1.uplineLeftData.sumRight
                                          //    : 0,
                                          pvLeft: liveScore1.pvLivePointLeft1,
                                          pvRight: liveScore1.pvLivePointRight1,
                                          pvPrevLeft:
                                             upline1.uplineLeftData?.pointLeft,
                                          pvPrevRight:
                                             upline1.uplineLeftData?.pointRight,
                                          side: 'L',
                                          userSideName:
                                             upline1.uplineRightData?.user
                                                ?.name,
                                          userSideId:
                                             upline1.uplineRightData?.user
                                                ?.userId,
                                       })}
                                    >
                                       <TreeNode
                                          label={cardLevel3({
                                             position:
                                                upline1.uplineLeftDataLevel31
                                                   ?.user.position,
                                             // isPaid:
                                             //    upline1.uplineLeftDataLevel31
                                             //       ?.userisPaid,
                                             name: upline1.uplineLeftDataLevel31
                                                ?.user.name,
                                             role: upline1.uplineLeftDataLevel31
                                                ?.user.role,
                                             isMobileGetPaid:
                                                upline1.uplineLeftDataLevel31
                                                   ?.user?.isMobileGetPaid,
                                             expired:
                                                upline1.uplineLeftDataLevel31
                                                   ?.user.expired,
                                             userId:
                                                upline1.uplineLeftDataLevel31
                                                   ?.user.userId,
                                             userRec:
                                                upline1.uplineLeftDataLevel31
                                                   ?.recommendby.userId,
                                             userRecName:
                                                upline1.uplineLeftDataLevel31
                                                   ?.recommendby.name,
                                             // depth: upline1.uplineLeftDataLevel31
                                             //    ?.user.depth,
                                             pvLeft:
                                                liveScore4?.pvLivePointLeft4,
                                             matching:
                                                upline1.uplineLeftDataLevel31
                                                   ?.matching,

                                             maxMatching:
                                                upline1.uplineLeftDataLevel31
                                                   ?.maxMatching,
                                             pvRight:
                                                liveScore4?.pvLivePointRight4,
                                             pvPrevLeft:
                                                upline1.uplineLeftDataLevel31
                                                   ?.pointLeft,
                                             pvPrevRight:
                                                upline1.uplineLeftDataLevel31
                                                   ?.pointRight,
                                             user_id:
                                                upline1.uplineLeftDataLevel31
                                                   ?.user._id,
                                             memberKeys:
                                                upline1.uplineLeftDataLevel31
                                                   ?.usermemberKeys,
                                             side: 'L',
                                             parentName:
                                                upline1?.uplineLeftData?.user
                                                   ?.name,
                                             parentId:
                                                upline1?.uplineLeftData?.user
                                                   ?.userId,
                                             userSideName:
                                                upline1.uplineLeftDataLevel31
                                                   ?.user?.name,
                                             userSideId:
                                                upline1.uplineLeftDataLevel31
                                                   ?.user?.userId,
                                             // sumLeft:
                                             //    upline1.uplineLeftDataLevel3
                                             //       ?.usertotalLeft,
                                             // sumRight:
                                             //    upline1.uplineLeftDataLevel3
                                             //       ?.usertotalRight,
                                          })}
                                       >
                                          {isLoadingUpline2 ? (
                                             <LoadingSpinner />
                                          ) : (
                                             <>
                                                <TreeNode
                                                   label={cardLevel4({
                                                      position:
                                                         upline2.uplineLeftData
                                                            ?.user?.position,
                                                      //isPaid: upline1.uplineLeftData.user.isPaid,
                                                      isMobileGetPaid:
                                                         upline2.uplineLeftData
                                                            ?.user
                                                            ?.isMobileGetPaid,
                                                      name: upline2
                                                         .uplineLeftData?.user
                                                         ?.name,
                                                      role: upline2
                                                         .uplineLeftData?.user
                                                         ?.role,
                                                      userId:
                                                         upline2.uplineLeftData
                                                            ?.user?.userId,
                                                      user_id:
                                                         upline2.uplineLeftData
                                                            ?.user?._id,
                                                      expired:
                                                         upline2.uplineLeftData
                                                            ?.user?.expired,
                                                      memberKeys:
                                                         upline2.uplineLeftData
                                                            ?.user?.memberKeys,
                                                      userRec:
                                                         upline2.uplineLeftData
                                                            ?.recommendby
                                                            ?.userId,
                                                      userRecName:
                                                         upline2.uplineLeftData
                                                            ?.recommendby?.name,
                                                      matching:
                                                         upline2.uplineLeftData
                                                            ?.matching,
                                                      maxMatching:
                                                         upline2.uplineLeftData
                                                            ?.maxMatching,

                                                      pvLeft:
                                                         upline2.uplineLeftData
                                                            ?.pointLeft,
                                                      pvRight:
                                                         upline2.uplineLeftData
                                                            ?.pointRight,

                                                      side: 'L',
                                                      userSideName:
                                                         upline2.uplineRightData
                                                            ?.user?.name,
                                                      userSideId:
                                                         upline2.uplineRightData
                                                            ?.user?.userId,
                                                      parentName:
                                                         upline1
                                                            .uplineLeftDataLevel31
                                                            ?.user?.name,
                                                      parentId:
                                                         upline1
                                                            .uplineLeftDataLevel31
                                                            ?.user?.userId,
                                                   })}
                                                >
                                                   <TreeNode
                                                      label={cardLevel5({
                                                         position:
                                                            upline2
                                                               .uplineLeftDataLevel31
                                                               ?.user?.position,

                                                         name: upline2
                                                            .uplineLeftDataLevel31
                                                            ?.user?.name,
                                                         role: upline2
                                                            .uplineLeftDataLevel31
                                                            ?.user?.role,
                                                         isMobileGetPaid:
                                                            upline2
                                                               .uplineLeftDataLevel31
                                                               ?.user
                                                               ?.isMobileGetPaid,
                                                         expired:
                                                            upline2
                                                               .uplineLeftDataLevel31
                                                               ?.user?.expired,
                                                         userId:
                                                            upline2
                                                               .uplineLeftDataLevel31
                                                               ?.user?.userId,
                                                         userRec:
                                                            upline2
                                                               .uplineLeftDataLevel31
                                                               ?.recommendby
                                                               ?.userId,
                                                         userRecName:
                                                            upline2
                                                               .uplineLeftDataLevel31
                                                               ?.recommendby
                                                               ?.name,

                                                         pvLeft:
                                                            upline2
                                                               .uplineLeftDataLevel31
                                                               ?.pointLeft,
                                                         matching:
                                                            upline2
                                                               .uplineLeftDataLevel31
                                                               ?.matching,
                                                         maxMatching:
                                                            upline2
                                                               .uplineLeftDataLevel31
                                                               ?.maxMatching,
                                                         pvRight:
                                                            upline2
                                                               .uplineLeftDataLevel31
                                                               ?.userpvLivePointRight,
                                                         pvPrevLeft:
                                                            upline2
                                                               .uplineLeftDataLevel31
                                                               ?.pointLeft,
                                                         pvPrevRight:
                                                            upline2
                                                               .uplineLeftDataLevel31
                                                               ?.pointRight,
                                                         user_id:
                                                            upline2
                                                               .uplineLeftDataLevel31
                                                               ?.user._id,
                                                         memberKeys:
                                                            upline2
                                                               .uplineLeftDataLevel31
                                                               ?.user
                                                               ?.memberKeys,
                                                         side: 'L',
                                                         parentName:
                                                            upline2
                                                               .uplineLeftData
                                                               ?.user?.name,
                                                         parentId:
                                                            upline2
                                                               .uplineLeftData
                                                               ?.user?.userId,
                                                         userSideName:
                                                            upline2
                                                               .uplineLeftDataLevel32
                                                               ?.user?.name,
                                                         userSideId:
                                                            upline2
                                                               .uplineLeftDataLevel32
                                                               ?.user?.userId,
                                                      })}
                                                   />
                                                   <TreeNode
                                                      label={cardLevel5({
                                                         position:
                                                            upline2
                                                               .uplineLeftDataLevel32
                                                               ?.user?.position,

                                                         name: upline2
                                                            .uplineLeftDataLevel32
                                                            ?.user?.name,
                                                         role: upline2
                                                            .uplineLeftDataLevel32
                                                            ?.user?.role,
                                                         isMobileGetPaid:
                                                            upline2
                                                               .uplineLeftDataLevel32
                                                               ?.user
                                                               ?.isMobileGetPaid,
                                                         expired:
                                                            upline2
                                                               .uplineLeftDataLevel32
                                                               ?.user?.expired,
                                                         userId:
                                                            upline2
                                                               .uplineLeftDataLevel32
                                                               ?.user?.userId,
                                                         userRec:
                                                            upline2
                                                               .uplineLeftDataLevel32
                                                               ?.recommendby
                                                               ?.userId,
                                                         userRecName:
                                                            upline2
                                                               .uplineLeftDataLevel32
                                                               ?.recommendby
                                                               ?.name,

                                                         pvLeft:
                                                            upline2
                                                               .uplineLeftDataLevel32
                                                               ?.pointLeft,
                                                         matching:
                                                            upline2
                                                               .uplineLeftDataLevel32
                                                               ?.matching,
                                                         maxMatching:
                                                            upline2
                                                               .uplineLeftDataLevel32
                                                               ?.maxMatching,
                                                         pvRight:
                                                            upline2
                                                               .uplineLeftDataLevel32
                                                               ?.userpvLivePointRight,
                                                         pvPrevLeft:
                                                            upline2
                                                               .uplineLeftDataLevel32
                                                               ?.pointLeft,
                                                         pvPrevRight:
                                                            upline2
                                                               .uplineLeftDataLevel32
                                                               ?.pointRight,
                                                         user_id:
                                                            upline2
                                                               .uplineLeftDataLevel32
                                                               ?.user?._id,
                                                         memberKeys:
                                                            upline2
                                                               .uplineLeftDataLevel32
                                                               ?.usermemberKeys,
                                                         side: 'R',
                                                         parentName:
                                                            upline2
                                                               .uplineLeftData
                                                               ?.user?.name,
                                                         parentId:
                                                            upline2
                                                               .uplineLeftData
                                                               ?.user?.userId,
                                                         userSideName:
                                                            upline2
                                                               .uplineLeftDataLevel31
                                                               ?.user?.name,
                                                         userSideId:
                                                            upline2
                                                               .uplineLeftDataLevel31
                                                               ?.user?.userId,
                                                      })}
                                                   />
                                                </TreeNode>
                                                <TreeNode
                                                   label={cardLevel4({
                                                      position:
                                                         upline2.uplineRightData
                                                            ?.user?.position,

                                                      name: upline2
                                                         .uplineRightData?.user
                                                         ?.name,
                                                      role: upline2
                                                         .uplineRightData?.user
                                                         ?.role,
                                                      isMobileGetPaid:
                                                         upline2.uplineRightData
                                                            ?.user
                                                            ?.isMobileGetPaid,
                                                      expired:
                                                         upline2.uplineRightData
                                                            ?.user?.expired,
                                                      userId:
                                                         upline2.uplineRightData
                                                            ?.user?.userId,
                                                      userRec:
                                                         upline2.uplineRightData
                                                            ?.recommendby
                                                            ?.userId,
                                                      pvLeft:
                                                         upline2.uplineRightData
                                                            ?.pointLeft,
                                                      pvRight:
                                                         upline2.plineRightData
                                                            ?.pointRight,
                                                      matching:
                                                         upline2.uplineRightData
                                                            ?.matching,
                                                      maxMatching:
                                                         upline2.uplineRightData
                                                            ?.maxMatching,
                                                      pvPrevLeft:
                                                         upline2.uplineRightData
                                                            ?.pointLeft,
                                                      pvPrevRight:
                                                         upline2.uplineRightData
                                                            ?.pointRightR,

                                                      user_id:
                                                         upline2.uplineRightData
                                                            ?.user._id,
                                                      memberKeys:
                                                         upline2.uplineRightData
                                                            ?.memberKeys,
                                                      side: 'R',
                                                      userSideName:
                                                         upline2?.uplineLeftData
                                                            ?.user?.name,
                                                      userSideId:
                                                         upline2?.uplineLeftData
                                                            ?.user?.userId,
                                                      parentName:
                                                         upline1
                                                            ?.uplineLeftDataLevel31
                                                            ?.user?.name,
                                                      parentId:
                                                         upline1
                                                            ?.uplineLeftDataLevel31
                                                            ?.user?.userId,
                                                   })}
                                                >
                                                   <TreeNode
                                                      label={cardLevel5({
                                                         position:
                                                            upline2
                                                               .uplineRightDataLevel31
                                                               ?.user.position,
                                                         isMobileGetPaid:
                                                            upline2
                                                               .uplineRightDataLevel31
                                                               ?.user
                                                               ?.isMobileGetPaid,

                                                         name: upline2
                                                            .uplineRightDataLevel31
                                                            ?.user?.name,
                                                         role: upline2
                                                            .uplineRightDataLevel31
                                                            ?.user?.role,
                                                         expired:
                                                            upline2
                                                               .uplineRightDataLevel31
                                                               ?.user.expired,
                                                         userId:
                                                            upline2
                                                               .uplineRightDataLevel31
                                                               ?.user?.userId,
                                                         userRec:
                                                            upline2
                                                               .uplineRightDataLevel31
                                                               ?.recommendby
                                                               ?.userId,
                                                         userRecName:
                                                            upline2
                                                               .uplineRightDataLevel31
                                                               ?.recommendby
                                                               ?.name,

                                                         pvLeft:
                                                            upline2
                                                               .uplineRightDataLevel31
                                                               ?.pointLeft,
                                                         matching: upline2
                                                            .uplineRightDataLevel31
                                                            ?.user
                                                            ? upline2
                                                                 .uplineRightDataLevel31
                                                                 ?.matching
                                                            : null,
                                                         maxMatching: upline2
                                                            .uplineRightDataLevel31
                                                            ?.user
                                                            ? upline2
                                                                 .uplineRightDataLevel31
                                                                 ?.maxMatching
                                                            : null,
                                                         pvRight:
                                                            upline2
                                                               .uplineRightDataLevel31
                                                               ?.userpvLivePointRight,
                                                         pvPrevLeft:
                                                            upline2
                                                               .uplineRightDataLevel31
                                                               ?.pointLeft,
                                                         pvPrevRight:
                                                            upline2
                                                               .uplineRightDataLevel31
                                                               ?.pointRight,
                                                         user_id:
                                                            upline2
                                                               .uplineRightDataLevel31
                                                               ?.user._id,
                                                         memberKeys:
                                                            upline2
                                                               .uplineRightDataLevel31
                                                               ?.usermemberKeys,
                                                         side: 'L',
                                                         parentName:
                                                            upline2
                                                               .uplineRightData
                                                               ?.user?.name,
                                                         parentId:
                                                            upline2
                                                               .uplineRightData
                                                               ?.user?.userId,
                                                         userSideName:
                                                            upline2
                                                               .uplineRightDataLevel32
                                                               ?.user?.name,
                                                         userSideId:
                                                            upline2
                                                               .uplineRightDataLevel32
                                                               ?.user?.userId,
                                                      })}
                                                   />
                                                   <TreeNode
                                                      label={cardLevel5({
                                                         position:
                                                            upline2
                                                               .uplineRightDataLevel32
                                                               ?.user.position,
                                                         isMobileGetPaid:
                                                            upline2
                                                               .uplineRightDataLevel32
                                                               ?.user
                                                               ?.isMobileGetPaid,

                                                         name: upline2
                                                            .uplineRightDataLevel32
                                                            ?.user?.name,
                                                         role: upline2
                                                            .uplineRightDataLevel32
                                                            ?.user?.role,
                                                         expired:
                                                            upline2
                                                               .uplineRightDataLevel32
                                                               ?.user?.expired,
                                                         userId:
                                                            upline2
                                                               .uplineRightDataLevel32
                                                               ?.user?.userId,
                                                         userRec:
                                                            upline2
                                                               .uplineRightDataLevel32
                                                               ?.recommendby
                                                               ?.userId,
                                                         userRecName:
                                                            upline2
                                                               .uplineRightDataLevel32
                                                               ?.recommendby
                                                               ?.name,

                                                         pvLeft:
                                                            upline2
                                                               .uplineRightDataLevel32
                                                               ?.pointLeft,
                                                         matching: upline2
                                                            .uplineRightDataLevel32
                                                            ?.user
                                                            ? upline2
                                                                 .uplineRightDataLevel32
                                                                 ?.matching
                                                            : null,
                                                         maxMatching: upline2
                                                            .uplineRightDataLevel32
                                                            ?.user
                                                            ? upline2
                                                                 .uplineRightDataLevel32
                                                                 ?.maxMatching
                                                            : null,
                                                         pvRight:
                                                            upline2
                                                               .uplineRightDataLevel32
                                                               ?.userpvLivePointRight,
                                                         pvPrevLeft:
                                                            upline2
                                                               .uplineRightDataLevel32
                                                               ?.pointLeft,
                                                         pvPrevRight:
                                                            upline2
                                                               .uplineRightDataLevel32
                                                               ?.pointRight,
                                                         user_id:
                                                            upline2
                                                               .uplineRightDataLevel32
                                                               ?.user._id,
                                                         memberKeys:
                                                            upline2
                                                               .uplineRightDataLevel32
                                                               ?.usermemberKeys,
                                                         side: 'R',
                                                         parentName:
                                                            upline2
                                                               .uplineRightData
                                                               ?.user?.name,
                                                         parentId:
                                                            upline2
                                                               .uplineRightData
                                                               ?.user?.userId,
                                                         userSideName:
                                                            upline2
                                                               .uplineRightDataLevel31
                                                               ?.user?.name,
                                                         userSideId:
                                                            upline2
                                                               .uplineRightDataLevel31
                                                               ?.user?.userId,
                                                      })}
                                                   />
                                                </TreeNode>
                                             </>
                                          )}
                                       </TreeNode>

                                       <TreeNode
                                          label={cardLevel3({
                                             position:
                                                upline1.uplineLeftDataLevel32
                                                   ?.user.position,

                                             name: upline1.uplineLeftDataLevel32
                                                ?.user.name,
                                             role: upline1.uplineLeftDataLevel32
                                                ?.user.role,
                                             isMobileGetPaid:
                                                upline1.uplineLeftDataLevel32
                                                   ?.user?.isMobileGetPaid,
                                             expired:
                                                upline1.uplineLeftDataLevel32
                                                   ?.user.expired,
                                             userId:
                                                upline1.uplineLeftDataLevel32
                                                   ?.user.userId,
                                             userRec:
                                                upline1.uplineLeftDataLevel32
                                                   ?.recommendby.userId,
                                             userRecName:
                                                upline1.uplineLeftDataLevel32
                                                   ?.recommendby.name,

                                             pvLeft:
                                                liveScore5?.pvLivePointLeft5,
                                             matching:
                                                upline1.uplineLeftDataLevel32
                                                   ?.matching,
                                             maxMatching:
                                                upline1.uplineLeftDataLevel32
                                                   ?.maxMatching,
                                             pvRight:
                                                liveScore5?.pvLivePointRight5,
                                             pvPrevLeft:
                                                upline1.uplineLeftDataLevel32
                                                   ?.pointLeft,
                                             pvPrevRight:
                                                upline1.uplineLeftDataLevel32
                                                   ?.pointRight,
                                             user_id:
                                                upline1.uplineLeftDataLevel32
                                                   ?.user._id,
                                             memberKeys:
                                                upline1.uplineLeftDataLevel32
                                                   ?.usermemberKeys,
                                             side: 'R',
                                             parentName:
                                                upline1?.uplineLeftData?.user
                                                   ?.name,
                                             parentId:
                                                upline1?.uplineLeftData?.user
                                                   ?.userId,
                                             userSideName:
                                                upline1.uplineLeftDataLevel31
                                                   ?.user?.name,
                                             userSideId:
                                                upline1.uplineLeftDataLevel31
                                                   ?.user?.userId,
                                          })}
                                       >
                                          {isLoadingUpline3 ? (
                                             <LoadingSpinner />
                                          ) : (
                                             <>
                                                <TreeNode
                                                   label={cardLevel4({
                                                      position:
                                                         upline3.uplineLeftData
                                                            ?.user?.position,
                                                      isMobileGetPaid:
                                                         upline3.uplineLeftData
                                                            ?.user
                                                            ?.isMobileGetPaid,

                                                      name: upline3
                                                         .uplineLeftData?.user
                                                         ? upline3
                                                              .uplineLeftData
                                                              .user.name
                                                         : '',
                                                      role: upline3
                                                         .uplineLeftData?.user
                                                         ?.role,
                                                      userId:
                                                         upline3.uplineLeftData
                                                            ?.user?.userId,
                                                      user_id:
                                                         upline3.uplineLeftData
                                                            ?.user?._id,
                                                      expired:
                                                         upline3.uplineLeftData
                                                            ?.user?.expired,
                                                      memberKeys:
                                                         upline3.uplineLeftData
                                                            ?.user?.memberKeys,
                                                      userRec:
                                                         upline3.uplineLeftData
                                                            ?.recommendby
                                                            ?.userId,
                                                      userRecName:
                                                         upline3.uplineLeftData
                                                            ?.recommendby?.name,
                                                      matching:
                                                         upline3.uplineLeftData
                                                            ?.matching,
                                                      maxMatching:
                                                         upline3.uplineLeftData
                                                            ?.maxMatching,

                                                      pvLeft:
                                                         upline3.uplineLeftData
                                                            ?.pointLeft,
                                                      pvRight:
                                                         upline3.uplineLeftData
                                                            ?.pointRight,
                                                      parentName:
                                                         upline1
                                                            .uplineLeftDataLevel32
                                                            ?.user?.name,
                                                      parentId:
                                                         upline1
                                                            .uplineLeftDataLevel32
                                                            ?.user?.userId,

                                                      side: 'L',
                                                      userSideName:
                                                         upline3.uplineRightData
                                                            ?.user?.name,
                                                      userSideId:
                                                         upline3.uplineRightData
                                                            ?.user?.userId,
                                                      parentName:
                                                         upline1
                                                            .uplineLeftDataLevel32
                                                            ?.user?.name,
                                                      parentId:
                                                         upline1
                                                            .uplineLeftDataLevel32
                                                            ?.user?.userId,
                                                   })}
                                                >
                                                   <TreeNode
                                                      label={cardLevel5({
                                                         position:
                                                            upline3
                                                               .uplineLeftDataLevel31
                                                               ?.user.position,
                                                         isMobileGetPaid:
                                                            upline3
                                                               .uplineLeftDataLevel31
                                                               ?.user
                                                               ?.isMobileGetPaid,

                                                         name: upline3
                                                            .uplineLeftDataLevel31
                                                            ?.user?.name,
                                                         role: upline3
                                                            .uplineLeftDataLevel31
                                                            ?.user?.role,
                                                         expired:
                                                            upline3
                                                               .uplineLeftDataLevel31
                                                               ?.user?.expired,
                                                         userId:
                                                            upline3
                                                               .uplineLeftDataLevel31
                                                               ?.user?.userId,
                                                         userRec:
                                                            upline3
                                                               .uplineLeftDataLevel31
                                                               ?.recommendby
                                                               ?.userId,
                                                         userRecName:
                                                            upline3
                                                               .uplineLeftDataLevel31
                                                               ?.recommendby
                                                               ?.name,

                                                         pvLeft:
                                                            upline3
                                                               .uplineLeftDataLevel31
                                                               ?.pointLeft,
                                                         matching: upline3
                                                            .uplineLeftDataLevel31
                                                            ?.user
                                                            ? upline3
                                                                 .uplineLeftDataLevel31
                                                                 ?.matching
                                                            : null,
                                                         maxMatching: upline3
                                                            .uplineLeftDataLevel31
                                                            ?.user
                                                            ? upline3
                                                                 .uplineLeftDataLevel31
                                                                 ?.maxMatching
                                                            : null,
                                                         pvRight:
                                                            upline3
                                                               .uplineLeftDataLevel31
                                                               ?.userpvLivePointRight,
                                                         pvPrevLeft:
                                                            upline3
                                                               .uplineLeftDataLevel31
                                                               ?.pointLeft,
                                                         pvPrevRight:
                                                            upline3
                                                               .uplineLeftDataLevel31
                                                               ?.pointRight,
                                                         user_id:
                                                            upline3
                                                               .uplineLeftDataLevel31
                                                               ?.user._id,
                                                         memberKeys:
                                                            upline3
                                                               .uplineLeftDataLevel31
                                                               ?.usermemberKeys,
                                                         side: 'L',
                                                         parentName:
                                                            upline3
                                                               .uplineLeftData
                                                               ?.user?.name,
                                                         parentId:
                                                            upline3
                                                               .uplineLeftData
                                                               ?.user?.userId,
                                                         userSideName:
                                                            upline3
                                                               .uplineRightDataLevel32
                                                               ?.user?.name,
                                                         userSideId:
                                                            upline3
                                                               .uplineRightDataLevel32
                                                               ?.user?.userId,
                                                      })}
                                                   />
                                                   <TreeNode
                                                      label={cardLevel5({
                                                         position:
                                                            upline3
                                                               .uplineLeftDataLevel32
                                                               ?.user.position,
                                                         isMobileGetPaid:
                                                            upline3
                                                               .uplineLeftDataLevel32
                                                               ?.user
                                                               ?.isMobileGetPaid,

                                                         name: upline3
                                                            .uplineLeftDataLevel32
                                                            ?.user?.name,
                                                         role: upline3
                                                            .uplineLeftDataLevel32
                                                            ?.user?.role,
                                                         expired:
                                                            upline3
                                                               .uplineLeftDataLevel32
                                                               ?.user?.expired,
                                                         userId:
                                                            upline3
                                                               .uplineLeftDataLevel32
                                                               ?.user?.userId,
                                                         userRec:
                                                            upline3
                                                               .uplineLeftDataLevel32
                                                               ?.recommendby
                                                               ?.userId,
                                                         userRecName:
                                                            upline3
                                                               .uplineLeftDataLevel32
                                                               ?.recommendby
                                                               ?.name,

                                                         pvLeft:
                                                            upline3
                                                               .uplineLeftDataLevel32
                                                               ?.pointLeft,
                                                         matching: upline3
                                                            .uplineLeftDataLevel32
                                                            ?.user
                                                            ? upline3
                                                                 .uplineLeftDataLevel32
                                                                 ?.matching
                                                            : null,
                                                         maxMatching: upline3
                                                            .uplineLeftDataLevel32
                                                            ?.user
                                                            ? upline3
                                                                 .uplineLeftDataLevel32
                                                                 ?.maxMatching
                                                            : null,
                                                         pvRight:
                                                            upline3
                                                               .uplineLeftDataLevel32
                                                               ?.userpvLivePointRight,
                                                         pvPrevLeft:
                                                            upline3
                                                               .uplineLeftDataLevel32
                                                               ?.pointLeft,
                                                         pvPrevRight:
                                                            upline3
                                                               .uplineLeftDataLevel32
                                                               ?.pointRight,
                                                         user_id:
                                                            upline3
                                                               .uplineLeftDataLevel32
                                                               ?.user._id,
                                                         memberKeys:
                                                            upline3
                                                               .uplineLeftDataLevel32
                                                               ?.usermemberKeys,
                                                         side: 'R',
                                                         parentName:
                                                            upline3
                                                               .uplineLeftData
                                                               ?.user?.name,
                                                         parentId:
                                                            upline3
                                                               .uplineLeftData
                                                               ?.user?.userId,
                                                         userSideName:
                                                            upline3
                                                               .uplineRightDataLevel31
                                                               ?.user?.name,
                                                         userSideId:
                                                            upline3
                                                               .uplineRightDataLevel31
                                                               ?.user?.userId,
                                                      })}
                                                   />
                                                </TreeNode>
                                                <TreeNode
                                                   label={cardLevel4({
                                                      position:
                                                         upline3.uplineRightData
                                                            ?.user?.position,
                                                      isMobileGetPaid:
                                                         upline3.uplineRightData
                                                            ?.user
                                                            ?.isMobileGetPaid,

                                                      name: upline3
                                                         .uplineRightData?.user
                                                         ?.name,
                                                      role: upline3
                                                         .uplineRightData?.user
                                                         ?.role,
                                                      expired:
                                                         upline3.uplineRightData
                                                            ?.user?.expired,
                                                      userId:
                                                         upline3.uplineRightData
                                                            ?.user?.userId,
                                                      userRec:
                                                         upline3.uplineRightData
                                                            ?.recommendby
                                                            ?.userId,
                                                      pvLeft:
                                                         upline3.uplineRightData
                                                            ?.pointLeft,
                                                      pvRight:
                                                         upline3.plineRightData
                                                            ?.pointRight,
                                                      matching:
                                                         upline3.uplineRightData
                                                            ?.matching,
                                                      maxMatching:
                                                         upline3.uplineRightData
                                                            ?.maxMatching,
                                                      pvPrevLeft:
                                                         upline3.uplineRightData
                                                            ?.pointLeft,
                                                      pvPrevRight:
                                                         upline3.uplineRightData
                                                            ?.pointRightR,

                                                      user_id:
                                                         upline3.uplineRightData
                                                            ?.user?._id,
                                                      memberKeys:
                                                         upline3.uplineRightData
                                                            ?.memberKeys,
                                                      side: 'R',
                                                      userSideName:
                                                         upline3.uplineLeftData
                                                            ?.user?.name,
                                                      userSideId:
                                                         upline3.uplineLeftData
                                                            ?.user?.userId,
                                                      parentName:
                                                         upline1
                                                            .uplineLeftDataLevel32
                                                            ?.user?.name,
                                                      parentId:
                                                         upline1
                                                            .uplineLeftDataLevel32
                                                            ?.user?.userId,
                                                   })}
                                                >
                                                   <TreeNode
                                                      label={cardLevel5({
                                                         position:
                                                            upline3
                                                               .uplineRightDataLevel31
                                                               ?.user.position,
                                                         isMobileGetPaid:
                                                            upline3
                                                               .uplineRightDataLevel31
                                                               ?.user
                                                               ?.isMobileGetPaid,

                                                         name: upline3
                                                            .uplineRightDataLevel31
                                                            ?.user.name,
                                                         role: upline3
                                                            .uplineRightDataLevel31
                                                            ?.user.role,
                                                         expired:
                                                            upline3
                                                               .uplineRightDataLevel31
                                                               ?.user?.expired,
                                                         userId:
                                                            upline3
                                                               .uplineRightDataLevel31
                                                               ?.user?.userId,
                                                         userRec:
                                                            upline3
                                                               .uplineRightDataLevel31
                                                               ?.recommendby
                                                               ?.userId,
                                                         userRecName:
                                                            upline3
                                                               .uplineRightDataLevel31
                                                               ?.recommendby
                                                               ?.name,

                                                         pvLeft:
                                                            upline3
                                                               .uplineRightDataLevel31
                                                               ?.pointLeft,
                                                         matching: upline3
                                                            .uplineRightDataLevel31
                                                            ?.user
                                                            ? upline3
                                                                 .uplineRightDataLevel31
                                                                 ?.matching
                                                            : null,
                                                         maxMatching: upline3
                                                            .uplineRightDataLevel31
                                                            ?.user
                                                            ? upline3
                                                                 .uplineRightDataLevel31
                                                                 ?.maxMatching
                                                            : null,
                                                         pvRight:
                                                            upline3
                                                               .uplineRightDataLevel31
                                                               ?.userpvLivePointRight,
                                                         pvPrevLeft:
                                                            upline3
                                                               .uplineRightDataLevel31
                                                               ?.pointLeft,
                                                         pvPrevRight:
                                                            upline3
                                                               .uplineRightDataLevel31
                                                               ?.pointRight,
                                                         user_id:
                                                            upline3
                                                               .uplineRightDataLevel31
                                                               ?.user._id,
                                                         memberKeys:
                                                            upline3
                                                               .uplineRightDataLevel31
                                                               ?.usermemberKeys,
                                                         side: 'L',
                                                         parentName:
                                                            upline3
                                                               .uplineRightData
                                                               ?.user?.name,
                                                         parentId:
                                                            upline3
                                                               .uplineRightData
                                                               ?.user?.userId,
                                                         userSideName:
                                                            upline3
                                                               .uplineRightDataLevel32
                                                               ?.user?.name,
                                                         userSideId:
                                                            upline3
                                                               .uplineRightDataLevel32
                                                               ?.user?.userId,
                                                      })}
                                                   />
                                                   <TreeNode
                                                      label={cardLevel5({
                                                         position:
                                                            upline3
                                                               .uplineRightDataLevel32
                                                               ?.user.position,
                                                         isMobileGetPaid:
                                                            upline3
                                                               .uplineRightDataLevel32
                                                               ?.user
                                                               ?.isMobileGetPaid,

                                                         name: upline3
                                                            .uplineRightDataLevel32
                                                            ?.user?.name,
                                                         role: upline3
                                                            .uplineRightDataLevel32
                                                            ?.user?.role,
                                                         expired:
                                                            upline3
                                                               .uplineRightDataLevel32
                                                               ?.user?.expired,
                                                         userId:
                                                            upline3
                                                               .uplineRightDataLevel32
                                                               ?.user?.userId,
                                                         userRec:
                                                            upline3
                                                               .uplineRightDataLevel32
                                                               ?.recommendby
                                                               ?.userId,
                                                         userRecName:
                                                            upline3
                                                               .uplineRightDataLevel32
                                                               ?.recommendby
                                                               ?.name,

                                                         pvLeft:
                                                            upline3
                                                               .uplineRightDataLevel32
                                                               ?.pointLeft,
                                                         matching: upline3
                                                            .uplineRightDataLevel32
                                                            ?.user
                                                            ? upline3
                                                                 .uplineRightDataLevel32
                                                                 ?.matching
                                                            : null,
                                                         maxMatching: upline3
                                                            .uplineRightDataLevel32
                                                            ?.user
                                                            ? upline3
                                                                 .uplineRightDataLevel32
                                                                 ?.maxMatching
                                                            : null,
                                                         pvRight:
                                                            upline3
                                                               .uplineRightDataLevel32
                                                               ?.userpvLivePointRight,
                                                         pvPrevLeft:
                                                            upline3
                                                               .uplineRightDataLevel32
                                                               ?.pointLeft,
                                                         pvPrevRight:
                                                            upline3
                                                               .uplineRightDataLevel32
                                                               ?.pointRight,
                                                         user_id:
                                                            upline3
                                                               .uplineRightDataLevel32
                                                               ?.user._id,
                                                         memberKeys:
                                                            upline3
                                                               .uplineRightDataLevel32
                                                               ?.usermemberKeys,
                                                         side: 'R',
                                                         parentName:
                                                            upline3
                                                               .uplineRightData
                                                               ?.user?.name,
                                                         parentId:
                                                            upline3
                                                               .uplineRightData
                                                               ?.user?.userId,
                                                         userSideName:
                                                            upline3
                                                               .uplineRightDataLevel31
                                                               ?.user?.name,
                                                         userSideId:
                                                            upline3
                                                               .uplineRightDataLevel31
                                                               ?.user?.userId,
                                                      })}
                                                   />
                                                </TreeNode>
                                             </>
                                          )}
                                       </TreeNode>
                                    </TreeNode>
                                    {upline1.uplineLeftData?.user?.userId && (
                                       <PointL2>
                                          <p className='m-1 mr-3'>
                                             {' '}
                                             เก่า =
                                             <span className='m-1 fw-bold'>
                                                {commaNumber(
                                                   upline1?.uplineLeftData
                                                      ?.pointLeft
                                                )}
                                             </span>
                                          </p>
                                          {isLiveScoreLoading1 ? (
                                             <>
                                                <p>Loading...</p>
                                             </>
                                          ) : (
                                             <>
                                                <p className='m-1 mr-3'>
                                                   {' '}
                                                   ใหม่ =
                                                   <span className='m-1 fw-bold text-primary'>
                                                      {commaNumber(
                                                         liveScore1.pvLivePointLeft1
                                                      )}
                                                   </span>
                                                </p>
                                                <p className='m-1 mr-3'>
                                                   {' '}
                                                   รวม =
                                                   <span className='m-1 fw-bold text-danger'>
                                                      {commaNumber(
                                                         liveScore1.pvLivePointLeft1 +
                                                            upline1
                                                               ?.uplineLeftData
                                                               ?.pointLeft
                                                      )}
                                                   </span>
                                                </p>
                                             </>
                                          )}
                                       </PointL2>
                                    )}
                                    {/*    HALF LEFT AND RIGHT    */}

                                    {upline1.uplineRightData?.user?.userId && (
                                       <PointR3>
                                          <p className='m-1 mr-3'>
                                             {' '}
                                             เก่า ={' '}
                                             <span className='m-1 fw-bold '>
                                                {commaNumber(
                                                   upline1?.uplineRightData
                                                      ?.pointRight
                                                )}
                                             </span>
                                          </p>
                                          {isLiveScoreLoading2 ? (
                                             <>
                                                <p>Loading...</p>
                                             </>
                                          ) : (
                                             <>
                                                <p className='m-1 mr-3'>
                                                   ใหม่ =
                                                   <span className='m-1 fw-bold text-primary'>
                                                      {commaNumber(
                                                         liveScore2.pvLivePointRight2
                                                      )}
                                                   </span>
                                                </p>
                                                <p className='m-1 mr-3'>
                                                   รวม =
                                                   <span className='m-1 fw-bold text-danger'>
                                                      {commaNumber(
                                                         upline1.uplineRightData
                                                            .pointRight +
                                                            liveScore2.pvLivePointRight2
                                                      )}
                                                   </span>
                                                </p>
                                             </>
                                          )}
                                       </PointR3>
                                    )}

                                    <TreeNode
                                       label={cardLevel2({
                                          isBanned:
                                             upline1.uplineRightData?.user
                                                ?.isBanned,
                                          position:
                                             upline1.uplineRightData?.user
                                                ?.position,

                                          name: upline1.uplineRightData?.user
                                             ?.name,
                                          role: upline1.uplineRightData?.user
                                             ?.role,
                                          isMobileGetPaid:
                                             upline1.uplineRightData?.user
                                                ?.isMobileGetPaid,
                                          expired:
                                             upline1.uplineRightData?.user
                                                ?.expired,
                                          userId:
                                             upline1.uplineRightData?.user
                                                ?.userId,
                                          userRec:
                                             upline1.uplineRightData
                                                ?.recommendby?.userId,
                                          pvLeft: liveScore2.pvLivePointLeft2,
                                          pvRight: liveScore2.pvLivePointRight2,
                                          pvPrevLeft:
                                             upline1.uplineRightData?.pointLeft,
                                          pvPrevRight:
                                             upline1.uplineRightData
                                                ?.pointRight,

                                          matching:
                                             upline1.uplineRightData?.matching,
                                          maxMatching:
                                             upline1.uplineRightData
                                                ?.maxMatching,
                                          pvPrevLeft:
                                             upline1.uplineRightData?.pointLeft,
                                          pvPrevRight:
                                             upline1.uplineRightData
                                                ?.pointRight,

                                          user_id:
                                             upline1.uplineRightData?.user._id,
                                          memberKeys:
                                             upline1.uplineRightData
                                                ?.memberKeys,
                                          side: 'R',
                                          userSideName:
                                             upline1.uplineLeftData?.user.name,
                                          userSideId:
                                             upline1.uplineLeftData?.user
                                                .userId,
                                       })}
                                    >
                                       <TreeNode
                                          label={cardLevel3({
                                             position:
                                                upline1.uplineRightDataLevel31
                                                   ?.user.position,
                                             isMobileGetPaid:
                                                upline1.uplineRightDataLevel31
                                                   ?.user?.isMobileGetPaid,

                                             name: upline1
                                                .uplineRightDataLevel31?.user
                                                .name,
                                             role: upline1
                                                .uplineRightDataLevel31?.user
                                                .role,
                                             expired:
                                                upline1.uplineRightDataLevel31
                                                   ?.user?.expired,
                                             userId:
                                                upline1.uplineRightDataLevel31
                                                   ?.user?.userId,
                                             userRec:
                                                upline1.uplineRightDataLevel31
                                                   ?.recommendby?.userId,
                                             userRecName:
                                                upline1.uplineRightDataLevel31
                                                   ?.recommendby.name,

                                             pvLeft:
                                                liveScore6?.pvLivePointLeft6,
                                             matching:
                                                upline1.uplineRightDataLevel31
                                                   ?.matching,
                                             maxMatching:
                                                upline1.uplineRightDataLevel31
                                                   ?.maxMatching,
                                             pvRight:
                                                liveScore6?.pvLivePointRight6,
                                             pvPrevLeft:
                                                upline1.uplineRightDataLevel31
                                                   ?.pointLeft,
                                             pvPrevRight:
                                                upline1.uplineRightDataLevel31
                                                   ?.pointRight,
                                             user_id:
                                                upline1.uplineRightDataLevel31
                                                   ?.user._id,
                                             memberKeys:
                                                upline1.uplineRightDataLevel31
                                                   ?.usermemberKeys,
                                             side: 'L',
                                             parentName:
                                                upline1?.uplineRightData?.user
                                                   ?.name,
                                             parentId:
                                                upline1?.uplineRightData?.user
                                                   ?.userId,
                                             userSideName:
                                                upline1.uplineRightDataLevel32
                                                   ?.user?.name,
                                             userSideId:
                                                upline1.uplineRightDataLevel32
                                                   ?.user?.userId,
                                          })}
                                       >
                                          {isLoadingUpline4 ? (
                                             <LoadingSpinner />
                                          ) : (
                                             <>
                                                <TreeNode
                                                   label={cardLevel4({
                                                      position:
                                                         upline4.uplineLeftData
                                                            ?.user?.position,

                                                      isMobileGetPaid:
                                                         upline4.uplineLeftData
                                                            ?.user
                                                            ?.isMobileGetPaid,
                                                      name: upline4
                                                         .uplineLeftData?.user
                                                         ?.name,
                                                      role: upline4
                                                         .uplineLeftData?.user
                                                         ?.role,
                                                      userId:
                                                         upline4.uplineLeftData
                                                            ?.user?.userId,
                                                      user_id:
                                                         upline4.uplineLeftData
                                                            ?.user?._id,
                                                      expired:
                                                         upline4.uplineLeftData
                                                            ?.user?.expired,
                                                      memberKeys:
                                                         upline4.uplineLeftData
                                                            ?.user?.memberKeys,
                                                      userRec:
                                                         upline4.uplineLeftData
                                                            ?.recommendby
                                                            ?.userId,
                                                      userRecName:
                                                         upline4.uplineLeftData
                                                            ?.recommendby?.name,
                                                      matching:
                                                         upline4.uplineLeftData
                                                            ?.matching,
                                                      maxMatching:
                                                         upline4.uplineLeftData
                                                            ?.maxMatching,

                                                      pvLeft:
                                                         upline4.uplineLeftData
                                                            ?.pointLeft,
                                                      pvRight:
                                                         upline4.uplineLeftData
                                                            ?.pointRight,

                                                      side: 'L',
                                                      userSideName:
                                                         upline4.uplineRightData
                                                            ?.user?.name,
                                                      userSideId:
                                                         upline4.uplineRightData
                                                            ?.user?.userId,
                                                      parentName:
                                                         upline1
                                                            .uplineRightDataLevel31
                                                            ?.user?.name,
                                                      parentId:
                                                         upline1
                                                            .uplineRightDataLevel31
                                                            ?.user?.userId,
                                                   })}
                                                >
                                                   <TreeNode
                                                      label={cardLevel5({
                                                         position:
                                                            upline4
                                                               .uplineLeftDataLevel31
                                                               ?.user.position,
                                                         isMobileGetPaid:
                                                            upline4
                                                               .uplineLeftDataLevel31
                                                               ?.user
                                                               ?.isMobileGetPaid,

                                                         name: upline4
                                                            .uplineLeftDataLevel31
                                                            ?.user?.name,
                                                         role: upline4
                                                            .uplineLeftDataLevel31
                                                            ?.user?.role,
                                                         expired:
                                                            upline4
                                                               .uplineLeftDataLevel31
                                                               ?.user?.expired,
                                                         userId:
                                                            upline4
                                                               .uplineLeftDataLevel31
                                                               ?.user?.userId,
                                                         userRec:
                                                            upline4
                                                               .uplineLeftDataLevel31
                                                               ?.recommendby
                                                               ?.userId,
                                                         userRecName:
                                                            upline4
                                                               .uplineLeftDataLevel31
                                                               ?.recommendby
                                                               ?.name,

                                                         pvLeft:
                                                            upline4
                                                               .uplineLeftDataLevel31
                                                               ?.pointLeft,
                                                         matching: upline4
                                                            .uplineLeftDataLevel31
                                                            ?.user
                                                            ? upline4
                                                                 .uplineLeftDataLevel31
                                                                 ?.matching
                                                            : null,
                                                         maxMatching: upline4
                                                            .uplineLeftDataLevel31
                                                            ?.user
                                                            ? upline4
                                                                 .uplineLeftDataLevel31
                                                                 ?.maxMatching
                                                            : null,
                                                         pvRight:
                                                            upline4
                                                               .uplineLeftDataLevel31
                                                               ?.userpvLivePointRight,
                                                         pvPrevLeft:
                                                            upline4
                                                               .uplineLeftDataLevel31
                                                               ?.pointLeft,
                                                         pvPrevRight:
                                                            upline4
                                                               .uplineLeftDataLevel31
                                                               ?.pointRight,
                                                         user_id:
                                                            upline4
                                                               .uplineLeftDataLevel31
                                                               ?.user._id,
                                                         memberKeys:
                                                            upline4
                                                               .uplineLeftDataLevel31
                                                               ?.usermemberKeys,
                                                         side: 'L',
                                                         parentName:
                                                            upline4
                                                               .uplineLeftData
                                                               ?.user?.name,
                                                         parentId:
                                                            upline4
                                                               .uplineLeftData
                                                               ?.user?.userId,
                                                         userSideName:
                                                            upline4
                                                               .uplineLeftDataLevel32
                                                               ?.user?.name,
                                                         userSideId:
                                                            upline4
                                                               .uplineLeftDataLevel32
                                                               ?.user?.userId,
                                                      })}
                                                   />
                                                   <TreeNode
                                                      label={cardLevel5({
                                                         position:
                                                            upline4
                                                               .uplineLeftDataLevel32
                                                               ?.user.position,
                                                         isMobileGetPaid:
                                                            upline4
                                                               .uplineLeftDataLevel32
                                                               ?.user
                                                               ?.isMobileGetPaid,

                                                         name: upline4
                                                            .uplineLeftDataLevel32
                                                            ?.user?.name,
                                                         role: upline4
                                                            .uplineLeftDataLevel32
                                                            ?.user?.role,
                                                         expired:
                                                            upline4
                                                               .uplineLeftDataLevel32
                                                               ?.user?.expired,
                                                         userId:
                                                            upline4
                                                               .uplineLeftDataLevel32
                                                               ?.user?.userId,
                                                         userRec:
                                                            upline4
                                                               .uplineLeftDataLevel32
                                                               ?.recommendby
                                                               ?.userId,
                                                         userRecName:
                                                            upline4
                                                               .uplineLeftDataLevel32
                                                               ?.recommendby
                                                               ?.name,

                                                         pvLeft:
                                                            upline4
                                                               .uplineLeftDataLevel32
                                                               ?.pointLeft,
                                                         matching: upline4
                                                            .uplineLeftDataLevel32
                                                            ?.user
                                                            ? upline4
                                                                 .uplineLeftDataLevel32
                                                                 ?.matching
                                                            : null,
                                                         maxMatching: upline4
                                                            .uplineLeftDataLevel32
                                                            ?.user
                                                            ? upline4
                                                                 .uplineLeftDataLevel32
                                                                 ?.maxMatching
                                                            : null,
                                                         pvRight:
                                                            upline4
                                                               .uplineLeftDataLevel32
                                                               ?.userpvLivePointRight,
                                                         pvPrevLeft:
                                                            upline4
                                                               .uplineLeftDataLevel32
                                                               ?.pointLeft,
                                                         pvPrevRight:
                                                            upline4
                                                               .uplineLeftDataLevel32
                                                               ?.pointRight,
                                                         user_id:
                                                            upline4
                                                               .uplineLeftDataLevel32
                                                               ?.user._id,
                                                         memberKeys:
                                                            upline4
                                                               .uplineLeftDataLevel32
                                                               ?.usermemberKeys,
                                                         side: 'R',
                                                         parentName:
                                                            upline4
                                                               .uplineLeftData
                                                               ?.user?.name,
                                                         parentId:
                                                            upline4
                                                               .uplineLeftData
                                                               ?.user?.userId,
                                                         userSideName:
                                                            upline4
                                                               .uplineLeftDataLevel31
                                                               ?.user?.name,
                                                         userSideId:
                                                            upline4
                                                               .uplineLeftDataLevel31
                                                               ?.user?.userId,
                                                      })}
                                                   />
                                                </TreeNode>
                                                <TreeNode
                                                   label={cardLevel4({
                                                      position:
                                                         upline4.uplineRightData
                                                            ?.user?.position,

                                                      isMobileGetPaid:
                                                         upline4.uplineRightData
                                                            ?.user
                                                            ?.isMobileGetPaid,
                                                      name: upline4
                                                         .uplineRightData?.user
                                                         ?.name,
                                                      role: upline4
                                                         .uplineRightData?.user
                                                         ?.role,
                                                      userId:
                                                         upline4.uplineRightData
                                                            ?.user?.userId,
                                                      user_id:
                                                         upline4.uplineRightData
                                                            ?.user?._id,
                                                      expired:
                                                         upline4.uplineRightData
                                                            ?.user?.expired,
                                                      memberKeys:
                                                         upline4.uplineRightData
                                                            ?.user?.memberKeys,
                                                      userRec:
                                                         upline4.uplineRightData
                                                            ?.recommendby
                                                            ?.userId,
                                                      userRecName:
                                                         upline4.uplineRightData
                                                            ?.recommendby?.name,
                                                      matching:
                                                         upline4.uplineRightData
                                                            ?.matching,
                                                      maxMatching:
                                                         upline4.uplineRightData
                                                            ?.maxMatching,

                                                      pvLeft:
                                                         upline4.uplineRightData
                                                            ?.pointLeft,
                                                      pvRight:
                                                         upline4.uplineRightData
                                                            ?.pointRight,

                                                      side: 'R',
                                                      userSideName:
                                                         upline4.uplineLeftData
                                                            ?.user?.name,
                                                      userSideId:
                                                         upline4.uplineLeftData
                                                            ?.user?.userId,
                                                      parentName:
                                                         upline1
                                                            .uplineRightDataLevel31
                                                            ?.user?.name,
                                                      parentId:
                                                         upline1
                                                            .uplineRightDataLevel31
                                                            ?.user?.userId,
                                                   })}
                                                >
                                                   <TreeNode
                                                      label={cardLevel5({
                                                         position:
                                                            upline4
                                                               .uplineRightDataLevel31
                                                               ?.user.position,
                                                         isMobileGetPaid:
                                                            upline4
                                                               .uplineRightDataLevel31
                                                               ?.user
                                                               ?.isMobileGetPaid,

                                                         name: upline4
                                                            .uplineRightDataLevel31
                                                            ?.user?.name,
                                                         role: upline4
                                                            .uplineRightDataLevel31
                                                            ?.user?.role,
                                                         expired:
                                                            upline4
                                                               .uplineRightDataLevel31
                                                               ?.user?.expired,
                                                         userId:
                                                            upline4
                                                               .uplineRightDataLevel31
                                                               ?.user?.userId,
                                                         userRec:
                                                            upline4
                                                               .uplineRightDataLevel31
                                                               ?.recommendby
                                                               ?.userId,
                                                         userRecName:
                                                            upline4
                                                               .uplineRightDataLevel31
                                                               ?.recommendby
                                                               ?.name,

                                                         pvLeft:
                                                            upline4
                                                               .uplineRightDataLevel31
                                                               ?.pointLeft,
                                                         matching: upline4
                                                            .uplineRightDataLevel31
                                                            ?.user
                                                            ? upline4
                                                                 .uplineRightDataLevel31
                                                                 ?.matching
                                                            : null,
                                                         maxMatching: upline4
                                                            .uplineRightDataLevel31
                                                            ?.user
                                                            ? upline4
                                                                 .uplineRightDataLevel31
                                                                 ?.maxMatching
                                                            : null,
                                                         pvRight:
                                                            upline4
                                                               .uplineRightDataLevel31
                                                               ?.userpvLivePointRight,
                                                         pvPrevLeft:
                                                            upline4
                                                               .uplineRightDataLevel31
                                                               ?.pointLeft,
                                                         pvPrevRight:
                                                            upline4
                                                               .uplineRightDataLevel31
                                                               ?.pointRight,
                                                         user_id:
                                                            upline4
                                                               .uplineRightDataLevel31
                                                               ?.user._id,
                                                         memberKeys:
                                                            upline4
                                                               .uplineRightDataLevel31
                                                               ?.usermemberKeys,
                                                         side: 'L',
                                                         parentName:
                                                            upline4
                                                               .uplineRightData
                                                               ?.user?.name,
                                                         parentId:
                                                            upline4
                                                               .uplineRightData
                                                               ?.user?.userId,
                                                         userSideName:
                                                            upline4
                                                               .uplineLeftDataLevel32
                                                               ?.user?.name,
                                                         userSideId:
                                                            upline4
                                                               .uplineLeftDataLevel32
                                                               ?.user?.userId,
                                                      })}
                                                   />
                                                   <TreeNode
                                                      label={cardLevel5({
                                                         position:
                                                            upline4
                                                               .uplineRightDataLevel32
                                                               ?.user.position,
                                                         isMobileGetPaid:
                                                            upline4
                                                               .uplineRightDataLevel32
                                                               ?.user
                                                               ?.isMobileGetPaid,

                                                         name: upline4
                                                            .uplineRightDataLevel32
                                                            ?.user?.name,
                                                         role: upline4
                                                            .uplineRightDataLevel32
                                                            ?.user?.role,
                                                         expired:
                                                            upline4
                                                               .uplineRightDataLevel32
                                                               ?.user?.expired,
                                                         userId:
                                                            upline4
                                                               .uplineRightDataLevel32
                                                               ?.user?.userId,
                                                         userRec:
                                                            upline4
                                                               .uplineRightDataLevel32
                                                               ?.recommendby
                                                               ?.userId,
                                                         userRecName:
                                                            upline4
                                                               .uplineRightDataLevel32
                                                               ?.recommendby
                                                               ?.name,

                                                         pvLeft:
                                                            upline4
                                                               .uplineRightDataLevel32
                                                               ?.pointLeft,
                                                         matching: upline4
                                                            .uplineRightDataLevel32
                                                            ?.user
                                                            ? upline4
                                                                 .uplineRightDataLevel32
                                                                 ?.matching
                                                            : null,
                                                         maxMatching: upline4
                                                            .uplineRightDataLevel32
                                                            ?.user
                                                            ? upline4
                                                                 .uplineRightDataLevel32
                                                                 ?.maxMatching
                                                            : null,
                                                         pvRight:
                                                            upline4
                                                               .uplineRightDataLevel32
                                                               ?.userpvLivePointRight,
                                                         pvPrevLeft:
                                                            upline4
                                                               .uplineRightDataLevel32
                                                               ?.pointLeft,
                                                         pvPrevRight:
                                                            upline4
                                                               .uplineRightDataLevel32
                                                               ?.pointRight,
                                                         user_id:
                                                            upline4
                                                               .uplineRightDataLevel32
                                                               ?.user._id,
                                                         memberKeys:
                                                            upline4
                                                               .uplineRightDataLevel32
                                                               ?.usermemberKeys,
                                                         side: 'R',
                                                         parentName:
                                                            upline4
                                                               .uplineRightData
                                                               ?.user?.name,
                                                         parentId:
                                                            upline4
                                                               .uplineRightData
                                                               ?.user?.userId,
                                                         userSideName:
                                                            upline4
                                                               .uplineLeftDataLevel31
                                                               ?.user?.name,
                                                         userSideId:
                                                            upline4
                                                               .uplineLeftDataLevel31
                                                               ?.user?.userId,
                                                      })}
                                                   />
                                                </TreeNode>
                                             </>
                                          )}
                                       </TreeNode>
                                       <TreeNode
                                          label={cardLevel3({
                                             position:
                                                upline1.uplineRightDataLevel32
                                                   ?.user.position,
                                             isMobileGetPaid:
                                                upline1.uplineRightDataLevel32
                                                   ?.user?.isMobileGetPaid,

                                             name: upline1
                                                .uplineRightDataLevel32?.user
                                                ?.name,
                                             role: upline1
                                                .uplineRightDataLevel32?.user
                                                ?.role,
                                             expired:
                                                upline1.uplineRightDataLevel32
                                                   ?.user?.expired,
                                             userId:
                                                upline1.uplineRightDataLevel32
                                                   ?.user?.userId,
                                             userRec:
                                                upline1.uplineRightDataLevel32
                                                   ?.recommendby?.userId,
                                             userRecName:
                                                upline1.uplineRightDataLevel32
                                                   ?.recommendby?.name,

                                             pvLeft:
                                                liveScore7?.pvLivePointLeft7,
                                             matching:
                                                upline1.uplineRightDataLevel32
                                                   ?.matching,
                                             maxMatching:
                                                upline1.uplineRightDataLevel32
                                                   ?.maxMatching,
                                             pvRight:
                                                liveScore7?.pvLivePointRight7,
                                             pvPrevLeft:
                                                upline1.uplineRightDataLevel32
                                                   ?.pointLeft,
                                             pvPrevRight:
                                                upline1.uplineRightDataLevel32
                                                   ?.pointRight,
                                             user_id:
                                                upline1.uplineRightDataLevel32
                                                   ?.user._id,
                                             memberKeys:
                                                upline1.uplineRightDataLevel32
                                                   ?.usermemberKeys,
                                             side: 'R',
                                             parentName:
                                                upline1?.uplineRightData?.user
                                                   ?.name,
                                             parentId:
                                                upline1?.uplineRightData?.user
                                                   ?.userId,
                                             userSideName:
                                                upline1.uplineRightDataLevel31
                                                   ?.user?.name,
                                             userSideId:
                                                upline1.uplineRightDataLevel31
                                                   ?.user?.userId,
                                          })}
                                       >
                                          {isLoadingUpline5 ? (
                                             <LoadingSpinner />
                                          ) : (
                                             <>
                                                <TreeNode
                                                   label={cardLevel4({
                                                      position:
                                                         upline5.uplineLeftData
                                                            ?.user?.position,
                                                      name: upline5
                                                         .uplineLeftData?.user
                                                         ?.name,
                                                      role: upline5
                                                         .uplineLeftData?.user
                                                         ?.role,
                                                      isMobileGetPaid:
                                                         upline5.uplineLeftData
                                                            ?.user
                                                            ?.isMobileGetPaid,
                                                      userId:
                                                         upline5.uplineLeftData
                                                            ?.user?.userId,
                                                      user_id:
                                                         upline5.uplineLeftData
                                                            ?.user?._id,
                                                      expired:
                                                         upline5.uplineLeftData
                                                            ?.user?.expired,
                                                      memberKeys:
                                                         upline5.uplineLeftData
                                                            ?.user?.memberKeys,
                                                      userRec:
                                                         upline5.uplineLeftData
                                                            ?.recommendby
                                                            ?.userId,
                                                      userRecName:
                                                         upline5.uplineLeftData
                                                            ?.recommendby?.name,
                                                      matching:
                                                         upline5.uplineLeftData
                                                            ?.matching,
                                                      maxMatching:
                                                         upline5.uplineLeftData
                                                            ?.maxMatching,

                                                      pvLeft:
                                                         upline5.uplineLeftData
                                                            ?.pointLeft,
                                                      pvRight:
                                                         upline5.uplineLeftData
                                                            ?.pointRight,

                                                      side: 'L',
                                                      userSideName:
                                                         upline5.uplineRightData
                                                            ?.user?.name,
                                                      userSideId:
                                                         upline5.uplineRightData
                                                            ?.user?.userId,
                                                      parentName:
                                                         upline1
                                                            .uplineRightDataLevel32
                                                            ?.user?.name,
                                                      parentId:
                                                         upline1
                                                            .uplineRightDataLevel32
                                                            ?.user?.userId,
                                                   })}
                                                >
                                                   <TreeNode
                                                      label={cardLevel5({
                                                         position:
                                                            upline5
                                                               .uplineLeftDataLevel31
                                                               ?.user?.position,
                                                         isMobileGetPaid:
                                                            upline5
                                                               .uplineLeftDataLevel31
                                                               ?.user
                                                               ?.isMobileGetPaid,

                                                         name: upline5
                                                            .uplineLeftDataLevel31
                                                            ?.user?.name,
                                                         role: upline5
                                                            .uplineLeftDataLevel31
                                                            ?.user?.role,
                                                         expired:
                                                            upline5
                                                               .uplineLeftDataLevel31
                                                               ?.user?.expired,
                                                         userId:
                                                            upline5
                                                               .uplineLeftDataLevel31
                                                               ?.user?.userId,
                                                         userRec:
                                                            upline5
                                                               .uplineLeftDataLevel31
                                                               ?.recommendby
                                                               ?.userId,
                                                         userRecName:
                                                            upline5
                                                               .uplineLeftDataLevel31
                                                               ?.recommendby
                                                               ?.name,

                                                         pvLeft:
                                                            upline5
                                                               .uplineLeftDataLevel31
                                                               ?.pointLeft,
                                                         matching: upline5
                                                            .uplineLeftDataLevel31
                                                            ?.user
                                                            ? upline5
                                                                 .uplineLeftDataLevel31
                                                                 ?.matching
                                                            : null,
                                                         maxMatching: upline5
                                                            .uplineLeftDataLevel31
                                                            ?.user
                                                            ? upline5
                                                                 .uplineLeftDataLevel31
                                                                 ?.maxMatching
                                                            : null,
                                                         pvRight:
                                                            upline5
                                                               .uplineLeftDataLevel31
                                                               ?.userpvLivePointRight,
                                                         pvPrevLeft:
                                                            upline5
                                                               .uplineLeftDataLevel31
                                                               ?.pointLeft,
                                                         pvPrevRight:
                                                            upline5
                                                               .uplineLeftDataLevel31
                                                               ?.pointRight,
                                                         user_id:
                                                            upline5
                                                               .uplineLeftDataLevel31
                                                               ?.user._id,
                                                         memberKeys:
                                                            upline5
                                                               .uplineLeftDataLevel31
                                                               ?.usermemberKeys,
                                                         side: 'L',
                                                         parentName:
                                                            upline5
                                                               .uplineLeftData
                                                               ?.user?.name,
                                                         parentId:
                                                            upline5
                                                               .uplineLeftData
                                                               ?.user?.userId,
                                                         userSideName:
                                                            upline5
                                                               .uplineLeftDataLevel32
                                                               ?.user?.name,
                                                         userSideId:
                                                            upline5
                                                               .uplineLeftDataLevel32
                                                               ?.user?.userId,
                                                      })}
                                                   />
                                                   <TreeNode
                                                      label={cardLevel5({
                                                         position:
                                                            upline5
                                                               .uplineLeftDataLevel32
                                                               ?.user.position,
                                                         isMobileGetPaid:
                                                            upline5
                                                               .uplineLeftDataLevel32
                                                               ?.user
                                                               ?.isMobileGetPaid,

                                                         name: upline5
                                                            .uplineLeftDataLevel32
                                                            ?.user?.name,
                                                         role: upline5
                                                            .uplineLeftDataLevel32
                                                            ?.user?.role,
                                                         expired:
                                                            upline5
                                                               .uplineLeftDataLevel32
                                                               ?.user?.expired,
                                                         userId:
                                                            upline5
                                                               .uplineLeftDataLevel32
                                                               ?.user?.userId,
                                                         userRec:
                                                            upline5
                                                               .uplineLeftDataLevel32
                                                               ?.recommendby
                                                               ?.userId,
                                                         userRecName:
                                                            upline5
                                                               .uplineLeftDataLevel32
                                                               ?.recommendby
                                                               ?.name,

                                                         pvLeft:
                                                            upline5
                                                               .uplineLeftDataLevel32
                                                               ?.pointLeft,
                                                         matching: upline5
                                                            .uplineLeftDataLevel32
                                                            ?.user
                                                            ? upline5
                                                                 .uplineLeftDataLevel32
                                                                 ?.matching
                                                            : null,
                                                         maxMatching: upline5
                                                            .uplineLeftDataLevel32
                                                            ?.user
                                                            ? upline5
                                                                 .uplineLeftDataLevel32
                                                                 ?.maxMatching
                                                            : null,
                                                         pvRight:
                                                            upline4
                                                               .uplineLeftDataLevel32
                                                               ?.userpvLivePointRight,
                                                         pvPrevLeft:
                                                            upline4
                                                               .uplineLeftDataLevel32
                                                               ?.pointLeft,
                                                         pvPrevRight:
                                                            upline4
                                                               .uplineLeftDataLevel32
                                                               ?.pointRight,
                                                         user_id:
                                                            upline4
                                                               .uplineLeftDataLevel32
                                                               ?.user._id,
                                                         memberKeys:
                                                            upline4
                                                               .uplineLeftDataLevel32
                                                               ?.usermemberKeys,
                                                         side: 'R',
                                                         parentName:
                                                            upline5
                                                               .uplineLeftData
                                                               ?.user?.name,
                                                         parentId:
                                                            upline5
                                                               .uplineLeftData
                                                               ?.user?.userId,
                                                         userSideName:
                                                            upline5
                                                               .uplineLeftDataLevel31
                                                               ?.user?.name,
                                                         userSideId:
                                                            upline5
                                                               .uplineLeftDataLevel31
                                                               ?.user?.userId,
                                                      })}
                                                   />
                                                </TreeNode>
                                                <TreeNode
                                                   label={cardLevel4({
                                                      farRight: true,
                                                      position:
                                                         upline5.uplineRightData
                                                            ?.user?.position,

                                                      isMobileGetPaid:
                                                         upline5.uplineRightData
                                                            ?.user
                                                            ?.isMobileGetPaid,
                                                      name: upline5
                                                         .uplineRightData?.user
                                                         ?.name,
                                                      role: upline5
                                                         .uplineRightData?.user
                                                         ?.role,
                                                      userId:
                                                         upline5.uplineRightData
                                                            ?.user?.userId,
                                                      user_id:
                                                         upline5.uplineRightData
                                                            ?.user?._id,
                                                      expired:
                                                         upline5.uplineRightData
                                                            ?.user?.expired,
                                                      memberKeys:
                                                         upline5.uplineRightData
                                                            ?.user?.memberKeys,
                                                      userRec:
                                                         upline5.uplineRightData
                                                            ?.recommendby
                                                            ?.userId,
                                                      userRecName:
                                                         upline5.uplineRightData
                                                            ?.recommendby?.name,
                                                      matching:
                                                         upline5.uplineRightData
                                                            ?.matching,
                                                      maxMatching:
                                                         upline5.uplineRightData
                                                            ?.maxMatching,

                                                      pvLeft:
                                                         upline5.uplineRightData
                                                            ?.pointLeft,
                                                      pvRight:
                                                         upline5.uplineRightData
                                                            ?.pointRight,

                                                      side: 'R',
                                                      userSideName:
                                                         upline5.uplineLeftData
                                                            ?.user?.name,
                                                      userSideId:
                                                         upline5.uplineLeftData
                                                            ?.user?.userId,
                                                      parentName:
                                                         upline1
                                                            .uplineRightDataLevel32
                                                            ?.user?.name,
                                                      parentId:
                                                         upline1
                                                            .uplineRightDataLevel32
                                                            ?.user?.userId,
                                                   })}
                                                >
                                                   <TreeNode
                                                      label={cardLevel5({
                                                         farRight: true,
                                                         position:
                                                            upline5
                                                               .uplineRightDataLevel31
                                                               ?.user.position,
                                                         isMobileGetPaid:
                                                            upline5
                                                               .uplineRightDataLevel31
                                                               ?.user
                                                               ?.isMobileGetPaid,

                                                         name: upline5
                                                            .uplineRightDataLevel31
                                                            ?.user.name,
                                                         role: upline5
                                                            .uplineRightDataLevel31
                                                            ?.user.role,
                                                         expired:
                                                            upline5
                                                               .uplineRightDataLevel31
                                                               ?.user.expired,
                                                         userId:
                                                            upline5
                                                               .uplineRightDataLevel31
                                                               ?.user.userId,
                                                         userRec:
                                                            upline5
                                                               .uplineRightDataLevel31
                                                               ?.recommendby
                                                               .userId,
                                                         userRecName:
                                                            upline5
                                                               .uplineRightDataLevel31
                                                               ?.recommendby
                                                               .name,

                                                         pvLeft:
                                                            upline5
                                                               .uplineRightDataLevel31
                                                               ?.pointLeft,
                                                         matching: upline5
                                                            .uplineRightDataLevel31
                                                            ?.user
                                                            ? upline5
                                                                 .uplineRightDataLevel31
                                                                 ?.matching
                                                            : null,
                                                         maxMatching: upline5
                                                            .uplineRightDataLevel31
                                                            ?.user
                                                            ? upline5
                                                                 .uplineRightDataLevel31
                                                                 ?.maxMatching
                                                            : null,
                                                         pvRight:
                                                            upline5
                                                               .uplineRightDataLevel31
                                                               ?.userpvLivePointRight,
                                                         pvPrevLeft:
                                                            upline5
                                                               .uplineRightDataLevel31
                                                               ?.pointLeft,
                                                         pvPrevRight:
                                                            upline5
                                                               .uplineRightDataLevel31
                                                               ?.pointRight,
                                                         user_id:
                                                            upline5
                                                               .uplineRightDataLevel31
                                                               ?.user?._id,
                                                         memberKeys:
                                                            upline5
                                                               .uplineRightDataLevel31
                                                               ?.usermemberKeys,
                                                         side: 'L',
                                                         parentName:
                                                            upline5
                                                               .uplineRightData
                                                               ?.user?.name,
                                                         parentId:
                                                            upline5
                                                               .uplineRightData
                                                               ?.user?.userId,
                                                         userSideName:
                                                            upline5
                                                               .uplineRightDataLevel32
                                                               ?.user?.name,
                                                         userSideId:
                                                            upline5
                                                               .uplineRightDataLevel32
                                                               ?.user?.userId,
                                                      })}
                                                   />

                                                   <TreeNode
                                                      label={cardLevel5({
                                                         farRight: true,
                                                         position:
                                                            upline5
                                                               .uplineRightDataLevel32
                                                               ?.user.position,
                                                         isMobileGetPaid:
                                                            upline5
                                                               .uplineRightDataLevel32
                                                               ?.user
                                                               ?.isMobileGetPaid,

                                                         name: upline5
                                                            .uplineRightDataLevel32
                                                            ?.user.name,
                                                         role: upline5
                                                            .uplineRightDataLevel32
                                                            ?.user.role,
                                                         expired:
                                                            upline5
                                                               .uplineRightDataLevel32
                                                               ?.user?.expired,
                                                         userId:
                                                            upline5
                                                               .uplineRightDataLevel32
                                                               ?.user?.userId,
                                                         userRec:
                                                            upline5
                                                               .uplineRightDataLevel32
                                                               ?.recommendby
                                                               ?.userId,
                                                         userRecName:
                                                            upline5
                                                               .uplineRightDataLevel32
                                                               ?.recommendby
                                                               ?.name,

                                                         pvLeft:
                                                            upline5
                                                               .uplineRightDataLevel32
                                                               ?.pointLeft,
                                                         matching: upline5
                                                            .uplineRightDataLevel32
                                                            ?.user
                                                            ? upline5
                                                                 .uplineRightDataLevel32
                                                                 ?.matching
                                                            : null,
                                                         maxMatching: upline5
                                                            .uplineRightDataLevel32
                                                            ?.user
                                                            ? upline5
                                                                 .uplineRightDataLevel32
                                                                 ?.maxMatching
                                                            : null,
                                                         pvRight:
                                                            upline5
                                                               .uplineRightDataLevel32
                                                               ?.userpvLivePointRight,
                                                         pvPrevLeft:
                                                            upline5
                                                               .uplineRightDataLevel32
                                                               ?.pointLeft,
                                                         pvPrevRight:
                                                            upline5
                                                               .uplineRightDataLevel32
                                                               ?.pointRight,
                                                         user_id:
                                                            upline5
                                                               .uplineRightDataLevel32
                                                               ?.user._id,
                                                         memberKeys:
                                                            upline5
                                                               .uplineRightDataLevel32
                                                               ?.usermemberKeys,
                                                         side: 'R',
                                                         parentName:
                                                            upline5
                                                               .uplineRightData
                                                               ?.user?.name,
                                                         parentId:
                                                            upline5
                                                               .uplineRightData
                                                               ?.user?.userId,
                                                         userSideName:
                                                            upline5
                                                               .uplineRightDataLevel31
                                                               ?.user?.name,
                                                         userSideId:
                                                            upline5
                                                               .uplineRightDataLevel31
                                                               ?.user?.userId,
                                                      })}
                                                   />
                                                </TreeNode>
                                             </>
                                          )}
                                       </TreeNode>
                                    </TreeNode>

                                    {upline1.uplineRightData?.user?.userId && (
                                       <PointL3>
                                          <p className='m-1 mr-3'>
                                             เก่า =
                                             <span className='m-1 fw-bold'>
                                                {commaNumber(
                                                   upline1?.uplineRightData
                                                      ?.pointLeft
                                                )}
                                             </span>
                                          </p>
                                          {isLiveScoreLoading2 ? (
                                             <>
                                                <p>Loading...</p>
                                             </>
                                          ) : (
                                             <>
                                                <p className='m-1 mr-3'>
                                                   ใหม่ =
                                                   <span className='m-1 fw-bold text-primary'>
                                                      {commaNumber(
                                                         liveScore2?.pvLivePointLeft2
                                                      )}
                                                   </span>
                                                </p>
                                                <p className='m-1 mr-3'>
                                                   รวม =
                                                   <span className='m-1 fw-bold text-danger'>
                                                      {commaNumber(
                                                         liveScore2?.pvLivePointLeft2 +
                                                            upline1
                                                               ?.uplineRightData
                                                               ?.pointLeft
                                                      )}
                                                   </span>
                                                </p>
                                             </>
                                          )}
                                       </PointL3>
                                    )}
                                 </Tree>
                                 <div className='d-flex justify-content-between mt-2 pt-3 mx-3'>
                                    {upline2.uplineLeftDataLevel31?.user
                                       ?.userId ? (
                                       <>
                                          <ImArrowDownLeft2
                                             onClick={() => {
                                                dispatch(
                                                   getUserBottomL(
                                                      upline2
                                                         .uplineLeftDataLevel31
                                                         ?.user?.userId
                                                   )
                                                );
                                             }}
                                             style={{ marginLeft: '50px' }}
                                             className='cursor-pointer '
                                             size={32}
                                          />
                                       </>
                                    ) : (
                                       <div></div>
                                    )}

                                    {upline5.uplineRightDataLevel32?.user
                                       ?.userId && (
                                       <ImArrowDownRight2
                                          onClick={() => {
                                             dispatch(
                                                getUserBottomR(
                                                   upline5
                                                      .uplineRightDataLevel32
                                                      ?.user?.userId
                                                )
                                             );
                                          }}
                                          style={{ marginRight: '50px' }}
                                          className='cursor-pointer'
                                          size={32}
                                       />
                                    )}
                                 </div>

                                 <PointL1>
                                    <p className='m-1 mr-3'>
                                       เก่า =
                                       <span className='m-1 fw-bold'>
                                          {commaNumber(
                                             upline1?.uplineData?.pointLeft
                                          )}
                                       </span>
                                    </p>
                                    {isLiveScoreLoading ? (
                                       <>
                                          <p>Loading...</p>
                                       </>
                                    ) : (
                                       <>
                                          <p
                                             onClick={() =>
                                                navigate(
                                                   '/liveScoreDetail/left'
                                                )
                                             }
                                             className='m-1 mr-3 cursor-pointer'
                                          >
                                             ใหม่ =
                                             <span className='m-1 fw-bold text-primary'>
                                                {commaNumber(
                                                   liveScore?.pvLivePointLeft
                                                )}
                                             </span>
                                          </p>
                                          <p className='m-1 mr-3'>
                                             รวม =
                                             <span className='m-1 fw-bold text-danger'>
                                                {liveScore?.pvLivePointLeft !==
                                                   undefined &&
                                                liveScore?.pvLivePointLeft !==
                                                   null ? (
                                                   <span className='m-1 fw-bold text-danger'>
                                                      {commaNumber(
                                                         liveScore.pvLivePointLeft +
                                                            (upline1?.uplineData
                                                               ?.pointLeft || 0)
                                                      )}
                                                   </span>
                                                ) : (
                                                   <span className='m-1 fw-bold text-danger'>
                                                      0
                                                   </span>
                                                )}
                                             </span>
                                          </p>
                                       </>
                                    )}
                                 </PointL1>
                              </>
                           ) : null}
                        </div>
                     </>
                  )}
               </>
            )}
         </>
         <Modal
            title=''
            visible={modal}
            footer={false}
            onCancel={() => {
               setModal(false);
               navigate('/');
            }}
         >
            <div
               style={{
                  height: '80vh',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'center',
                  position: 'relative',
                  zIndex: '99999',
               }}
               className='text-center'
            >
               <FaTimes size={128} color='red' />
               <h5>
                  <b>{message}</b>
               </h5>
            </div>
         </Modal>
      </DefaultLayout>
   );
};

const sizes = {
   mobileS: '320px',
   mobileM: '375px',
   mobileL: '558px',
   tablet: '768px',
   laptop: '1024px',
   laptopL: '1440px',
   laptopXL: '1740px',
   desktop: '2560px',
};

const device = {
   mobileS: `(max-width: ${sizes.mobileS})`,
   mobileM: `(max-width: ${sizes.mobileM})`,
   mobileL: `(max-width: ${sizes.mobileL})`,
   tablet: `(max-width: ${sizes.tablet})`,
   laptop: `(max-width: ${sizes.laptop})`,
   laptopL: `(max-width: ${sizes.laptopL})`,
   laptopXL: `(max-width: ${sizes.laptopXL})`,
   desktop: `(max-width: ${sizes.desktop})`,
};

const PointR1 = styled.div`
   position: absolute;
   width: 200px;
   left: calc(50% + 80px);
   top: 14px;
   z-index: 99;

   @media ${device.laptop} {
      left: calc(50% + 80px);
   }

   @media ${device.tablet} {
      left: calc(50% + 80px);
      top: 50px;
   }

   @media ${device.mobileL} {
      left: calc(50% + 80px);
      font-size: 0.75rem !important;
   }
   @media ${device.mobileM} {
      left: calc(50% + 70px);
      font-size: 0.75rem !important;
   }
`;

const PointL1 = styled.div`
   position: absolute;
   top: 14px;
   width: 200px;
   left: calc(50% - 230px);

   @media ${device.laptop} {
      left: calc(50% - 230px);
   }

   @media ${device.tablet} {
      left: calc(50% - 180px);
      top: 50px;
   }

   @media ${device.mobileL} {
      left: calc(50% - 150px);
      font-size: 0.75rem !important;
   }
   @media ${device.mobileM} {
      left: calc(50% - 150px);
      font-size: 0.75rem !important;
   }
`;

const PointR2 = styled.div`
   position: absolute;
   top: 30px;
   left: calc(25% + 60px);
   width: 200px;
   display: block;
   text-align: start;

   @media ${device.laptopXL} {
      left: calc(25% + 60px);
   }
   @media ${device.laptopL} {
      left: calc(28% + 50px);
   }
   @media ${device.laptop} {
      left: calc(28% + 28px) !important;
      font-size: 12px !important;
      top: 35px;
      z-index: 99999;
   }
   @media ${device.tablet} {
      left: calc(28% + 45px) !important;
      font-size: 12px !important;
      top: 33px;
   }
   @media ${device.mobileL} {
      display: none;
   }
`;

const PointL2 = styled.div`
   position: absolute;
   top: 33px;
   left: calc(25% - 180px);
   display: block;
   width: 200px;
   text-align: start;

   @media ${device.laptopL} {
      left: calc(25% - 230px);
   }
   @media ${device.laptop} {
      left: calc(30% - 200px) !important;
      font-size: 12px !important;
      top: 33px;
   }
   @media ${device.tablet} {
      left: calc(30% - 200px) !important;
      display: block !important;
   }
   @media ${device.mobileL} {
      display: none !important;
   }
`;

const PointR3 = styled.div`
   position: absolute;
   top: 30px;
   left: calc(75% + 60px);
   display: block;
   width: 200px;
   text-align: start;

   @media ${device.laptopL} {
      left: calc(75% + 60px);
   }
   @media ${device.laptop} {
      left: calc(77% + 30px);
      font-size: 12px !important;
      top: 33px;
      z-index: 9999;
   }
   @media ${device.tablet} {
      left: calc(80% + 30px);
      z-index: 9999;
   }
   @media ${device.mobileL} {
      display: none;
   }
`;

const PointL3 = styled.div`
   position: absolute;
   top: 33px;
   width: 200px;
   display: block;
   left: calc(75% - 180px);
   text-align: start;

   @media ${device.desktop} {
      left: calc(75% - 180px);
   }
   @media ${device.laptopL} {
      left: calc(75% - 230px);
   }
   @media ${device.laptop} {
      left: calc(80% - 200px) !important;
      font-size: 12px !important;
      top: 33px;
   }
   @media ${device.mobileL} {
      display: none;
   }
`;

export default BinaryView3;
