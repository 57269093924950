import {
   Button,
   Card,
   List,
   Col,
   Row,
   Avatar,
   InputNumber,
   Tooltip,
} from 'antd';
import React, { useEffect, useState } from 'react';
import { Container, Fade } from 'react-bootstrap';
import DefaultLayout from '../components/DefaultLayout';
import { TbPackage } from 'react-icons/tb';

import {
   BsFillCartPlusFill,
   BsFillDashCircleFill,
   BsPlusCircleFill,
} from 'react-icons/bs';
import { addToCart } from '../features/cartSlice';
import { useDispatch, useSelector } from 'react-redux';
import { getProducts } from '../features/product/productSlice';
import { useNavigate } from 'react-router-dom';
import FadeIn from 'react-fade-in/lib/FadeIn';
//import { products } from '../daumpData';

const { Meta } = Card;
const OrderPage = () => {
   const dispatch = useDispatch();
   const navigate = useNavigate();

   const [isTopup, setIsTopUp] = useState(false);
   const [isRenew, setIsRenew] = useState(false);

   const [quantity, setQty] = useState(1);
   const [quantities, setQuantities] = useState({}); // Object to store quantities for each product

   const {
      products,
      isLoading,
      isError,
      isSuccess,
      getLoading,
      getSuccess,
      deleteSuccess,
      createSuccess,
   } = useSelector((state) => state.product);

   const ProductSell = products.filter((e) => e.type === 'Sell');
   const ProductTester = products.filter((e) => e.type === 'Tester');

   useEffect(() => {
      dispatch(getProducts());
   }, []);

   return (
      <DefaultLayout>
         <Container>
            <div style={{ overflow: 'hidden' }} className='my-5'>
               <div style={{ marginTop: '20px' }}>
                  <h3 className='text-center mb-2' style={{ fontSize: '36px' }}>
                     {' '}
                     <b>เลือกสินค้าแพ็คเกจ</b>{' '}
                  </h3>
                  <br />

                  <Row align='center' className='text-center mb-3'>
                     <Col sm={24} xs={24} md={12} lg={12} xl={12}>
                        <p
                           className='cursor-pointer btn-chooseProduct'
                           onClick={() => {
                              setIsRenew((prevState) => !prevState);
                              setIsTopUp(false);
                           }}
                        >
                           <b> ซื้อสินค้า (ต่ออายุ/ซื้อซ้ำ)</b>
                        </p>
                     </Col>
                     <Col sm={24} xs={24} md={12} lg={12} xl={12}>
                        <p
                           className='cursor-pointer btn-chooseProduct'
                           onClick={() => {
                              setIsTopUp((prevState) => !prevState);
                              setIsRenew(false);
                           }}
                        >
                           <b> Topup รหัส </b>
                        </p>
                     </Col>
                  </Row>
                  {isRenew && (
                     <>
                        <div
                           className=' d-flex flex-row '
                           style={{ height: '' }}
                        >
                           <div
                              className='mt-auto mr-2'
                              style={{
                                 borderLeft: '10px solid #ff5500',
                                 height: '40px',
                              }}
                           ></div>
                           <div>
                              <h5
                                 className='mt-5 ml-2'
                                 style={{ marginLeft: '10px' }}
                              >
                                 <b>ซื้อสินค้า (ต่ออายุ/ซื้อซ้ำ)</b>
                              </h5>
                             
                           </div>
                        </div>
                        <div></div>
                        <hr />
                        <Row gutter={[10, 30]} justify='space-evenly'>
                           {ProductTester.map((product) => {
                              return (
                                 <FadeIn>
                                    <Col
                                       key={product.id}
                                       sm={24}
                                       xs={24}
                                       md={24}
                                       lg={8}
                                       xl={8}
                                    >
                                       <Card
                                          size='big'
                                          hoverable
                                          style={{
                                             width: 300,
                                             height: 500,
                                             marginLeft: 'auto',
                                             marginRight: 'auto',
                                             textAlign: 'end',
                                          }}
                                          cover={
                                             <img
                                                alt={product.name}
                                                style={{
                                                   height: '300px',
                                                   objectFit: 'cover',
                                                }}
                                                src={product.picUrl.url}
                                             />
                                          }
                                       >
                                          <h4 className='fw-bold'>
                                             {' '}
                                             {product.name}
                                          </h4>
                                          {/* <p class="fw-light fs-6">
                           {" "}
                           Top up เทสเตอร์ เป็น เมมเบอร์{" "}
                         </p> */}

                                          <div
                                             style={{
                                                position: 'absolute',
                                                right: '15px',
                                                bottom: '50px',
                                             }}
                                          >
                                             <h5
                                                className='fw-bold'
                                                style={{ color: '#ff5500' }}
                                             >
                                                {' '}
                                                {product.pv} PV
                                             </h5>
                                          </div>
                                          <Row
                                             justify='space-between'
                                             style={{
                                                textAlign: 'end',
                                                alignItems: 'center',
                                                position: 'absolute',
                                                bottom: '5px',
                                                width: '90%',
                                             }}
                                          >
                                             <Col span={24}>
                                                <Button
                                                   onClick={() => {
                                                      navigate(
                                                         `/orders/orderCheckBillRenew/${product.name}/${product.pv}`
                                                      );
                                                   }}
                                                   className='btn-orange'
                                                   type='primary'
                                                   size='large'
                                                   block
                                                >
                                                   ซื้อสินค้า
                                                </Button>
                                             </Col>
                                          </Row>
                                       </Card>
                                    </Col>
                                 </FadeIn>
                              );
                           })}
                        </Row>
                     </>
                  )}

                  {isTopup && (
                     <>
                        <div
                           className=' d-flex flex-row '
                           style={{ height: '' }}
                        >
                           <div
                              className='mt-auto mr-2'
                              style={{
                                 borderLeft: '10px solid #ff5500',
                                 height: '40px',
                              }}
                           ></div>
                           <h5
                              className='mt-5 ml-2'
                              style={{ marginLeft: '10px' }}
                           >
                              <b>Topup รหัส</b>
                           </h5>
                        </div>
                        <hr />
                        <Row gutter={[10, 30]} justify='space-evenly'>
                           {ProductSell.map((product) => {
                              return (
                                 <FadeIn>
                                    <Col
                                       key={product.id}
                                       sm={24}
                                       xs={24}
                                       md={24}
                                       lg={12}
                                       xl={12}
                                    >
                                       <Card
                                          size='big'
                                          hoverable
                                          style={{
                                             width: 300,
                                             height: 500,
                                             marginLeft: 'auto',
                                             marginRight: 'auto',
                                             textAlign: 'end',
                                          }}
                                          cover={
                                             <img
                                                alt={product.name}
                                                style={{
                                                   height: '300px',
                                                   objectFit: 'cover',
                                                }}
                                                src={product.picUrl.url}
                                             />
                                          }
                                       >
                                          <h4 className='fw-bold'>
                                             {' '}
                                             {product.name}
                                          </h4>
                                          <p class='fw-light fs-6'>
                                             {' '}
                                             {product.desc}
                                          </p>

                                          <div
                                             style={{
                                                position: 'absolute',
                                                right: '15px',
                                                bottom: '50px',
                                             }}
                                          >
                                             <h5
                                                className='fw-bold'
                                                style={{ color: '#ff5500' }}
                                             >
                                                {' '}
                                                {product.pv} PV
                                             </h5>
                                          </div>
                                          <Row
                                             justify='space-between'
                                             style={{
                                                textAlign: 'end',
                                                alignItems: 'center',
                                                position: 'absolute',
                                                bottom: '5px',
                                                width: '90%',
                                             }}
                                          >
                                             <Col span={24}>
                                                <Button
                                                   onClick={() => {
                                                      navigate(
                                                         `/orders/orderCheckBill/${product.name}/${product.pv}`
                                                      );
                                                   }}
                                                   className='btn-orange'
                                                   type='primary'
                                                   size='large'
                                                   block
                                                >
                                                   เลือกแพ็คเกจ
                                                </Button>
                                             </Col>
                                          </Row>
                                       </Card>
                                    </Col>
                                 </FadeIn>
                              );
                           })}
                        </Row>
                     </>
                  )}
               </div>
            </div>
         </Container>{' '}
      </DefaultLayout>
   );
};

export default OrderPage;
