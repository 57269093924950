import { Form, Input, Button, Col, Row, DatePicker, Image, Modal } from 'antd';
import React, { useEffect, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import DefaultLayout from '../components/DefaultLayout';
import { getUser, updateUser } from '../features/users/usersSlice';

import { GrMap } from 'react-icons/gr';
import styled from 'styled-components';

import UserLogo2 from '../assets/icon/user-logo2.png';

import { toast } from 'react-toastify';
import Loading from '../components/Loading';
import moment from 'moment';
import TextArea from 'antd/lib/input/TextArea';
import { TbAlertTriangleFilled } from 'react-icons/tb';
import { GiConfirmed } from 'react-icons/gi';

const ProfileEditPage = () => {
   const [media, setMedia] = useState(null);

   const lang = useSelector((state) => state.language.lang);

   const [modalSuccess, setModalSuccess] = useState(false);

   const [modalConfirm, setModalConfirm] = useState(false);

   const [dataUpdate, setDataUpdate] = useState(null);

   const dispatch = useDispatch();

   const { userInfo } = useSelector((state) => state.auth);

   const {
      user,
      isLoading,
      isSuccess,
      isUpdateSuccess,
      message: messageUser,
   } = useSelector((state) => state.user);

   const [form] = Form.useForm();
   const [loadingPage, setLoadingPage] = useState(true);

   useEffect(() => {
      if (loadingPage) {
         setTimeout(() => {
            setLoadingPage(false);
         }, 1500);
      }

      if (isUpdateSuccess) {
         toast.success('แก้ไขข้อมูลเซ็นเตอร์สำเร็จ', {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
         });
      }

      if (isUpdateSuccess) {
         setModalSuccess(true);
      }

      dispatch(getUser(userInfo._id));

      form.setFieldsValue({
         name: user.name,
         firstName: user.firstName,
         lastName: user.lastName,
         user: user.cardId,
      });
   }, [
      dispatch,
      form,
      user.name,
      user.firstName,
      user.lastName,
      isUpdateSuccess,
      isSuccess,
   ]);

   const onFinish = (values) => {
      const address = {
         address: values.address,
         province: values.province,
         district: values.district,
         subdistrict: values.subdistrict,
         postalCode: values.postalCode,
      };

      const contact = {
         tel: values.tel,
         email: values.email,
         lineId: values.lineId,
      };
      const bankData = {
         bank: values.bank,
         bankAccount: values.bankAccount,
         bankName: values.bankName,
      };

      if (media !== null) values.picUrl = media;
      values.address = address;
      values.contact = contact;
      values.bankData = bankData;

      setDataUpdate(values);
   };

   const handlerUpdateUser = (event) => {
      //  event.preventDefault()

      console.log(dataUpdate);

      dispatch(updateUser(dataUpdate));

      setModalConfirm(false);
   };

   const handleProductImageUpload = (e) => {
      const file = e.target.files[0];

      TransformFileData(file);
   };

   const TransformFileData = (file) => {
      const reader = new FileReader();

      if (file) {
         reader.readAsDataURL(file);
         reader.onloadend = () => {
            setMedia(reader.result);
         };
      } else {
         setMedia('');
      }
   };

   return (
      <>
         <DefaultLayout>
            {loadingPage ? (
               <>
                  <Loading />
               </>
            ) : (
               <>
                  {' '}
                  {isLoading ? (
                     <Loading />
                  ) : (
                     <>
                        {user ? (
                           <div className=' form-block '>
                              <h3
                                 style={{ fontSize: '20px' }}
                                 className='text-center mb-3'
                              >
                                 {lang === 'TH' && <b>ประวัติสมาชิก</b>}
                                 {lang === 'ENG' && <b>Member Information</b>}
                                 {lang === 'KH' && <b>ប្រវត្តិសមាជិក</b>}
                              </h3>

                              <div
                                 className='d-flex justify-content-center my-3'
                                 style={{ marginTop: '50px' }}
                              >
                                 {media ? (
                                    <Image
                                       className='m-auto'
                                       preview={false}
                                       height={100}
                                       width={100}
                                       src={media}
                                    />
                                 ) : (
                                    <Image
                                       className='m-auto'
                                       preview={false}
                                       height={100}
                                       width={100}
                                       src={UserLogo2}
                                    />
                                 )}
                              </div>
                              <input
                                 hidden
                                 id='upload'
                                 name='imgUpload'
                                 accept='image/*'
                                 type='file'
                                 onChange={handleProductImageUpload}
                              />

                              <Form
                                 form={form}
                                 name='basic'
                                 labelCol={{
                                    span: 7,
                                 }}
                                 wrapperCol={{
                                    span: 17,
                                 }}
                                 initialValues={{
                                    name: user.name,
                                    firstName: user.firstName,
                                    lastName: user.lastName,
                                    createdAt: user.createdAt,
                                    cardId: user.cardId,
                                    beneficiary: user.beneficiary,
                                    lineId: user.contact
                                       ? user.contact.lineId
                                       : null,
                                    tel: user.contact ? user.contact.tel : null,
                                    email: user.contact
                                       ? user.contact.email
                                       : null,
                                    bank: user.bankData
                                       ? user.bankData.bank
                                       : null,
                                    bankName: user.bankData
                                       ? user.bankData.bankName
                                       : null,
                                    bankAccount: user.bankData
                                       ? user.bankData.bankAccount
                                       : null,
                                    address: user.address
                                       ? user.address.address
                                       : null,
                                    subdistrict: user.address
                                       ? user.address.subdistrict
                                       : null,
                                    district: user.address
                                       ? user.address.district
                                       : null,
                                    province: user.address
                                       ? user.address.province
                                       : null,
                                    amphoe: user.address
                                       ? user.address.amphoe
                                       : null,
                                    postalCode: user.address
                                       ? user.address.postalCode
                                       : null,
                                 }}
                                 onFinish={onFinish}
                                 autoComplete='off'
                              >
                                 <h5
                                    style={{ fontSize: '18px' }}
                                    className='text-start '
                                 >
                                       {lang === 'TH' && <b>ข้อมูลส่วนตัว</b>}
        {lang === 'ENG' && <b>Personal Information</b>}
        {lang === 'KH' && <b>ព័ត៌មានផ្ទាល់ខ្លួន</b>}
                                 </h5>

                                 <Row className=''>
                                    <Col
                                       style={{ height: '80px' }}
                                       xs={24}
                                       sm={24}
                                       md={12}
                                       lg={12}
                                    >
                                       {' '}
                                       <Form.Item
                                            label={
                                             lang === 'TH' ? 'ชื่อที่ใช้แสดง' :
                                             lang === 'ENG' ? 'Display Name' :
                                             lang === 'KH' ? 'ឈ្មោះបង្ហាញ' : 'Display Name'
                                           }
                                          name='name'
                                       >
                                          <Input />
                                       </Form.Item>
                                    </Col>

                                    <Col
                                       style={{ height: '80px' }}
                                       xs={24}
                                       sm={24}
                                       md={12}
                                       lg={12}
                                    >
                                       <Form.Item
                                         label={
                                          lang === 'TH' ? 'ชื่อ' :
                                          lang === 'ENG' ? 'Name' :
                                          lang === 'KH' ? 'ឈ្មោះ' : 'Name'
                                        }
                                         name='firstName'>
                                          <Input
                                             style={{ color: 'black' }}
                                             disabled
                                          />
                                       </Form.Item>
                                    </Col>
                                    <Col
                                       style={{ height: '80px' }}
                                       xs={24}
                                       sm={24}
                                       md={12}
                                       lg={12}
                                    >
                                       {' '}
                                       <Form.Item
                                            label={
                                             lang === 'TH' ? 'นามสกุล' :
                                             lang === 'ENG' ? 'Last Name' :
                                             lang === 'KH' ? 'គោត្តនាម' : 'Last Name'
                                           }
                                          name='lastName'
                                       >
                                          <Input
                                             style={{ color: 'black' }}
                                             disabled
                                          />
                                       </Form.Item>
                                    </Col>
                                    <Col
                                       style={{ height: '80px' }}
                                       xs={24}
                                       sm={24}
                                       md={12}
                                       lg={12}
                                    >
                                       <Form.Item
                                          name='createdAt'
                                          label={
                                             lang === 'TH' ? 'วันที่ลงทะเบียน' :
                                             lang === 'ENG' ? 'Registration Date' :
                                             lang === 'KH' ? 'កាលបរិច្ឆេទចុះឈ្មោះ' : 'Registration Date'
                                           }
                                       >
                                          <DatePicker
                                             style={{ color: 'black' }}
                                             disabled
                                             defaultValue={moment(
                                                user.createdAt
                                             )}
                                             //  format={dateFormatList}
                                          />
                                          <input style={{ display: 'none' }} />
                                       </Form.Item>
                                    </Col>

                                    <Col
                                       style={{ height: '80px' }}
                                       xs={24}
                                       sm={24}
                                       md={12}
                                       lg={12}
                                    >
                                       {' '}
                                       <Form.Item
                                           label={
                                             lang === 'TH' ? 'บัตรประชาชน' :
                                             lang === 'ENG' ? 'ID Card' :
                                             lang === 'KH' ? 'អត្តសញ្ញាណបណ្ណ' : 'ID Card'
                                           }
                                          name='cardId'
                                       >
                                          <Input
                                             style={{ color: 'black' }}
                                             disabled
                                          />
                                       </Form.Item>
                                    </Col>
                                    <Col
                                       style={{ height: '80px' }}
                                       xs={24}
                                       sm={24}
                                       md={12}
                                       lg={12}
                                    >
                                       {' '}
                                       <Form.Item
                                         label={
                                          lang === 'TH' ? 'ผู้รับผลประโยชน์' :
                                          lang === 'ENG' ? 'Beneficiary' :
                                          lang === 'KH' ? 'អ្នកទទួលផលប្រយោជន៍' : 'Beneficiary'
                                        }
                                          name='beneficiary'
                                       >
                                          <Input
                                             style={{ color: 'black' }}
                                             disabled
                                          />
                                       </Form.Item>
                                    </Col>
                                 </Row>
                                 <br />
                                 <h5 style={{ fontSize: '18px' }}>
                                    {' '}
                                    <b>
          {lang === 'TH' ? 'ที่อยู่' :
           lang === 'ENG' ? 'Address' :
           lang === 'KH' ? 'អាសយដ្ឋាន' : 'Address'}
        </b>
                                 </h5>

                                 <Row>
                                    <Col
                                       style={{ height: '100px' }}
                                       xs={24}
                                       sm={24}
                                       md={12}
                                       lg={12}
                                    >
                                       {' '}
                                       <Form.Item
                                          name='address'
                                          label={<GrMap />}
                                       >
                                          <TextArea
                                             type='text'
                                             placeholder='บ้านเลขที่ ซอย หมู่บ้าน ถนน'
                                          />
                                       </Form.Item>
                                    </Col>
                                    <Col
                                       style={{ height: '80px' }}
                                       xs={24}
                                       sm={24}
                                       md={12}
                                       lg={12}
                                    >
                                       {' '}
                                       <Form.Item
                                          labelCol={{
                                             span: 4,
                                          }}
                                          wrapperCol={{
                                             span: 17,
                                          }}
                                          label='อำเภอ'
                                          name='subdistrict'
                                       >
                                          <Input />
                                       </Form.Item>
                                    </Col>
                                    <Col
                                       style={{ height: '80px' }}
                                       xs={24}
                                       sm={24}
                                       md={12}
                                       lg={12}
                                    >
                                       {' '}
                                       <Form.Item label='ตำบล' name='district'>
                                          <Input />
                                       </Form.Item>
                                    </Col>
                                    <Col
                                       style={{ height: '80px' }}
                                       xs={24}
                                       sm={24}
                                       md={12}
                                       lg={12}
                                    >
                                       {' '}
                                       <Form.Item
                                          labelCol={{
                                             span: 4,
                                          }}
                                          wrapperCol={{
                                             span: 17,
                                          }}
                                          label='จังหวัด'
                                          name='province'
                                       >
                                          <Input />
                                       </Form.Item>
                                    </Col>
                                    <Col
                                       style={{ height: '80px' }}
                                       xs={24}
                                       sm={24}
                                       md={12}
                                       lg={12}
                                    >
                                       {' '}
                                       <Form.Item
                                          label='รหัสไปรษณี'
                                          name='postalCode'
                                       >
                                          <Input />
                                       </Form.Item>
                                    </Col>
                                 </Row>

                                 <div>
                                    <br />
                                    <h5 style={{ fontSize: '18px' }}>
                                       <b> ข้อมูลการติดต่อ </b>
                                    </h5>

                                    <Row>
                                       <Col
                                          style={{ height: '80px' }}
                                          xs={24}
                                          sm={24}
                                          md={12}
                                          lg={12}
                                       >
                                          {' '}
                                          <Form.Item
                                             name='tel'
                                             label='เบอร์มือถือ'
                                          >
                                             <Input placeholder='เบอร์มือถือ' />
                                          </Form.Item>
                                       </Col>
                                    </Row>
                                 </div>

                                 <br />
                                 <h5 style={{ fontSize: '18px' }}>
                                    {' '}
                                    <b>ข้อมูลบัญชีธนาคาร</b>{' '}
                                 </h5>

                                 <Row>
                                    <Col
                                       style={{ height: '80px' }}
                                       xs={24}
                                       sm={24}
                                       md={12}
                                       lg={12}
                                    >
                                       {' '}
                                       <Form.Item name='bank' label='ธนาคาร'>
                                          <Input
                                             style={{ color: 'black' }}
                                             disabled
                                             placeholder='ธนาคาร'
                                          />
                                       </Form.Item>
                                    </Col>
                                    <Col
                                       style={{ height: '80px' }}
                                       xs={24}
                                       sm={24}
                                       md={12}
                                       lg={12}
                                    >
                                       {' '}
                                       <Form.Item
                                          labelCol={{
                                             span: 4,
                                          }}
                                          wrapperCol={{
                                             span: 17,
                                          }}
                                          name='bankAccount'
                                          label='เลขที่บัญชี'
                                       >
                                          <Input
                                             style={{ color: 'black' }}
                                             disabled
                                             placeholder='เลขที่บัญชี'
                                          />
                                       </Form.Item>
                                    </Col>
                                    <Col
                                       style={{ height: '80px' }}
                                       xs={24}
                                       sm={24}
                                       md={12}
                                       lg={12}
                                    >
                                       {' '}
                                       <Form.Item
                                          name='bankName'
                                          label='ชื่อบัญชี'
                                       >
                                          <Input
                                             style={{ color: 'black' }}
                                             disabled
                                             placeholder='ชื่อบัญชี'
                                          />
                                       </Form.Item>
                                    </Col>
                                 </Row>

                                 <div className='d-flex justify-content-center my-2'>
                                    <Button
                                       onClick={() => setModalConfirm(true)}
                                       className='login-form-button mt-5 btn-secondary'
                                       type='primary'
                                       size='large'
                                       block
                                       style={{ width: '200px' }}
                                       htmlType='submit'
                                    >
                                       บันทึก
                                    </Button>
                                 </div>
                              </Form>
                           </div>
                        ) : null}
                     </>
                  )}
                  <Modal
                     title='ยืนยันการทำรายการ'
                     visible={modalConfirm}
                     footer={false}
                     onCancel={() => {
                        setModalConfirm(false);
                     }}
                  >
                     <div className='text-center'>
                        <div className='text-center'>
                           {' '}
                           <TbAlertTriangleFilled
                              size={128}
                              color='#ffda30'
                              className='text-center'
                           />
                        </div>
                        <h5
                           style={{
                              fontSize: '30px',
                              textAlign: 'center',
                              color: 'red',
                           }}
                        >
                           <b> ยืนยันการทำรายการแก้ไขข้อมูลสมาชิก</b>{' '}
                        </h5>

                        <br />
                        <div className='d-flex'>
                           <br />
                           <br />
                           <Button
                              style={{ backgroundColor: '#ffda30' }}
                              block
                              size='large'
                              onClick={() => setModalConfirm(false)}
                           >
                              <h5>
                                 <b>ยกเลิก</b>
                              </h5>
                           </Button>{' '}
                           <Button
                              block
                              size='large'
                              type='primary'
                              htmlType='submit'
                              onClick={(event) => handlerUpdateUser(event)}
                           >
                              <h5 style={{ color: '#ffda30' }}>
                                 <b>ยืนยัน</b>
                              </h5>
                           </Button>
                        </div>
                     </div>
                  </Modal>
                  <Modal
                     title='ทำรายการสำเร็จ'
                     style={{ height: '60%' }}
                     visible={modalSuccess}
                     footer={false}
                     destroyOnClose
                     onCancel={() => {
                        setModalSuccess(false);

                        window.location.reload();
                     }}
                  >
                     <div className='text-center mb-3'>
                        <GiConfirmed size={64} color='#4cbf2d' />
                     </div>
                     <h4
                        style={{ fontSize: '30px' }}
                        className='text-center mb-4 text-danger'
                     >
                        {' '}
                        <b>ทำรายการแก้ไขข้อมูลสำเร็จ</b>{' '}
                     </h4>

                     <br />
                  </Modal>
               </>
            )}
         </DefaultLayout>
      </>
   );
};

const TextAddress = styled.div`
   margin-bottom: 15px;
   input {
      width: 100%;
      min-width: 0px;
      padding: 4px 11px;
      color: rgba(0, 0, 0, 0.85);
      font-size: 14px;
      line-height: 1.5715;
      background-color: rgb(255, 255, 255);
      border: 1px solid rgb(217, 217, 217);
      border-radius: 2px;
   }
`;

export default ProfileEditPage;
