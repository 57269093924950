import { Table } from 'antd';
import React, { useEffect, useState } from 'react';
import DefaultLayout from '../components/DefaultLayout';
import Loading from '../components/Loading';

const BinaryPointHistoryNew = () => {
   const [loadingPage, setLoadingPage] = useState(true);

   useEffect(() => {
      if (loadingPage) {
         setTimeout(() => {
            setLoadingPage(false);
         }, 1500);
      }
   }, []);

   const columns = [
      {
         title: 'วันที่',
         dataIndex: 'date',
         key: 'date',
      },
      {
         title: 'ตำแหน่ง',
         dataIndex: 'position',
         key: 'position',
      },
      {
         title: 'ซ้าย',
         dataIndex: 'left',
         key: 'left',
      },
      {
         title: 'ขวา',
         dataIndex: 'right',
         key: 'right',
      },
      {
         title: 'คำนวณ',
         dataIndex: 'step',
         key: 'step',
      },
      {
         title: 'โบนัส',
         dataIndex: 'bonus',
         key: 'bonus',
      },
      {
         title: 'เก่าซ้าย',
         dataIndex: 'leftPrevious',
         key: 'leftPrevious',
      },
      {
         title: 'เก่าขวา',
         dataIndex: 'rightPrevious',
         key: 'rightPrevious',
      },
      {
         title: 'ใหม่ข่าว',
      },
      {
         title: 'ใหม่ซ้าย',
      },
   ];

   return (
      <DefaultLayout>
         <h3>สรุปค่าคอมมิชชั่น</h3>
         {loadingPage ? (
            <>
               <Loading />{' '}
            </>
         ) : (
            <>
               <Table columns={columns} />
            </>
         )}
      </DefaultLayout>
   );
};

export default BinaryPointHistoryNew;
