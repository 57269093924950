import { Table, Tag } from 'antd';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import DefaultLayout from '../components/DefaultLayout';
import { getCenter } from '../features/center/centerSlice';

const MemberPage = () => {
   const navigate = useNavigate();
   const dispatch = useDispatch();

   const [data, setData] = useState({});
   const [modalOpen, setModalOpen] = useState(false);
   const { userInfo } = useSelector((state) => state.auth);
   const {
      center,
      isLoading,
      isSuccess,
      isError,
      message: messageShow,
   } = useSelector((state) => state.centers);

   // useEffect(() => {
   //    dispatch(getCenter(userInfo._id));
   //    setData(centers);
   // }, [dispatch]);

   const columns = [
      {
         title: 'รหัสสมาชิก',
         dataIndex: 'member',
         key: 'member',
         render: (value) => value.userId,
      },
      {
         title: 'ชื่อ',
         dataIndex: 'member',
         key: 'member',
         render: (value) => value.name,
      },

      {
         title: 'Action',
         key: 'action',
      },
   ];

   useEffect(() => {
      dispatch(getCenter(userInfo._id));
   }, []);

   return (
      <DefaultLayout>
         <Table columns={columns} dataSource={center.member} />
      </DefaultLayout>
   );
};

export default MemberPage;
