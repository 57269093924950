import { configureStore } from '@reduxjs/toolkit';
import authReducer from './features/auth/authSlice';
import centerReducer from './features/center/centerSlice';
import userReducer from './features/users/usersSlice';
import uplineReducer from './features/upline/uplineSlice';

import cartReducer from './features/cartSlice';
import orderReducer from './features/order/orderSlice';
import commissionReducer from './features/commission/commissionSlice';
import ewalletReducer from './features/ewallet/ewalletSlice';
import settingReducer from './features/setting/settingSlice';
import productReducer from './features/product/productSlice';
import depositReducer from './features/deposit/depositSlice';

import languageReducer from './features/languageSlice';



export const store = configureStore({
   reducer: {
      auth: authReducer,
      centers: centerReducer,
      user: userReducer,
      upline: uplineReducer,
      cart: cartReducer,
      order: orderReducer,
      ewallet: ewalletReducer,
      commission: commissionReducer,
      setting: settingReducer,
      product: productReducer,
      deposit: depositReducer,

      language: languageReducer,


   },
});
