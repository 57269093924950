import axios from 'axios';

let API_URL = ''
let API_URL2 
if(process.env.NODE_ENV === 'development') {
   API_URL = 'http://localhost:5000/api/upline/';
 
} else {
   API_URL = '//api.siamprai-login.com/api/upline/';

}


const CheckDownLine = async (id, token) => {
    const config = {
       headers: {
          Authorization: `Bearer ${token}`,
       },
    };
 
    const response = await axios.get(API_URL + 'CheckDownLine/' + id, config);
 
    return response.data;
 };

 

const getUpline1 = async (id, token) => {
    const config = {
       headers: {
          Authorization: `Bearer ${token}`,
       },
    };
 
    const response = await axios.get(API_URL + 'getUpline1/' + id, config);
 
    return response.data;
 };

 const getUpline2 = async (id, token) => {
    const config = {
       headers: {
          Authorization: `Bearer ${token}`,
       },
    };
 
    const response = await axios.get(API_URL + 'getUpline2/' + id, config);
 
    return response.data;
 };
 const getUpline3 = async (id, token) => {
    const config = {
       headers: {
          Authorization: `Bearer ${token}`,
       },
    };
 
    const response = await axios.get(API_URL + 'getUpline3/' + id, config);
 
    return response.data;
 };

 const getUpline4 = async (id, token) => {
   const config = {
      headers: {
         Authorization: `Bearer ${token}`,
      },
   };

   const response = await axios.get(API_URL + 'getUpline3/' + id, config);

   return response.data;
};
const getUpline5 = async (id, token) => {
   const config = {
      headers: {
         Authorization: `Bearer ${token}`,
      },
   };

   const response = await axios.get(API_URL + 'getUpline3/' + id, config);

   return response.data;
};

const getLiveScore = async (id, token) => {
   const config = {
      headers: {
         Authorization: `Bearer ${token}`,
      },
   };

   const response = await axios.get(API_URL + 'getLiveScore/' + id, config);

   return response.data;
};

const getLiveScore1 = async (id, token) => {
   const config = {
      headers: {
         Authorization: `Bearer ${token}`,
      },
   };

   const response = await axios.get(API_URL + 'getLiveScore1/' + id, config);

   return response.data;
};

const getLiveScore2 = async (id, token) => {
   const config = {
      headers: {
         Authorization: `Bearer ${token}`,
      },
   };

   const response = await axios.get(API_URL + 'getLiveScore2/' + id, config);

   return response.data;
};

const getLiveScore4 = async (id, token) => {
   const config = {
      headers: {
         Authorization: `Bearer ${token}`,
      },
   };

   const response = await axios.get(API_URL + 'getLiveScore4/' + id, config);

   return response.data;
};

const getLiveScore5 = async (id, token) => {
   const config = {
      headers: {
         Authorization: `Bearer ${token}`,
      },
   };

   const response = await axios.get(API_URL + 'getLiveScore5/' + id, config);

   return response.data;
};

const getLiveScore6 = async (id, token) => {
   const config = {
      headers: {
         Authorization: `Bearer ${token}`,
      },
   };

   const response = await axios.get(API_URL + 'getLiveScore6/' + id, config);

   return response.data;
};

const getLiveScore7 = async (id, token) => {
   const config = {
      headers: {
         Authorization: `Bearer ${token}`,
      },
   };

   const response = await axios.get(API_URL + 'getLiveScore7/' + id, config);

   return response.data;
};

const getPointLR = async ( token) => {
   const config = {
      headers: {
         Authorization: `Bearer ${token}`,
      },
   };

   const response = await axios.get(API_URL + 'getPointLR', config);

   return response.data;
};


const getUserBottomL = async (id, token) => {
   const config = {
      headers: {
         Authorization: `Bearer ${token}`,
      },
   };

   const response = await axios.get(API_URL + 'getUserBottomL/' + id, config);

   return response.data;
};
const getUserBottomR = async (id, token) => {
   const config = {
      headers: {
         Authorization: `Bearer ${token}`,
      },
   };

   const response = await axios.get(API_URL + 'getUserBottomR/' + id, config);

   return response.data;
};

 const uplineService ={
   CheckDownLine,
    getUpline1,
    getUpline2,
    getUpline4,
    getUpline5,
    getUpline3,
    getLiveScore,
    getLiveScore1,
    getLiveScore2,
    getPointLR,
    getLiveScore4,
    getLiveScore5,
    getLiveScore6,
    getLiveScore7,
    getUserBottomL,
    getUserBottomR
 }

 export default uplineService