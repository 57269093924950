import React from 'react';
import FadeIn from 'react-fade-in';
import Lottie from 'react-lottie';
import * as bankTransaction from '../assets/bankTransaction.json';

const defaultOptions = {
   loop: true,
   autoplay: true,
   animationData: bankTransaction.default,
   rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
   },
};

const BankTransaction = () => {
   return (
      <div>
         <FadeIn>
            <Lottie options={defaultOptions} width={200} />
         </FadeIn>
      </div>
   );
};

export default BankTransaction;
