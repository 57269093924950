import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Button, Checkbox, Form, Image, Input, Modal, message } from 'antd';
import { AiOutlineUser, AiOutlineLock } from 'react-icons/ai';
import { Container } from 'react-bootstrap';
import Logo from '../assets/img/LOGO.png';
import { useNavigate } from 'react-router-dom';
import { login, logout, reset } from '../features/auth/authSlice';
import { toast } from 'react-toastify';
import moment from 'moment'
import Cookies from 'js-cookie';
import CloseSystem from '../assets/img/close-system.jpg'
import Close from '../assets/img/close-login.jpg'


const LoginPage = () => {
   const navigate = useNavigate();
   const dispatch = useDispatch();

   const [systemModal, setSystemModal] = useState(false)
   const [anableLogin,setAnableLogin] = useState(true)

   const toastId = React.useRef(null);
   const token = Cookies.get('token');
   const {
      userInfo,
      isLoading,
      isError,
      isSuccess,
      message: msgShow,
   } = useSelector((state) => state.auth);

   useEffect(() => {
      if (isError) {
         message.error(msgShow);
      }

      if (userInfo && token) {
       
            navigate('/');
          
         
      } else{
         navigate('/login')
      }

      dispatch(reset());
   }, [userInfo, isError, isSuccess, message, navigate, dispatch]);


   const onFinish = (values) => {

     
    //  const currentTime = moment();
      // const lastLoginTime = moment({ hour: 18, minute: 30 });
      // const morningTime = moment({ hour: 18, minute: 41 });

      // const currentTime = moment();
      // const lastLoginTime = moment({ hour: 23, minute: 30 });
      // const morningTime = moment({ hour: 7, minute: 0 });

     // dispatch(login(values))


      if(values.userId === '7633472'){
         dispatch(login(values))
      } else  {

       //  setSystemModal(true)
      }
      
      if(values.userId === '1234569'){
         dispatch(login(values))
      } else  {

      //   setSystemModal(true)
      }
  
    

   //    if (
   //       (currentTime.isAfter(lastLoginTime) || currentTime.isBefore(morningTime))
   //   ) {
     
   //       setSystemModal(true)
   //   } else {
   //       dispatch(login(values));
   //   }

   const currentTimeInThailand = moment().tz('Asia/Bangkok')

  // console.log('Current time in Thailand (with offset):', currentTimeInThailand.format('YYYY-MM-DD HH:mm Z'));
  // const currentTimeInThailand = moment().tz('Asia/Bangkok');

   // Define the login restriction time window in Thailand time zone
   const loginStartTime = moment({ hour: 23, minute: 45 }).tz('Asia/Bangkok');
   const loginEndTime = moment({ hour: 7, minute: 0 }).tz('Asia/Bangkok');
   
   if (currentTimeInThailand.isAfter(loginStartTime) || currentTimeInThailand.isBefore(loginEndTime)) {
       setSystemModal(true);
   } else {
       dispatch(login(values));
   }

}

   return (
   <>
      <div className='Login d-flex justify-content-center'>
         <div className='auth-block'>
            <div>
               <div className='text-center'>
                  <span className='db d-block my-2 mb-4'>
                     <Image src={Logo} width={150} preview={false} />
                  </span>
               </div>
               <Form
                  name='normal_login'
                  className='login-form'
                  initialValues={{
                     remember: true,
                  }}
                  onFinish={onFinish}>
                  <Form.Item
                  className='mb-3'
                     name='userId'
                     rules={[
                        {
                           required: true,
                           message: 'กรุณากรอกรหัสสมาชิก!',
                        },
                     ]}>
                     <Input
                     style={{
                        fontSize: '24px'
                     }}
                        prefix={
                           <AiOutlineUser className='site-form-item-icon' />
                        }
                        placeholder='รหัสสมาชิก'
                     />
                  </Form.Item>
                  <Form.Item
                     name='password'
                     rules={[
                        {
                           required: true,
                           message: 'กรุณากรอกรหัสผ่าน!',
                        },
                     ]}>
                     <Input.Password
                       style={{
                        fontSize: '24px'
                     }}
                        prefix={
                           <AiOutlineLock className='site-form-item-icon' />
                        }
                        type='password'
                        placeholder='รหัสผ่าน'
                     />
                  </Form.Item>

                  <Form.Item>
                     <Button
                     style={{position: 'relative', zIndex: '9999'}}
                        type='primary'
                        htmlType='submit'
                        block
                        size='large'
                        className='login-form-button mt-2 btn-secondary'>
                        เข้าใช้งานระบบ
                     </Button>
                  </Form.Item>
               </Form>
               <div
               style={{position: 'absolute', bottom: '0', left: '0', right: '0'}}
               >
                  <h5
                  className='my-2 text-center' 
                  style={{fontSize: '10px'}}
                  ><b style={{color: 'gray'}}>Copyright © by B.A.T INTER GROUP COMPANY LIMITED</b> </h5>
               </div>
            </div>
         </div>
      </div>
      <Modal
      closable={false}
  
      visible={systemModal}
      footer={false}
      onCancel={() => {
         setSystemModal(false);
      }}
      >
         <div
         style={{height: '50vh'}}
         >
            <Image
            src={Close}
            width='100%'
            preview={false}
            />
         </div>
         
      </Modal>
   </>


      // <div>
      //    <Image 
      //    src={CloseSystem}
      //    width='100vw'
      //    height='100vh'
      //    preview={false}
      //    />
      // </div>
   );
};

export default LoginPage;
