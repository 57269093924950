import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import DefaultLayout from '../components/DefaultLayout';
import { getByUsers } from '../features/commission/commissionSlice';
import Loading from '../components/Loading';
import { Button, Statistic, Table } from 'antd';
import { Container } from 'react-bootstrap';
import { render } from 'react-dom';
import commaNumber from 'comma-number';

const MatchngDetailsNew = () => {
   const location = useLocation();
   const navigate = useNavigate();
   const dispatch = useDispatch();
   const { state } = location;
   const [loadingPage, setLoadingPage] = useState(true);

   const { date, id } = useParams();

   let dataToB = {
      depth: id - 1,
      date: date,
   };

   const {
      commissions,
      reccommendCommsion,
      isError,
      isSuccess,
      message,
      isLoading,
   } = useSelector((state) => state.commission);

   console.log('com: ', commissions);

   // let users = [];

   // console.log(users);

   useEffect(() => {
      if (loadingPage) {
         setTimeout(() => {
            setLoadingPage(false);
         }, 1500);
      }

      dispatch(getByUsers(dataToB));
   }, [dispatch]);

   // if (commissions) {
   //    users = commissions.flat();
   //    console.log(commissions);
   // }

   const columns = [
      {
         title: 'ลำดับ',
         dataIndex: 'depth',
         key: 'depth',
         render: (text, record, index) => <span>{index + 1}</span>,
         width: '2%',
      },
      {
         title: 'รหัสสมาชิก',
         dataIndex: 'user',
         key: 'user',
         
         width: '2%',
         render: (user) => <span><b> {user.userId}</b> </span>,
      },
      {
         title: 'ชื่อ',
         dataIndex: 'user',
         width: '2%',
         key: 'user',
         render: (user) => <span> <b>{user.name}</b> </span>,
      },
      {
         title: 'ไบนารี่',
         dataIndex: 'amount',
         width: '2%',
         key: 'amount',
         render: (value) => <span><b>{commaNumber(value)}</b></span>
      },
      {
         title: '%',
         width: '2%',
         render: (text) => <span><b>5% </b></span>,
      },
      {
         title: 'โบนัส',
         width: '2%',
         dataIndex: 'amount',
         key: 'amount',
         render: (value) => <span><b>{commaNumber(value * 0.05)}</b></span>,
      },
   ];

   return (
      <DefaultLayout>
         {loadingPage ? (
            <>
               <Loading />{' '}
            </>
         ) : (
            <>
               {commissions.length >= 1 && (
                  <Container>
                     <div className='my-5'>
                        {' '}
                        <h3>
                           <b>สรุปโบนัสแมชชิ่ง ชั่นที่ : {id} </b>
                        </h3>
                        <h3>
                           <b>
                              วันที่ : {date}
                           </b>
                        </h3>
                    
                     </div>
                     <div className='d-flex justify-content-start '>
                        <Button type='danger'   style={{ backgroundColor: '#eb2632', color: 'white' }} onClick={() => navigate(-1)}>
                           {' '}
                           กลับไป{' '}
                        </Button>
                     </div>
                     <br />
                     <Table
                        summary={(commissions) => {
                           let totalAmount = 0;
                           commissions?.forEach((e) => {
                              totalAmount += e.amount;
                           });

                           return (
                              <>
                                 <Table.Summary.Row>
                                    <Table.Summary.Cell colSpan={2}>
                                       {' '}
                                       <b> รวม</b>{' '}
                                    </Table.Summary.Cell>
                                    <Table.Summary.Cell type='danger'>
                                   <span> <b>{commaNumber(totalAmount)} ฿</b></span>
                                    </Table.Summary.Cell>
                                    <Table.Summary.Cell type='danger'></Table.Summary.Cell>
                                    <Table.Summary.Cell type='danger'>
                                    <span> <b>{commaNumber((totalAmount * 5) / 100)} ฿</b></span>
                                     
                                    </Table.Summary.Cell>
                                 </Table.Summary.Row>
                              </>
                           );
                        }}
                        columns={columns}
                        dataSource={commissions}
                        rowKey='id'
                        pagination={false}
                     />
                  </Container>
               )}
            </>
         )}
      </DefaultLayout>
   );
};

export default MatchngDetailsNew;
