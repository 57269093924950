import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import { FaSearch } from 'react-icons/fa';

const SearchForBinary = ({ sendDataToParent }) => {
   const navigate = useNavigate();

   const [query, setQuery] = useState('');

   const [isError, setIsError] = useState(false);

   const [data, setData] = useState({});
   const [modalOpen, setModalOpen] = useState(false);

   const dispatch = useDispatch();

   const [userUplineSelect, setUserUplineSelect] = useState({});

   const { userInfo } = useSelector((state) => state.auth);
   const { check } = useSelector((state) => state.user);

   useEffect(() => {
      const fetchData = async () => {
              const res = await axios.get(`//api.siamprai-login.com/api/users/binarySearch/${query}/${userInfo._id}`,
            //  const res = await axios.get(`http://localhost:5000/api/users/binarySearch/${query}/${userInfo._id}`,
            {
               headers: {
                  Authorization: `Bearer ${Cookies.get('token')}`,
               },
            }
         );
         setData(res.data);
         console.log('res: ', res);
      };

      const fecthDataByUserId = async () => {
         try {
          //  const res = await axios.get( `//api.siamprai-login.com/api/users/binarySearch/${query}/${userInfo._id}`,
                     const res = await axios.get( `http://localhost:5000/api/users/binarySearch/${query}/${userInfo._id}`,
               {
                  headers: {
                     Authorization: `Bearer ${Cookies.get('token')}`,
                  },
               }
            );
            setData(res.data);
            console.log(res.data);
            console.log('res: ', res);
            setIsError(false);
         } catch (error) {
            console.log(error);
            setIsError(true);
         }
      };

      if (query.length >= 3) {
         fetchData();

      }



      if (check === true) setModalOpen(true);
   }, [query, check]);

   const recId = localStorage.getItem('recomId');
   const recName = localStorage.getItem('recomName');

   return (
      <>
         {recId === null ? (
            <>
               <div
                  className='d-flex gap-2'
                  style={{
                     position: 'absolute',
                     paddingTop: '50px',
                     right: '0',
                     paddingRight: '20px',
                     zIndex: '9999'
                  }}
               >
                  <FaSearch size={32} color='#14213D' />
                  <input
                     style={searchStyle}
                     className='search'
                     placeholder='ค้นหารหัสสมาชิก...'
                     onChange={(e) => setQuery(e.target.value.toLowerCase())}
                  />
               </div>
            </>
         ) : (
      
            <>
             
               <h5 style={resultSearch}>
                  {recId} - {recName}
               </h5>
            </>
         )}

         <div
            style={{
               position: 'absolute',
               paddingTop: '80px',
               right: '0',
               paddingRight: '40px',
               zIndex: '999',
            }}
            className='mt-2'
         >
            {data === 'ไม่พบรหัสในสายงานของท่าน' && (
               <b className='m-1 text-danger'> ไม่พบสิ่งที่ค้นหา </b>
            )}
         </div>

         <div
            style={{
               position: 'absolute',
               paddingTop: '80px',
               right: '0',
               paddingRight: '20px',
               zIndex: '999',
               backgroundColor: 'white',
               overflow: 'hidden'
            }}
            className='m-2 text-primary'
         >
            {data &&
               data.map &&
               data.map((e) => (
                  <li
                     key={e.userId}
                     style={{ cursor: 'pointer' }}
                     onClick={() => {
                        setData([]);
                        setUserUplineSelect(e);
                        sendDataToParent(e);
                     }}
                  >
                     {' '}
                     {e.name} - {e.userId}
                  </li>
               ))}
         </div>
         <div className='m-2 text-primary'
         style={{backgroundColor: 'white', overflow: 'hidden'}}
         >
            {data.userId ? (
               <p
                  style={{ cursor: 'pointer', color: '#00bbf0' }}
                  onClick={() => {
                     setUserUplineSelect(data);
                     sendDataToParent(data);
                    
                     navigate(
                        `/binary/${data.userId}/${data.name}/${data.parentId}`
                     );
                     window.location.reload();
                     localStorage.setItem('recomId', data.userId);
                     localStorage.setItem('recomName', data.name);
                 
                  }}
               >
                  {data.userId} - {data.name}
               </p>
            ) : null}
         </div>
         
      </>
   );
};

export default SearchForBinary;

const searchStyle = {
   padding: '4px 11px',
   border: '1px solid #14213D',
   borderRadius: '2px',
   width: '100%',
};

const resultSearch = {
   borderBottom: '.5px dotted black',
};
