import React, { useEffect, useRef, useState } from 'react';
import { Container } from 'react-bootstrap';
import { Col, Row, Card, Collapse } from 'antd';
import DefaultLayout from '../components/DefaultLayout';

import { FaArrowUp, FaBitcoin, FaCoins, FaUserFriends } from 'react-icons/fa';

import { Link, useNavigate } from 'react-router-dom';

import Loading from '../components/Loading';
import { useDispatch, useSelector } from 'react-redux';
import {
   getBonusWeak,
   getBonusWeak2,
   getRecLine,
   getSumBinary,
   reset,
} from '../features/commission/commissionSlice';
import styled from 'styled-components';
import { RiHandCoinFill } from 'react-icons/ri';
import { TbBinaryTree2, TbBinaryTree } from 'react-icons/tb';

import millify from 'millify';

import {
   getOldTotalBonusBinary,
   getTotalLeft,
   getTotalRight,
   getUsersByCardId,
} from '../features/users/usersSlice';

import commaNumber from 'comma-number';
import { useMediaQuery } from 'react-responsive';
import { getPointLR } from '../features/upline/uplineSlice';

import FadeIn from 'react-fade-in/lib/FadeIn';
import LoadingSpinner from '../components/LoadingSpinner';
import RecommendedLevel1Lists from '../components/RecommendedLevel1Lists';
import { AiOutlineArrowDown } from 'react-icons/ai';

const { Panel } = Collapse;

const HomePage = () => {
   const isMobile = useMediaQuery({ maxWidth: 370 });
   const isMobileL = useMediaQuery({ maxWidth: 480 });

   const lang = useSelector((state) => state.language.lang);

   const navigate = useNavigate();
   const [isLoading, setIsLoading] = useState(true);

   const dispatch = useDispatch();

   const { userInfo } = useSelector((state) => state.auth);
   const { ewallet } = useSelector((state) => state.ewallet);
   const { binary, isError, isSuccess, message } = useSelector(
      (state) => state.commission
   );

   const { users, oldBonus ,totalLeft,totalRight} = useSelector((state) => state.user);
   const { point } = useSelector((state) => state.upline);
   const {
      recommendLine,
      bonusWeak,
      bonusWeak2,
      isLoading: isUserLoading,
   } = useSelector((state) => state.commission);

// console.log('left:', totalLeft);
// console.log('right:', totalRight);


   let commission2 = null;
   let childLeft2 = null;
   let childRight2 = null;

   if (binary) {
      const { childLeft, childRight, commission } = binary;

      commission2 = commission;
      childLeft2 = childLeft;
      childRight2 = childRight;
   }

   let sum1 = 0;
   let sum = 0;
   let sumByWeak = 0;
   let sumByWeak2 = 0;

   let sumByWeak21 = 0;
   let sumByWeak22 = 0;

   if (commission2 && typeof commission2 === 'object' && commission2.reduce) {
      sum1 = commission2.reduce(
         (accumulator, currentValue) => accumulator + currentValue.amount,
         0
      );
      sum = sum1.toLocaleString();
   }

   if (bonusWeak && typeof bonusWeak === 'object' && bonusWeak.reduce) {
      sumByWeak = bonusWeak.reduce(
         (accumulator, currentValue) => accumulator + currentValue.amount,
         0
      );
      sumByWeak2 = sumByWeak.toLocaleString();
   }

   if (bonusWeak2 && typeof bonusWeak2 === 'object' && bonusWeak2.reduce) {
      sumByWeak21 = bonusWeak2.reduce(
         (accumulator, currentValue) => accumulator + currentValue.amount,
         0
      );
      sumByWeak22 = sumByWeak21.toLocaleString();
   }

   useEffect(() => {
      if (isLoading) {
         setTimeout(() => {
            setIsLoading(false);
         }, 1500);
      }

      dispatch(getBonusWeak(userInfo.userId));
      dispatch(getBonusWeak2(userInfo.userId));

      dispatch(getPointLR());

      dispatch(getTotalLeft(userInfo._id));
      dispatch(getTotalRight(userInfo._id));
      dispatch(getUsersByCardId(userInfo._id));
      dispatch(getSumBinary());
      dispatch(getRecLine());
      dispatch(getOldTotalBonusBinary(userInfo.userId));
   }, [dispatch]);

   let usersArray1 = null;
   let usersArray2 = null;
   let usersArray3 = null;
   if (recommendLine) {
      usersArray1 = recommendLine?.usersLevel1;

      usersArray2 = recommendLine?.usersLevel2;
      usersArray3 = recommendLine?.usersLevel3;
   }

   const onChangeCollapse = () => {};

   const positionText = {
      TH: {
         Assistant: 'ผู้ช่วย',
         Director: 'ผอ',
         President: 'ประธาน',
      },
      ENG: {
         Assistant: 'Assistant',
         Director: 'Director',
         President: 'President',
      },
      KH: {
         Assistant: 'ជំនួយការ',
         Director: 'នាយក',
         President: 'ប្រធាន',
      },
   };

   const positionLabel = {
      TH: 'ตำแหน่ง :',
      ENG: 'Position :',
      KH: 'មុខតំណែង :',
   };

   const scrollRef = useRef(null);

   const GoUp = () => {
      window.scrollTo({
         top: 0,
         behavior: 'smooth' // This will make the scroll smooth
      });
   };

   return (
      <DefaultLayout>
         {isLoading ? (
            <>
               {' '}
               <Loading />{' '}
            </>
         ) : (
            <>
               {' '}
               <Container
                  style={{
                     maxWidth: isMobileL ? '100vw' : '85vw',
                     paddingRight: isMobileL ? '0' : '',
                     paddingLeft: isMobileL ? '0' : '',
                  }}
               >
                  <div className='form-block2'>
                     <div className=''>
                        <HeaderText>
                           {' '}
                           <b>
                              {lang === 'TH' && (
                                 <>
                                    ยินดีต้อนรับ {userInfo.username} -{' '}
                                    <span>{userInfo.role}</span>
                                 </>
                              )}
                              {lang === 'ENG' && (
                                 <>
                                    Welcome {userInfo.username} -{' '}
                                    <span>{userInfo.role}</span>
                                 </>
                              )}
                              {lang === 'KH' && (
                                 <>
                                    សូមស្វាគមន៍ {userInfo.username} -{' '}
                                    <span>{userInfo.role}</span>
                                 </>
                              )}
                           </b>
                        </HeaderText>
                        {userInfo.expired < 10 && (
                           <HeaderText>
                              {lang === 'TH' && (
                                 <>
                                    <b className='mr-2 text-danger'>
                                       รหัสของท่านเหลือ {userInfo.expired} วัน
                                       <span> </span>
                                    </b>
                                    <b>
                                       กรุณาต่ออายุรหัสเพื่อรักษาคะแนนของท่าน
                                    </b>
                                 </>
                              )}
                              {lang === 'ENG' && (
                                 <>
                                    <b className='mr-2 text-danger'>
                                       Your code has {userInfo.expired} days
                                       left
                                       <span> </span>
                                    </b>
                                    <b>
                                       Please renew your code to maintain your
                                       points
                                    </b>
                                 </>
                              )}
                              {lang === 'KH' && (
                                 <>
                                    <b className='mr-2 text-danger'>
                                       កូដរបស់អ្នកនៅសល់ {userInfo.expired} ថ្ងៃ
                                       <span> </span>
                                    </b>
                                    <b>
                                       សូមធ្វើការតម្រៀបកូដរបស់អ្នកដើម្បីរក្សាពិន្ទុ
                                    </b>
                                 </>
                              )}
                           </HeaderText>
                        )}
                        <div className='mb-3'>
                           <StyledHeading>
                              {userInfo.position ? (
                                 <>
                                    {positionLabel[lang]}{' '}
                                    <span>
                                       {positionText[lang][userInfo.position]}
                                    </span>
                                 </>
                              ) : null}
                           </StyledHeading>
                        </div>
                     </div>
                     <div className=''>
                        <FadeIn>
                           <Row justify='space-evenly' gutter={[1, 9]}>
                              <Col
                                 style={{ paddingRight: '0', paddingLeft: '0' }}
                                 sm={12}
                                 xs={12}
                                 md={12}
                                 lg={6}
                              >
                                 <Card
                                    className=''
                                    style={{
                                       width: isMobile
                                          ? '90%'
                                          : isMobileL
                                          ? '90%'
                                          : '90%',
                                       height: '150px',
                                       borderRadius: '10px',
                                       background: '#14213D',
                                       marginLeft: isMobile
                                          ? '8px'
                                          : isMobileL
                                          ? '12px'
                                          : '',
                                    }}
                                 >
                                    <Link
                                       className='point-cursor'
                                       to='/bonusHistory'
                                    >
                                       <div className=''>
                                          <div className='d-flex flex-column gap-1'>
                                             <div className='d-flex flex-row gap-1'>
                                                <FaBitcoin
                                                   color='#ffca00'
                                                   size={40}
                                                />
                                                <div className='text-start'>
                                                   <HeaderBox>
                                                      {lang === 'TH' && (
                                                         <b>โบนัส</b>
                                                      )}
                                                      {lang === 'ENG' && (
                                                         <b>Bonus</b>
                                                      )}
                                                      {lang === 'KH' && (
                                                         <b>ប្រាក់រង្វាន់</b>
                                                      )}
                                                   </HeaderBox>
                                                   <SubHeader
                                                      style={{
                                                         color: '#fff66b',
                                                      }}
                                                   >
                                                      {lang === 'TH' && (
                                                         <b>รายสัปดาห์</b>
                                                      )}
                                                      {lang === 'ENG' && (
                                                         <b>Weekly</b>
                                                      )}
                                                      {lang === 'KH' && (
                                                         <b>ប្រចាំសប្តាហ៍</b>
                                                      )}
                                                   </SubHeader>
                                                </div>
                                             </div>

                                             <TextInSideBox
                                                style={{ color: '#fff66b' }}
                                             >
                                                <b>{commaNumber(sumByWeak2)}</b>{' '}
                                                <span>฿</span>
                                             </TextInSideBox>
                                             <p
                                                style={{
                                                   fontSize: '10px',
                                                   color: 'white',
                                                }}
                                             >
                                                {lang === 'TH' &&
                                                   ' จ่ายทุกวันพุธของสัปดาห์'}
                                                {lang === 'ENG' &&
                                                   '  Paid every Wednesday of the week'}
                                                {lang === 'KH' &&
                                                   'បង់ប្រាក់រៀងរាល់ថ្ងៃពុធនៃសប្តាហ៍'}
                                             </p>
                                          </div>
                                       </div>
                                    </Link>
                                    <br />
                                 </Card>
                              </Col>
                              <Col
                                 style={{ paddingRight: '0', paddingLeft: '0' }}
                                 sm={12}
                                 xs={12}
                                 md={12}
                                 lg={6}
                              >
                                 <Card
                                    className=''
                                    style={{
                                       width: isMobile
                                          ? '90%'
                                          : isMobileL
                                          ? '90%'
                                          : '90%',
                                       height: '150px',
                                       borderRadius: '10px',
                                       background: '#eae6d2',
                                       marginRight: isMobile
                                          ? '8px'
                                          : isMobileL
                                          ? '12px'
                                          : '',
                                    }}
                                 >
                                    <div className=''>
                                       <div className='d-flex flex-column gap-4'>
                                          <div className='d-flex flex-row gap-1'>
                                             <TbBinaryTree2
                                                color='#14213D'
                                                size={40}
                                             />
                                             <div className='text-start'>
                                                <HeaderBoxSecond>
                                                   {lang === 'TH' && (
                                                      <b>คะแนนไบนารี่</b>
                                                   )}
                                                   {lang === 'ENG' && (
                                                      <b>Binary Points</b>
                                                   )}
                                                   {lang === 'KH' && (
                                                      <b>ពិន្ទុបីណារី</b>
                                                   )}
                                                </HeaderBoxSecond>
                                                <SubHeader
                                                   style={{ color: '#14213D' }}
                                                >
                                                   <b>PV</b>
                                                </SubHeader>
                                             </div>
                                          </div>

                                          <TextInSideBox
                                             className='text-start'
                                             style={{ color: '#14213D' }}
                                          >
                                             <b>
                                                {commaNumber(point?.pointLeft)}
                                             </b>{' '}
                                             -{' '}
                                             <b>
                                                {commaNumber(point?.pointRight)}
                                             </b>
                                          </TextInSideBox>
                                       </div>
                                    </div>
                                    <br />
                                 </Card>
                              </Col>

                              <Col
                                 style={{ paddingRight: '0', paddingLeft: '0' }}
                                 sm={12}
                                 xs={12}
                                 md={12}
                                 lg={6}
                              >
                                 <Card
                                    className=''
                                    style={{
                                       width: isMobile
                                          ? '90%'
                                          : isMobileL
                                          ? '90%'
                                          : '90%',
                                       height: '150px',
                                       borderRadius: '10px',
                                       background: '#eae6d2',
                                       marginLeft: isMobile
                                          ? '8px'
                                          : isMobileL
                                          ? '12px'
                                          : '',
                                    }}
                                 >
                                    <Link to={`/bonusesUsers`}>
                                       <div className=''>
                                          <div className='d-flex flex-column gap-4'>
                                             <div className='d-flex flex-row gap-1'>
                                                <FaCoins
                                                   color='#14213D'
                                                   size={40}
                                                />
                                                <div className='text-start'>
                                                   <HeaderBoxSecond
                                                      style={{
                                                         color: '#14213D',
                                                      }}
                                                   >
                                                      {lang === 'TH' && (
                                                         <b>โบนัสสะสม</b>
                                                      )}
                                                      {lang === 'ENG' && (
                                                         <b>
                                                            Accumulated Bonus
                                                         </b>
                                                      )}
                                                      {lang === 'KH' && (
                                                         <b>
                                                            ប្រាក់រង្វាន់សរុប
                                                         </b>
                                                      )}
                                                   </HeaderBoxSecond>
                                                   <SubHeader
                                                      style={{
                                                         color: '#14213D',
                                                      }}
                                                   >
                                                      {lang === 'TH' && (
                                                         <b>รายสัปดาห์</b>
                                                      )}
                                                      {lang === 'ENG' && (
                                                         <b>Weekly</b>
                                                      )}
                                                      {lang === 'KH' && (
                                                         <b>ប្រចាំសប្តាហ៍</b>
                                                      )}
                                                   </SubHeader>
                                                </div>
                                             </div>

                                             <TextInSideBox
                                                style={{ color: '#14213D' }}
                                             >
                                                <b>
                                                   {commaNumber(sumByWeak22)}
                                                </b>{' '}
                                                <span>฿</span>
                                             </TextInSideBox>
                                          </div>
                                       </div>
                                    </Link>
                                    <br />
                                 </Card>
                              </Col>

                              <Col
                                 style={{ paddingRight: '0', paddingLeft: '0' }}
                                 sm={12}
                                 xs={12}
                                 md={12}
                                 lg={6}
                              >
                                 <Card
                                    className=''
                                    style={{
                                       width: isMobile
                                          ? '90%'
                                          : isMobileL
                                          ? '90%'
                                          : '90%',
                                       height: '150px',
                                       borderRadius: '10px',
                                       background: '#eae6d2',
                                       marginRight: isMobile
                                          ? '8px'
                                          : isMobileL
                                          ? '12px'
                                          : '',
                                    }}
                                 >
                                    <Link
                                       to={`/binary/${userInfo?.userId}/${userInfo?.username}`}
                                    >
                                       <div className=''>
                                          <div className='d-flex flex-column gap-4'>
                                             <div className='d-flex flex-row gap-1'>
                                                <TbBinaryTree
                                                   color='#14213D'
                                                   size={40}
                                                />
                                                <div className='text-start'>
                                                   <HeaderBoxSecond
                                                      style={{
                                                         color: '#14213D',
                                                      }}
                                                   >
                                                      {lang === 'TH' && (
                                                         <b>รหัสใต้สายงาน</b>
                                                      )}
                                                      {lang === 'ENG' && (
                                                         <b>
                                                            Downlines
                                                         </b>
                                                      )}
                                                      {lang === 'KH' && (
                                                         <b>កូដក្រោមផ្នែក</b>
                                                      )}
                                                   </HeaderBoxSecond>
                                                   <SubHeader2
                                                      style={{
                                                         color: '#14213D',
                                                      }}
                                                   >
                                                      {lang === 'TH' && (
                                                         <b>
                                                            จำนวนสมาชิกใต้สายงาน
                                                         </b>
                                                      )}
                                                      {lang === 'ENG' && (
                                                         <b>
                                                            Number of members downlines
                                                         </b>
                                                      )}
                                                      {lang === 'KH' && (
                                                         <b>
                                                            ចំនួនសមាជិកក្រោមផ្នែក
                                                         </b>
                                                      )}
                                                   </SubHeader2>
                                                </div>
                                             </div>

                                             {totalLeft?.totalLeft &&
                                             totalRight?.totalRight ? (
                                                <>
                                                   <TextInSideBox
                                                      style={{
                                                         color: '#14213D',
                                                      }}
                                                   >
                                                      <b>
                                                         {commaNumber(
                                                            totalLeft?.totalLeft
                                                         )}
                                                      </b>{' '}
                                                      -{' '}
                                                      <b>
                                                         {commaNumber(
                                                            totalRight?.totalRight
                                                         )}
                                                      </b>
                                                   </TextInSideBox>
                                                </>
                                             ) : (
                                                <>
                                                   <b>Loading...</b>
                                                </>
                                             )}
                                          </div>
                                       </div>
                                    </Link>
                                    <br />
                                 </Card>
                              </Col>
                              <Col
                                 style={{ paddingRight: '0', paddingLeft: '0' }}
                                 sm={12}
                                 xs={12}
                                 md={12}
                                 lg={6}
                              >
                                 <Card
                                    className=''
                                    style={{
                                       width: isMobile
                                          ? '90%'
                                          : isMobileL
                                          ? '90%'
                                          : '90%',
                                       height: '150px',
                                       borderRadius: '10px',
                                       background: '#eae6d2',
                                       marginLeft: isMobile
                                          ? '8px'
                                          : isMobileL
                                          ? '12px'
                                          : '',
                                    }}
                                 >
                                    <div className=''>
                                       <div className='d-flex flex-column gap-1'>
                                          <div className='d-flex flex-row gap-1'>
                                             <FaUserFriends
                                                color='#14213D'
                                                size={40}
                                             />
                                             <div>
                                                <HeaderBoxSecond
                                                   style={{ color: '#14213D' }}
                                                >
                                                   {lang === 'TH' && (
                                                      <b>โบนัสสะสม</b>
                                                   )}
                                                   {lang === 'ENG' && (
                                                      <b>Accumulated Bonus</b>
                                                   )}
                                                   {lang === 'KH' && (
                                                      <b>ប្រាក់រង្វាន់សរុប</b>
                                                   )}
                                                </HeaderBoxSecond>
                                                <SubHeader
                                                   style={{
                                                      color: '#14213D',
                                                      opacity: '0',
                                                   }}
                                                >
                                                   <b>
                                                      {lang === 'TH' && (
                                                         <b>
                                                            จำนวน {users.length}{' '}
                                                            รหัส
                                                         </b>
                                                      )}
                                                      {lang === 'ENG' && (
                                                         <b>
                                                            Number of{' '}
                                                            {users.length} ID
                                                         </b>
                                                      )}
                                                      {lang === 'KH' && (
                                                         <b>
                                                            ចំនួន {users.length}{' '}
                                                            កូដ
                                                         </b>
                                                      )}
                                                   </b>
                                                </SubHeader>
                                             </div>
                                          </div>

                                          <TextInSideBox
                                             style={{ color: '#14213D' }}
                                          >
                                             {binary?.totalEarnings?.toString()
                                                .length >= 6 ? (
                                                <b>
                                                   {millify(
                                                      binary?.totalEarnings,
                                                      {
                                                         precision: 3,
                                                         lowercase: true,
                                                      }
                                                   )}
                                                </b>
                                             ) : (
                                                <>
                                                   <b>
                                                      {commaNumber(
                                                         binary?.totalEarnings
                                                      )}
                                                   </b>

                                                   <span>฿</span>
                                                </>
                                             )}
                                          </TextInSideBox>
                                       </div>
                                    </div>
                                    <br />
                                 </Card>
                              </Col>
                              <Col
                                 style={{ paddingRight: '0', paddingLeft: '0' }}
                                 sm={12}
                                 xs={12}
                                 md={12}
                                 lg={6}
                              >
                                 <Link to='usersDetail'>
                                    <Card
                                       className=''
                                       style={{
                                          width: isMobile
                                             ? '90%'
                                             : isMobileL
                                             ? '90%'
                                             : '90%',
                                          height: '150px',
                                          borderRadius: '10px',
                                          background: '#ffcc00',
                                          marginRight: isMobile
                                             ? '8px'
                                             : isMobileL
                                             ? '12px'
                                             : '',
                                       }}
                                    >
                                       <div className=''>
                                          <div className='d-flex flex-column gap-1'>
                                             <div className='d-flex flex-row gap-1'>
                                                <RiHandCoinFill
                                                   color='#14213D'
                                                   size={40}
                                                />
                                                <div className='text-start'>
                                                   <HeaderBoxSecond
                                                      style={{
                                                         color: '#14213D',
                                                      }}
                                                   >
                                                      {lang === 'TH' && (
                                                         <b>โบนัสสะสมทุกรหัส</b>
                                                      )}
                                                      {lang === 'ENG' && (
                                                         <b>
                                                            Accumulated Bonus
                                                            for all ID
                                                         </b>
                                                      )}
                                                      {lang === 'KH' && (
                                                         <b>
                                                            ប្រាក់រង្វាន់សរុបសម្រាប់កូដទាំងអស់
                                                         </b>
                                                      )}
                                                   </HeaderBoxSecond>
                                                   <SubHeader
                                                      style={{
                                                         color: '#14213D',
                                                      }}
                                                   >
                                                      {lang === 'TH' && (
                                                         <b>
                                                            จำนวน {users.length}{' '}
                                                            รหัส
                                                         </b>
                                                      )}
                                                      {lang === 'ENG' && (
                                                         <b>
                                                            Number of{' '}
                                                            {users.length} ID
                                                         </b>
                                                      )}
                                                      {lang === 'KH' && (
                                                         <b>
                                                            ចំនួន {users.length}{' '}
                                                            កូដ
                                                         </b>
                                                      )}
                                                   </SubHeader>
                                                </div>
                                             </div>

                                             <TextInSideBox
                                                style={{ color: '#14213D' }}
                                             >
                                                {oldBonus?.totalBonus?.toString()
                                                   .length >= 6 ? (
                                                   <b>
                                                      {millify(
                                                         oldBonus?.totalBonus,
                                                         {
                                                            precision: 3,
                                                            lowercase: true,
                                                         }
                                                      )}
                                                   </b>
                                                ) : (
                                                   <>
                                                      <b>
                                                         {commaNumber(
                                                            oldBonus?.totalBonus
                                                         )}
                                                      </b>

                                                      <span>฿</span>
                                                   </>
                                                )}
                                             </TextInSideBox>
                                          </div>
                                       </div>
                                       <br />
                                    </Card>
                                 </Link>
                              </Col>
                           </Row>
                        </FadeIn>
                        <div className='my-5'>
                           {/* <h5>
                              {lang === 'TH' && <b>รายละเอียดการแนะนำ</b>}
                              {lang === 'ENG' && <b>Referral Details</b>}
                              {lang === 'KH' && (
                                 <b>ព័ត៌មានលម្អិតអំពីការណែនាំ</b>
                              )}
                           </h5> */}

                           {/* <FadeIn delay={300}>
                              <div>
                                 {isUserLoading ? (
                                    <LoadingSpinner />
                                 ) : (
                                    <Collapse onChange={onChangeCollapse}>
                                       <Panel
                                          style={{
                                             fontSize: '16px',
                                             background: '#14213D',
                                             color: '#ffca00',
                                          }}
                                          header={
                                             lang === 'TH'
                                               ? `รหัสแนะนำชั้นที่ 1 จำนวน ${usersArray1?.length} รหัส`
                                               : lang === 'ENG'
                                               ? `Level 1 Referral Code Count ${usersArray1?.length}`
                                               : lang === 'KH'
                                               ? `ចំនួនកូដណែនាំកម្រិត ១ ${usersArray1?.length}`
                                               : `Level 1 Referral Code Count ${usersArray1?.length}`
                                           }
                                          key='1'
                                       >
                                          <Row gutter={[16, 16]}>
                                             {' '}
                                             {usersArray1?.map((e) => {
                                                return (
                                                   <>
                                                      {' '}
                                                      <div>
                                                         <Col
                                                            xs={24}
                                                            sm={24}
                                                            md={8}
                                                            lg={6}
                                                            xl={8}
                                                         >
                                                            <Card
                                                               size='small'
                                                               style={{
                                                                  width: '250px',
                                                                  height:
                                                                     '50px',
                                                               }}
                                                            >
                                                               <div className='d-flex gap-1'>
                                                                  <span
                                                                     style={{
                                                                        fontSize:
                                                                           '14px',
                                                                     }}
                                                                  >
                                                                     {e.name}
                                                                  </span>
                                                                  <span className='m-auto'>
                                                                     {' '}
                                                                     -
                                                                  </span>
                                                                  <span
                                                                     style={{
                                                                        fontSize:
                                                                           '14px',
                                                                     }}
                                                                     className=''
                                                                  >
                                                                     {e.userId}
                                                                  </span>
                                                               </div>
                                                            </Card>
                                                         </Col>
                                                      </div>
                                                   </>
                                                );
                                             })}
                                          </Row>
                                       </Panel>
                                       <Panel
                                          style={{ fontSize: '16px' }}
                                          header={
                                             lang === 'TH'
                                               ? `รหัสแนะนำชั้นที่ 2 จำนวน ${usersArray2?.length} รหัส`
                                               : lang === 'ENG'
                                               ? `Level 2 Referral Code Count ${usersArray2?.length}`
                                               : lang === 'KH'
                                               ? `ចំនួនកូដណែនាំកម្រិត ១ ${usersArray2?.length}`
                                               : `Level 1 Referral Code Count ${usersArray2?.length}`
                                           }
                                          key='2'
                                       >
                                          <Row gutter={[16, 16]}>
                                             {' '}
                                             {usersArray2?.map((e) => {
                                                return (
                                                   <>
                                                      {' '}
                                                      <div>
                                                         <Col
                                                            xs={24}
                                                            sm={24}
                                                            md={8}
                                                            lg={6}
                                                         >
                                                            <Card
                                                               size='small'
                                                               style={{
                                                                  width: '250px',
                                                                  height:
                                                                     '50px',
                                                               }}
                                                            >
                                                               <div className='d-flex gap-1'>
                                                                  <span
                                                                     style={{
                                                                        fontSize:
                                                                           '14px',
                                                                     }}
                                                                  >
                                                                     {e.name}
                                                                  </span>
                                                                  <span className='m-auto'>
                                                                     {' '}
                                                                     -
                                                                  </span>
                                                                  <span
                                                                     style={{
                                                                        fontSize:
                                                                           '14px',
                                                                     }}
                                                                     className=''
                                                                  >
                                                                     {e.userId}
                                                                  </span>
                                                               </div>
                                                            </Card>
                                                         </Col>
                                                      </div>
                                                   </>
                                                );
                                             })}
                                          </Row>
                                       </Panel>
                                       <Panel
                                          style={{
                                             fontSize: '16px',
                                             backgroundColor: 'grey',
                                          }}
                                          className='bg-white black-color'
                                          header={
                                             isUserLoading
                                                ? 'Loading...'
                                                : `รหัสแนะนำชั้นที่ 3 จำนวน ${usersArray3?.length} รหัส`
                                          }
                                          key='3'
                                       >
                                          <Row gutter={[16, 16]}>
                                             {' '}
                                             {usersArray3?.map((e) => {
                                                return (
                                                   <>
                                                      {' '}
                                                      <div>
                                                         <Col
                                                            xs={24}
                                                            sm={24}
                                                            md={8}
                                                            lg={6}
                                                         >
                                                            <Card
                                                               size='small'
                                                               style={{
                                                                  width: '250px',
                                                                  height:
                                                                     '50px',
                                                               }}
                                                            >
                                                               <div className='d-flex gap-1'>
                                                                  <span
                                                                     style={{
                                                                        fontSize:
                                                                           '14px',
                                                                     }}
                                                                  >
                                                                     {e.name}
                                                                  </span>
                                                                  <span className='m-auto'>
                                                                     {' '}
                                                                     -
                                                                  </span>
                                                                  <span
                                                                     style={{
                                                                        fontSize:
                                                                           '14px',
                                                                     }}
                                                                     className=''
                                                                  >
                                                                     {e.userId}
                                                                  </span>
                                                               </div>
                                                            </Card>
                                                         </Col>
                                                      </div>
                                                   </>
                                                );
                                             })}
                                          </Row>
                                       </Panel>
                                    </Collapse>
                                 )}
                              </div>
                           </FadeIn> */}
                           <RecommendedLevel1Lists />
                        </div>
                     </div>
                  </div>
                 <div
                   style={{
                     position: 'fixed',
                     bottom: '20px', // Adjust this value as needed
                     right: '20px',  // Adjust this value as needed
                     zIndex: 1000,   // Ensure it appears above other elements
                   }}
                 className='d-flex justify-content-end'>
                 <FaArrowUp
                                    size={32}
                                    className=' mr-6 '
                                    style={{
                                       cursor: 'pointer',
                                       marginRight: '10px',
                                    }}
                                    onClick={GoUp}
                                 />
                 </div>
               </Container>{' '}
            </>
         )}
      </DefaultLayout>
   );
};

const sizes = {
   mobileS: '320px',
   mobileM: '375px',
   mobileL: '558px',
   tablet: '768px',
   laptop: '1024px',
   laptopL: '1440px',
   laptopXL: '1740px',
   desktop: '2560px',
};

const device = {
   mobileS: `(max-width: ${sizes.mobileS})`,
   mobileM: `(max-width: ${sizes.mobileM})`,
   mobileL: `(max-width: ${sizes.mobileL})`,
   tablet: `(max-width: ${sizes.tablet})`,
   laptop: `(max-width: ${sizes.laptop})`,
   laptopL: `(max-width: ${sizes.laptopL})`,
   laptopXL: `(max-width: ${sizes.laptopXL})`,
   desktop: `(max-width: ${sizes.desktop})`,
};


const StyledHeading = styled.b`
   margin-left: 15px;

   @media (min-width: 576px) {
      margin-left: 0; /* Reset margin for larger screens if needed */
   }
`;

const HeaderText = styled.h5`
   margin-top: 20px;
   margin-bottom: 20px;

   @media ${device.mobileL} {
      text-align: center;
      padding: 25px;
      padding-bottom: 25px;
   }
`;

const HeaderBox = styled.h4`
   color: #fff66b;
   font-size: 14px;
   margin: 0;

   @media ${device.mobileM} {
      font-size: 14px;
   }
`;

const SubHeader = styled.p`
   @media ${device.mobileM} {
      font-size: 12px;
   }
`;

const SubHeader2 = styled.p`
   @media ${device.mobileL} {
      font-size: 12px;
   }

   @media ${device.mobileM} {
      font-size: 10px;
   }
`;

const HeaderBoxSecond = styled.h4`
   color: #14213d;
   font-size: 18px;
   margin: 0;
   @media ${device.mobileL} {
      font-size: 14px;
   }
   @media ${device.mobileM} {
      font-size: 14px;
   }
`;

const TextInSideBox = styled.h5`
   @media ${device.mobileL} {
      font-size: 20px;
      text-align: center;
   }

   @media ${device.mobileM} {
      font-size: 16px;
      text-align: center;
      margin-left: 0 !important;
   }
`;

export default HomePage;
