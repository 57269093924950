import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import Cookies from 'js-cookie';
import uplineService from './uplineService';

const initialState = {
   userBottom: {},
   upline1: {},
   upline2: {},
   upline3: {},
   upline4: {},
   upline5: {},
   liveScore: {},
   liveScore1: {},
   liveScore2: {},
   liveScore4: {},
   liveScore5: {},
   liveScore6: {},
   liveScore7: {},
   point: {},
   UpTopIdUplineUser: {},
   getUpTopSuccess: false,
   check: null,
   isError: false,
   isSuccess: false,
   registerSuccess: false,
   isLiveScoreLoading: false,
   isLiveScoreLoading1: false,
   isLiveScoreLoading2: false,
   isLoading: false,
   isLoadingUpline1: false,
   isLoadingUpline2: false,
   isLoadingUpline3: false,
   isLoadingUpline4: false,
   isLoadingUpline5: false,
   message: '',
};

// GET UPLINE
export const CheckDownLine = createAsyncThunk(
   'upline/CheckDownLine',
   async (id, thunkAPI) => {
      try {
         const token = Cookies.get('token');

         return await uplineService.CheckDownLine(id, token);
      } catch (error) {
         const message =
            (error.response &&
               error.response.data &&
               error.response.data.message) ||
            error.message ||
            error.toString();
         return thunkAPI.rejectWithValue(message);
      }
   }
);

export const getUpline1 = createAsyncThunk(
   'upline/getUpline1',
   async (id, thunkAPI) => {
      try {
         const token = Cookies.get('token');

         return await uplineService.getUpline1(id, token);
      } catch (error) {
         const message =
            (error.response &&
               error.response.data &&
               error.response.data.message) ||
            error.message ||
            error.toString();
         return thunkAPI.rejectWithValue(message);
      }
   }
);
export const getUpline2 = createAsyncThunk(
   'upline/getUpline2',
   async (id, thunkAPI) => {
      try {
         const token = Cookies.get('token');

         return await uplineService.getUpline2(id, token);
      } catch (error) {
         const message =
            (error.response &&
               error.response.data &&
               error.response.data.message) ||
            error.message ||
            error.toString();
         return thunkAPI.rejectWithValue(message);
      }
   }
);

export const getUpline3 = createAsyncThunk(
   'upline/getUpline3',
   async (id, thunkAPI) => {
      try {
         const token = Cookies.get('token');

         return await uplineService.getUpline3(id, token);
      } catch (error) {
         const message =
            (error.response &&
               error.response.data &&
               error.response.data.message) ||
            error.message ||
            error.toString();
         return thunkAPI.rejectWithValue(message);
      }
   }
);

export const getUpline4 = createAsyncThunk(
   'upline/getUpline4',
   async (id, thunkAPI) => {
      try {
         const token = Cookies.get('token');

         return await uplineService.getUpline4(id, token);
      } catch (error) {
         const message =
            (error.response &&
               error.response.data &&
               error.response.data.message) ||
            error.message ||
            error.toString();
         return thunkAPI.rejectWithValue(message);
      }
   }
);
export const getUpline5 = createAsyncThunk(
   'upline/getUpline5',
   async (id, thunkAPI) => {
      try {
         const token = Cookies.get('token');

         return await uplineService.getUpline5(id, token);
      } catch (error) {
         const message =
            (error.response &&
               error.response.data &&
               error.response.data.message) ||
            error.message ||
            error.toString();
         return thunkAPI.rejectWithValue(message);
      }
   }
);

export const getLiveScore = createAsyncThunk(
   'upline/getLiveScore',
   async (id, thunkAPI) => {
      try {
         const token = Cookies.get('token');

         return await uplineService.getLiveScore(id, token);
      } catch (error) {
         const message =
            (error.response &&
               error.response.data &&
               error.response.data.message) ||
            error.message ||
            error.toString();
         return thunkAPI.rejectWithValue(message);
      }
   }
);
export const getLiveScore1 = createAsyncThunk(
   'upline/getLiveScore1',
   async (id, thunkAPI) => {
      try {
         const token = Cookies.get('token');

         return await uplineService.getLiveScore1(id, token);
      } catch (error) {
         const message =
            (error.response &&
               error.response.data &&
               error.response.data.message) ||
            error.message ||
            error.toString();
         return thunkAPI.rejectWithValue(message);
      }
   }
);

export const getLiveScore2 = createAsyncThunk(
   'upline/getLiveScore2',
   async (id, thunkAPI) => {
      try {
         const token = Cookies.get('token');

         return await uplineService.getLiveScore2(id, token);
      } catch (error) {
         const message =
            (error.response &&
               error.response.data &&
               error.response.data.message) ||
            error.message ||
            error.toString();
         return thunkAPI.rejectWithValue(message);
      }
   }
);

export const getLiveScore4 = createAsyncThunk(
   'upline/getLiveScore4',
   async (id, thunkAPI) => {
      try {
         const token = Cookies.get('token');

         return await uplineService.getLiveScore4(id, token);
      } catch (error) {
         const message =
            (error.response &&
               error.response.data &&
               error.response.data.message) ||
            error.message ||
            error.toString();
         return thunkAPI.rejectWithValue(message);
      }
   }
);

export const getLiveScore5 = createAsyncThunk(
   'upline/getLiveScore5',
   async (id, thunkAPI) => {
      try {
         const token = Cookies.get('token');

         return await uplineService.getLiveScore5(id, token);
      } catch (error) {
         const message =
            (error.response &&
               error.response.data &&
               error.response.data.message) ||
            error.message ||
            error.toString();
         return thunkAPI.rejectWithValue(message);
      }
   }
);

export const getLiveScore6 = createAsyncThunk(
   'upline/getLiveScore6',
   async (id, thunkAPI) => {
      try {
         const token = Cookies.get('token');

         return await uplineService.getLiveScore6(id, token);
      } catch (error) {
         const message =
            (error.response &&
               error.response.data &&
               error.response.data.message) ||
            error.message ||
            error.toString();
         return thunkAPI.rejectWithValue(message);
      }
   }
);

export const getLiveScore7 = createAsyncThunk(
   'upline/getLiveScore7',
   async (id, thunkAPI) => {
      try {
         const token = Cookies.get('token');

         return await uplineService.getLiveScore7(id, token);
      } catch (error) {
         const message =
            (error.response &&
               error.response.data &&
               error.response.data.message) ||
            error.message ||
            error.toString();
         return thunkAPI.rejectWithValue(message);
      }
   }
);

export const getPointLR = createAsyncThunk(
   'upline/getPointLR',
   async (thunkAPI) => {
      try {
         const token = Cookies.get('token');

         return await uplineService.getPointLR(token);
      } catch (error) {
         const message =
            (error.response &&
               error.response.data &&
               error.response.data.message) ||
            error.message ||
            error.toString();
         return thunkAPI.rejectWithValue(message);
      }
   }
);

export const getUserBottomL = createAsyncThunk(
   'upline/getUserBottomL',
   async (id, thunkAPI) => {
      try {
         const token = Cookies.get('token');

         return await uplineService.getUserBottomL(id, token);
      } catch (error) {
         const message =
            (error.response &&
               error.response.data &&
               error.response.data.message) ||
            error.message ||
            error.toString();
         return thunkAPI.rejectWithValue(message);
      }
   }
);
export const getUserBottomR = createAsyncThunk(
   'upline/getUserBottomR',
   async (id, thunkAPI) => {
      try {
         const token = Cookies.get('token');

         return await uplineService.getUserBottomR(id, token);
      } catch (error) {
         const message =
            (error.response &&
               error.response.data &&
               error.response.data.message) ||
            error.message ||
            error.toString();
         return thunkAPI.rejectWithValue(message);
      }
   }
);

const uplineSlice = createSlice({
   name: 'upline',
   initialState,
   reducers: {
      reset: (state) => initialState,
   },
   extraReducers: (builder) => {
      builder
         .addCase(CheckDownLine.pending, (state) => {
            state.isLoadingUpline1 = true;
         })
         .addCase(CheckDownLine.fulfilled, (state, action) => {
            state.isLoadingUpline1 = false;
            state.isSuccess = true;
        
         })
         .addCase(CheckDownLine.rejected, (state, action) => {
            state.isLoadingUpline1 = false;
            state.isError = true;
            state.message = action.payload;
         })
         .addCase(getUpline1.pending, (state) => {
            state.isLoadingUpline1 = true;
         })
         .addCase(getUpline1.fulfilled, (state, action) => {
            state.isLoadingUpline1 = false;
            state.isSuccess = true;
            state.upline1 = action.payload;
         })
         .addCase(getUpline1.rejected, (state, action) => {
            state.isLoadingUpline1 = false;
            state.isError = true;
            state.message = action.payload;
         })
         .addCase(getUpline2.pending, (state) => {
            state.isLoadingUpline2 = true;
         })
         .addCase(getUpline2.fulfilled, (state, action) => {
            state.isLoadingUpline2 = false;
            state.isSuccess = true;
            state.upline2 = action.payload;
         })
         .addCase(getUpline2.rejected, (state, action) => {
            state.isLoadingUpline2 = false;
            state.isError = true;
            state.message = action.payload;
         })
         .addCase(getUpline3.pending, (state) => {
            state.isLoadingUpline3 = true;
         })
         .addCase(getUpline3.fulfilled, (state, action) => {
            state.isLoadingUpline3 = false;
            state.isSuccess = true;
            state.upline3 = action.payload;
         })
         .addCase(getUpline3.rejected, (state, action) => {
            state.isLoadingUpline3 = false;
            state.isError = true;
            state.message = action.payload;
         })
         .addCase(getUpline4.pending, (state) => {
            state.isLoadingUpline4 = true;
         })
         .addCase(getUpline4.fulfilled, (state, action) => {
            state.isLoadingUpline4 = false;
            state.isSuccess = true;
            state.upline4 = action.payload;
         })
         .addCase(getUpline4.rejected, (state, action) => {
            state.isLoadingUpline4 = false;
            state.isError = true;
            state.message = action.payload;
         })
         .addCase(getUpline5.pending, (state) => {
            state.isLoadingUpline5 = true;
         })
         .addCase(getUpline5.fulfilled, (state, action) => {
            state.isLoadingUpline5 = false;
            state.isSuccess = true;
            state.upline5 = action.payload;
         })
         .addCase(getUpline5.rejected, (state, action) => {
            state.isLoadingUpline5 = false;
            state.isError = true;
            state.message = action.payload;
         })
         .addCase(getLiveScore.pending, (state) => {
            state.isLiveScoreLoading = true;
         })
         .addCase(getLiveScore.fulfilled, (state, action) => {
            state.isLiveScoreLoading = false;
            state.isSuccess = true;
            state.liveScore = action.payload;
         })
         .addCase(getLiveScore.rejected, (state, action) => {
            state.isLiveScoreLoading = false;
            state.isError = true;
            state.message = action.payload;
         })
         .addCase(getLiveScore1.pending, (state) => {
            state.isLiveScoreLoading1 = true;
         })
         .addCase(getLiveScore1.fulfilled, (state, action) => {
            state.isLiveScoreLoading1 = false;
            state.isSuccess = true;
            state.liveScore1 = action.payload;
         })
         .addCase(getLiveScore1.rejected, (state, action) => {
            state.isLiveScoreLoading1 = false;
            state.isError = true;
            state.message = action.payload;
         })
         .addCase(getLiveScore2.pending, (state) => {
            state.isLiveScoreLoading2 = true;
         })
         .addCase(getLiveScore2.fulfilled, (state, action) => {
            state.isLiveScoreLoading2 = false;
            state.isSuccess = true;
            state.liveScore2 = action.payload;
         })
         .addCase(getLiveScore2.rejected, (state, action) => {
            state.isLiveScoreLoading2 = false;
            state.isError = true;
            state.message = action.payload;
         })
         .addCase(getLiveScore4.pending, (state) => {
            state.isLoading = true;
         })
         .addCase(getLiveScore4.fulfilled, (state, action) => {
            state.isLoading = false;
            state.isSuccess = true;
            state.liveScore4 = action.payload;
         })
         .addCase(getLiveScore4.rejected, (state, action) => {
            state.isLoading = false;
            state.isError = true;
            state.message = action.payload;
         })
         .addCase(getLiveScore5.pending, (state) => {
            state.isLoading = true;
         })
         .addCase(getLiveScore5.fulfilled, (state, action) => {
            state.isLoading = false;
            state.isSuccess = true;
            state.liveScore5 = action.payload;
         })
         .addCase(getLiveScore5.rejected, (state, action) => {
            state.isLoading = false;
            state.isError = true;
            state.message = action.payload;
         })
         .addCase(getLiveScore6.pending, (state) => {
            state.isLoading = true;
         })
         .addCase(getLiveScore6.fulfilled, (state, action) => {
            state.isLoading = false;
            state.isSuccess = true;
            state.liveScore6 = action.payload;
         })
         .addCase(getLiveScore6.rejected, (state, action) => {
            state.isLoading = false;
            state.isError = true;
            state.message = action.payload;
         })
         .addCase(getLiveScore7.pending, (state) => {
            state.isLoading = true;
         })
         .addCase(getLiveScore7.fulfilled, (state, action) => {
            state.isLoading = false;
            state.isSuccess = true;
            state.liveScore7 = action.payload;
         })
         .addCase(getLiveScore7.rejected, (state, action) => {
            state.isLoading = false;
            state.isError = true;
            state.message = action.payload;
         })
         .addCase(getPointLR.pending, (state) => {
            state.isLoading = true;
         })
         .addCase(getPointLR.fulfilled, (state, action) => {
            state.isLoading = false;
            state.isSuccess = true;
            state.point = action.payload;
         })
         .addCase(getPointLR.rejected, (state, action) => {
            state.isLoading = false;
            state.isError = true;
            state.message = action.payload;
         })
         .addCase(getUserBottomL.pending, (state) => {
            state.isLoading = true;
         })
         .addCase(getUserBottomL.fulfilled, (state, action) => {
            state.isLoading = false;
            state.isSuccess = true;
            state.userBottom = action.payload;
         })
         .addCase(getUserBottomL.rejected, (state, action) => {
            state.isLoading = false;
            state.isError = true;
            state.message = action.payload;
         })
         .addCase(getUserBottomR.pending, (state) => {
            state.isLoading = true;
         })
         .addCase(getUserBottomR.fulfilled, (state, action) => {
            state.isLoading = false;
            state.isSuccess = true;
            state.userBottom = action.payload;
         })
         .addCase(getUserBottomR.rejected, (state, action) => {
            state.isLoading = false;
            state.isError = true;
            state.message = action.payload;
         });
   },
});

export const { reset } = uplineSlice.actions;
export default uplineSlice.reducer;
