import React, { useEffect, useState } from 'react';
import Loading from '../components/Loading';
import { useDispatch, useSelector } from 'react-redux';
import { Col, Row, Card, Collapse, Input, Popover } from 'antd';
import {
   getBonusWeak,
   getBonusWeak2,
   getRecLine,
   getSumBinary,
   reset,
} from '../features/commission/commissionSlice';
import styled from 'styled-components';
import LoadingSpinner from './LoadingSpinner';
import FadeIn from 'react-fade-in/lib/FadeIn';

const { Panel } = Collapse;

const StyledHeading = styled.h5`
   margin-left: 15px;

   @media (min-width: 576px) {
      margin-left: 0; /* Reset margin for larger screens if needed */
   }
`;

const RecommendedLevel1Lists = () => {
   const lang = useSelector((state) => state.language.lang);

   const dispatch = useDispatch();

   const {
      recommendLine,

      isLoading: isUserLoading,
   } = useSelector((state) => state.commission);

   let usersArray1 = null;
   let usersArray2 = null;
   let usersArray3 = null;
   if (recommendLine) {
      usersArray1 = recommendLine?.usersLevel1;

      usersArray2 = recommendLine?.usersLevel2;
      usersArray3 = recommendLine?.usersLevel3;
   }

   useEffect(() => {
      dispatch(getRecLine());
   }, [dispatch]);

   const [searchTerm, setSearchTerm] = useState('');
   const [searchTerm2, setSearchTerm2] = useState('');
   const [searchTerm3, setSearchTerm3] = useState('');

   const filteredUsers = usersArray1?.filter((user) =>
      user.userId.toLowerCase().includes(searchTerm.toLowerCase())
   );
   const filteredUsers2 = usersArray2?.filter((user) =>
      user.userId.toLowerCase().includes(searchTerm2.toLowerCase())
   );

   const filteredUsers3 = usersArray3?.filter((user) =>
      user.userId.toLowerCase().includes(searchTerm3.toLowerCase())
   );


   const renderContent = (user) => (
      <div style={{fontSize: '16px'}}>
         <p>
            <b>ชื่อ : {user?.name}</b>
         </p>
         <p>
            <b>รหัสสมาชิก : {user?.userId}</b>
         </p>
         <p>
            <b>แพ็คเกจ : {user?.role}</b>
         </p>
         <p>
            <b>อัพไลน์ : {user?.parentId?.userId} - {user?.parentId?.name} </b>
         </p>
         <p>
            <b>ผู้แนะนำ : {user?.recommendedBy?.userId} - {user?.recommendedBy?.name} </b>
         </p>
         <p>
            <b>
               {user.position === 'Assistant'
                  ? 'ตำแหน่ง : ผู้ช่วย'
                  : user.position === 'director'
                  ? 'ตำแหน่ง : ผอ'
                  : user.position === 'President'
                  ? 'ตำแหน่ง : ประธาน'
                  : user.position}
            </b>
         </p>
      </div>
   );

   return (
      <div className='my-5'>
         <StyledHeading>
            {lang === 'TH' && <b>รายละเอียดการแนะนำ</b>}
            {lang === 'ENG' && <b>Referral Details</b>}
            {lang === 'KH' && <b>ព័ត៌មានលម្អិតអំពីការណែនាំ</b>}
         </StyledHeading>

         <div
            className='my-3'
            style={{ width: '20%', marginRight: 'auto', marginLeft: 'auto' }}
         ></div>

         <FadeIn delay={300}>
            <div>
               {isUserLoading ? (
                  <LoadingSpinner />
               ) : (
                  <Collapse>
                     <Panel
                        style={{
                           fontSize: '16px',
                           background: '#14213D',
                           color: '#ffca00',
                        }}
                        header={
                           lang === 'TH'
                              ? `รหัสแนะนำชั้นที่ 1 จำนวน ${usersArray1?.length} รหัส`
                              : lang === 'ENG'
                              ? `Level 1 Referral Code Count ${usersArray1?.length}`
                              : lang === 'KH'
                              ? `ចំនួនកូដណែនាំកម្រិត ១ ${usersArray1?.length}`
                              : `Level 1 Referral Code Count ${usersArray1?.length}`
                        }
                        key='1'
                     >
                        <div
                           style={{
                              width: '30%',
                              marginLeft: 'auto',
                              marginRight: 'auto',
                           }}
                        >
                           <Input
                              placeholder='ค้นหาด้วยรหัส'
                              value={searchTerm}
                              onChange={(e) => setSearchTerm(e.target.value)}
                              style={{ marginBottom: '16px' }}
                           />
                        </div>
                        <Row gutter={[16, 16]} justify='center'>
                           {' '}
                           {filteredUsers?.length >= 1 ? (
                              <>
                                 {filteredUsers?.map((e) => {
                                    return (
                                       <>
                                          {' '}
                                          <div>
                                             <Col
                                                xs={24}
                                                sm={24}
                                                md={8}
                                                lg={6}
                                                xl={8}
                                             >
                                                <Popover
                                                   trigger='click'
                                                   content={renderContent(e)}
                                                >
                                                   <Card
                                                      size='small'
                                                      style={{
                                                         width: '250px',
                                                         height: '50px',
                                                         background:
                                                            e.position ===
                                                            'Assistant'
                                                               ? 'gray'
                                                               : 'white',
                                                      }}
                                                   >
                                                      <div className='d-flex gap-1'>
                                                         <span
                                                            style={{
                                                               fontSize: '14px',
                                                            }}
                                                         >
                                                            {e.name}
                                                         </span>
                                                         <span className='m-auto'>
                                                            {' '}
                                                            -
                                                         </span>
                                                         <span
                                                            style={{
                                                               fontSize: '14px',
                                                            }}
                                                            className=''
                                                         >
                                                            {e.userId}
                                                         </span>
                                                      </div>
                                                   </Card>
                                                </Popover>
                                             </Col>
                                          </div>
                                       </>
                                    );
                                 })}
                              </>
                           ) : (
                              <>
                                 {usersArray1?.map((e) => {
                                    return (
                                       <>
                                          {' '}
                                          <div>
                                             <Col
                                                xs={24}
                                                sm={24}
                                                md={8}
                                                lg={6}
                                                xl={8}
                                             >
                                                <Card
                                                   size='small'
                                                   style={{
                                                      width: '250px',
                                                      height: '50px',
                                                      background:
                                                         e.position ===
                                                         'Assistant'
                                                            ? 'gray'
                                                            : 'white',
                                                   }}
                                                >
                                                   <div className='d-flex gap-1'>
                                                      <span
                                                         style={{
                                                            fontSize: '14px',
                                                         }}
                                                      >
                                                         {e.name}
                                                      </span>
                                                      <span className='m-auto'>
                                                         {' '}
                                                         -
                                                      </span>
                                                      <span
                                                         style={{
                                                            fontSize: '14px',
                                                         }}
                                                         className=''
                                                      >
                                                         {e.userId}
                                                      </span>
                                                   </div>
                                                </Card>
                                             </Col>
                                          </div>
                                       </>
                                    );
                                 })}
                              </>
                           )}
                        </Row>
                     </Panel>
                     <Panel
                        style={{ fontSize: '16px' }}
                        header={
                           lang === 'TH'
                              ? `รหัสแนะนำชั้นที่ 2 จำนวน ${usersArray2?.length} รหัส`
                              : lang === 'ENG'
                              ? `Level 2 Referral Code Count ${usersArray2?.length}`
                              : lang === 'KH'
                              ? `ចំនួនកូដណែនាំកម្រិត ១ ${usersArray2?.length}`
                              : `Level 1 Referral Code Count ${usersArray2?.length}`
                        }
                        key='2'
                     >
                           <div
                           style={{
                              width: '30%',
                              marginLeft: 'auto',
                              marginRight: 'auto',
                           }}
                        >
                           <Input
                              placeholder='ค้นหาด้วยรหัส'
                              value={searchTerm2}
                              onChange={(e) => setSearchTerm2(e.target.value)}
                              style={{ marginBottom: '16px' }}
                           />
                        </div>
                        <Row gutter={[16, 16]} justify='center'>
                        {' '}
                           {filteredUsers2?.length >= 1 ? (
                              <>
                                 {filteredUsers2?.map((e) => {
                                    return (
                                       <>
                                          {' '}
                                          <div>
                                             <Col
                                                xs={24}
                                                sm={24}
                                                md={8}
                                                lg={6}
                                                xl={8}
                                             >
                                                <Popover
                                                   trigger='click'
                                                   content={renderContent(e)}
                                                >
                                                   <Card
                                                      size='small'
                                                      style={{
                                                         width: '250px',
                                                         height: '50px',
                                                         background:
                                                            e.position ===
                                                            'Assistant'
                                                               ? 'gray'
                                                               : 'white',
                                                      }}
                                                   >
                                                      <div className='d-flex gap-1'>
                                                         <span
                                                            style={{
                                                               fontSize: '14px',
                                                            }}
                                                         >
                                                            {e.name}
                                                         </span>
                                                         <span className='m-auto'>
                                                            {' '}
                                                            -
                                                         </span>
                                                         <span
                                                            style={{
                                                               fontSize: '14px',
                                                            }}
                                                            className=''
                                                         >
                                                            {e.userId}
                                                         </span>
                                                      </div>
                                                   </Card>
                                                </Popover>
                                             </Col>
                                          </div>
                                       </>
                                    );
                                 })}
                              </>
                           ) : (
                              <>
                                 {usersArray2?.map((e) => {
                                    return (
                                       <>
                                          {' '}
                                          <div>
                                             <Col
                                                xs={24}
                                                sm={24}
                                                md={8}
                                                lg={6}
                                                xl={8}
                                             >
                                                <Card
                                                   size='small'
                                                   style={{
                                                      width: '250px',
                                                      height: '50px',
                                                      background:
                                                         e.position ===
                                                         'Assistant'
                                                            ? 'gray'
                                                            : 'white',
                                                   }}
                                                >
                                                   <div className='d-flex gap-1'>
                                                      <span
                                                         style={{
                                                            fontSize: '14px',
                                                         }}
                                                      >
                                                         {e.name}
                                                      </span>
                                                      <span className='m-auto'>
                                                         {' '}
                                                         -
                                                      </span>
                                                      <span
                                                         style={{
                                                            fontSize: '14px',
                                                         }}
                                                         className=''
                                                      >
                                                         {e.userId}
                                                      </span>
                                                   </div>
                                                </Card>
                                             </Col>
                                          </div>
                                       </>
                                    );
                                 })}
                              </>
                           )}
                        </Row>
                     </Panel>
                     <Panel
                        style={{
                           fontSize: '16px',
                           backgroundColor: 'grey',
                        }}
                        className='bg-white black-color'
                        header={
                           isUserLoading
                              ? 'Loading...'
                              : `รหัสแนะนำชั้นที่ 3 จำนวน ${usersArray3?.length} รหัส`
                        }
                        key='3'
                     >
                         <div
                           style={{
                              width: '30%',
                              marginLeft: 'auto',
                              marginRight: 'auto',
                           }}
                        >
                           <Input
                              placeholder='ค้นหาด้วยรหัส'
                              value={searchTerm3}
                              onChange={(e) => setSearchTerm3(e.target.value)}
                              style={{ marginBottom: '16px' }}
                           />
                        </div>
                        <Row gutter={[16, 16]} justify='center'>
                        {filteredUsers3?.length >= 1 ? (
                              <>
                                 {filteredUsers3?.map((e) => {
                                    return (
                                       <>
                                          {' '}
                                          <div>
                                             <Col
                                                xs={24}
                                                sm={24}
                                                md={8}
                                                lg={6}
                                                xl={8}
                                             >
                                                <Popover
                                                   trigger='click'
                                                   content={renderContent(e)}
                                                >
                                                   <Card
                                                      size='small'
                                                      style={{
                                                         width: '250px',
                                                         height: '50px',
                                                         background:
                                                            e.position ===
                                                            'Assistant'
                                                               ? 'gray'
                                                               : 'white',
                                                      }}
                                                   >
                                                      <div className='d-flex gap-1'>
                                                         <span
                                                            style={{
                                                               fontSize: '14px',
                                                            }}
                                                         >
                                                            {e.name}
                                                         </span>
                                                         <span className='m-auto'>
                                                            {' '}
                                                            -
                                                         </span>
                                                         <span
                                                            style={{
                                                               fontSize: '14px',
                                                            }}
                                                            className=''
                                                         >
                                                            {e.userId}
                                                         </span>
                                                      </div>
                                                   </Card>
                                                </Popover>
                                             </Col>
                                          </div>
                                       </>
                                    );
                                 })}
                              </>
                           ) : (
                              <>
                                 {usersArray3?.map((e) => {
                                    return (
                                       <>
                                          {' '}
                                          <div>
                                             <Col
                                                xs={24}
                                                sm={24}
                                                md={8}
                                                lg={6}
                                                xl={8}
                                             >
                                                <Card
                                                   size='small'
                                                   style={{
                                                      width: '250px',
                                                      height: '50px',
                                                      background:
                                                         e.position ===
                                                         'Assistant'
                                                            ? 'gray'
                                                            : 'white',
                                                   }}
                                                >
                                                   <div className='d-flex gap-1'>
                                                      <span
                                                         style={{
                                                            fontSize: '14px',
                                                         }}
                                                      >
                                                         {e.name}
                                                      </span>
                                                      <span className='m-auto'>
                                                         {' '}
                                                         -
                                                      </span>
                                                      <span
                                                         style={{
                                                            fontSize: '14px',
                                                         }}
                                                         className=''
                                                      >
                                                         {e.userId}
                                                      </span>
                                                   </div>
                                                </Card>
                                             </Col>
                                          </div>
                                       </>
                                    );
                                 })}
                              </>
                           )}
                        </Row>
                     </Panel>
                  </Collapse>
               )}
            </div>
         </FadeIn>
      </div>
   );
};

export default RecommendedLevel1Lists;
