import axios from 'axios';


 // const API_URL = 'http://localhost:5000/api/commission/';

let API_URL = ''
if(process.env.NODE_ENV === 'development') {
   API_URL = 'http://localhost:5000/api/commission/';
} else {
   API_URL =  '//api.siamprai-login.com/api/commission/';

}


const getCommission = async (token) => {
   const config = {
      headers: {
         Authorization: `Bearer ${token}`,
      },
   };

   const response = await axios.get(API_URL, config);
   return response.data;
};

const getSumBinary = async (token) => {
   const config = {
      headers: {
         Authorization: `Bearer ${token}`,
      },
   };

   const response = await axios.get(API_URL + '/getsumbinary', config);
   return response.data;
};

const getComMobile = async (token) => {
   const config = {
      headers: {
         Authorization: `Bearer ${token}`,
      },
   };

   const response = await axios.get(API_URL + '/getComMobile', config);
   return response.data;
};

const getRecLine = async (token) => {
   const config = {
      headers: {
         Authorization: `Bearer ${token}`,
      },
   };

   const response = await axios.get(API_URL + '/getrecline', config);
   return response.data;
};

const getRecommendCommission = async (token) => {
   const config = {
      headers: {
         Authorization: `Bearer ${token}`,
      },
   };

   const response = await axios.get(API_URL + '/recpoint', config);
   return response.data;
};

//GET BY USERS
const getByUsers = async (users, token) => {
   const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params: users,
    };
  
    const response = await axios.get(API_URL + 'getusers', config);
   return response.data;
};


const getBonusWeak = async (id, token) => {
   const config = {
      headers: {
         Authorization: `Bearer ${token}`,
      },
   };

   const response = await axios.get(API_URL + `getBonusWeak/${id}`, config);
   return response.data;
};

const getBonusWeak2 = async (id, token) => {
   const config = {
      headers: {
         Authorization: `Bearer ${token}`,
      },
   };

   const response = await axios.get(API_URL + `getBonusWeak2/${id}`, config);
   return response.data;
};

const getUsersBonusDetail = async (queryString, token) => {
   const config = {
      headers: {
         Authorization: `Bearer ${token}`,
      },
   };


   const response = await axios.get(`${API_URL}getUsersBonusDetail?week=${queryString}`, config);
   return response.data;
};

const getBonusMobileDetail = async (date, token) => {
   const config = {
      headers: {
         Authorization: `Bearer ${token}`,
      },
   };

   const response = await axios.get(API_URL + `getBonusMobileDetail/${date}`, config);
   return response.data;
};
const getBonusBinaryDetail = async (date, token) => {
   const config = {
      headers: {
         Authorization: `Bearer ${token}`,
      },
   };

   const response = await axios.get(API_URL + `getBonusBinaryDetail/${date}`, config);
   return response.data;
};

const commissionService = {
   getRecommendCommission,
   getCommission,
   getByUsers,
   getRecLine,
   getSumBinary,
   getComMobile,
   getBonusWeak,
   getBonusWeak2,
   getUsersBonusDetail,
   getBonusMobileDetail,
   getBonusBinaryDetail
};

export default commissionService;
