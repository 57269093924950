import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import ewalletService from './ewalletService';
import Cookies from 'js-cookie'

import axios from 'axios'
import jwt_decode from 'jwt-decode';

let API_URL = ''
if(process.env.NODE_ENV === 'development') {
   API_URL = 'http://localhost:5000/api/users/';
} else {
   API_URL =  '//api.siamprai-login.com/api/users/';

}

// Configure Axios
axios.defaults.baseURL = API_URL;
axios.defaults.withCredentials = true; // Ensure credentials are sent with requests

// Interceptor to refresh token on 401 error
// axios.interceptors.response.use(
//    (response) => response,
//    async (error) => {
//      const originalRequest = error.config;
//      if (error.response.status === 401 && !originalRequest._retry) {
//        originalRequest._retry = true;
//        try {
//          const { data } = await axios.post('/token');
//          localStorage.setItem('token', data.token);
//          axios.defaults.headers.common['Authorization'] = `Bearer ${data.token}`;
//          originalRequest.headers['Authorization'] = `Bearer ${data.token}`;
//          return axios(originalRequest);
//        } catch (err) {
//          return Promise.reject(err);
//        }
//      }
//      return Promise.reject(error);
//    }
//  );

const initialState = {
   ewallets: [],
   ewallet: {},
   ewallet2: {},
   isError: false,
   isSuccess: false,
   isTransferSuccess: false,
   isLoading: false,
   message: '',
};

// UPDATE EWALLET
export const updateEwallet = createAsyncThunk(
   'ewallet/updateEwallet',
   async (data, thunkAPI) => {
      try {
         const token = Cookies.get('token');
         return await ewalletService.updateEwallet(data, token);
      } catch (error) {
         const message =
            (error.response &&
               error.response.data &&
               error.response.data.message) ||
            error.message ||
            error.toString();
         return thunkAPI.rejectWithValue(message);
      }
   }
);

// ADMIN UPDATE EWALLET
export const AdminUpdateEwallet = createAsyncThunk(
   'ewallet/adminUpdateEwallet',
   async (data, thunkAPI) => {
      try {
         const token = Cookies.get('token');
         return await ewalletService.AdminUpdateEwallet(data, token);
      } catch (error) {
         const message =
            (error.response &&
               error.response.data &&
               error.response.data.message) ||
            error.message ||
            error.toString();
         return thunkAPI.rejectWithValue(message);
      }
   }
);

export const getByMonth = createAsyncThunk(
   'ewallet/getByMonth',
   async (_, thunkAPI) => {
      try {
         const token = Cookies.get('token');
         return await ewalletService.getByMonth(token);
      } catch (error) {
         const message =
            (error.response &&
               error.response.data &&
               error.response.data.message) ||
            error.message ||
            error.toString();
         return thunkAPI.rejectWithValue(message);
      }
   }
);

export const getEwallet = createAsyncThunk(
   'ewallet/getEwallet',
   async (page, thunkAPI) => {
      try {
         const token = Cookies.get('token');
    
         return await ewalletService.getEwallet(page,token);
      } catch (error) {
         const message =
            (error.response &&
               error.response.data &&
               error.response.data.message) ||
            error.message ||
            error.toString();
         return thunkAPI.rejectWithValue(message);
      }
   }
);
export const getPV = createAsyncThunk(
   'ewallet/getPV',
   async ( thunkAPI) => {
      try {
         const token = Cookies.get('token');
    
         return await ewalletService.getPV(token);
      } catch (error) {
         const message =
            (error.response &&
               error.response.data &&
               error.response.data.message) ||
            error.message ||
            error.toString();
         return thunkAPI.rejectWithValue(message);
      }
   }
);

export const getCenterDeposit = createAsyncThunk(
   'ewallet/getCenterDeposit',
   async (_, thunkAPI) => {
      try {
         const token = Cookies.get('token');
         return await ewalletService.getCenterDeposit(token);
      } catch (error) {
         const message =
            (error.response &&
               error.response.data &&
               error.response.data.message) ||
            error.message ||
            error.toString();
         return thunkAPI.rejectWithValue(message);
      }
   }
);

export const getCenterTransfer = createAsyncThunk(
   'ewallet/getCenterTransfer',
   async (_, thunkAPI) => {
      try {
         const token = Cookies.get('token');
         return await ewalletService.getCenterTransfer(token);
      } catch (error) {
         const message =
            (error.response &&
               error.response.data &&
               error.response.data.message) ||
            error.message ||
            error.toString();
         return thunkAPI.rejectWithValue(message);
      }
   }
);

const ewalletSlice = createSlice({
   name: 'ewallet',
   initialState,
   reducers: {
      reset: (state) => initialState,
   },
   extraReducers: (builder) => {
      builder
         .addCase(updateEwallet.pending, (state) => {
            state.isLoading = true;
         })
         .addCase(updateEwallet.fulfilled, (state, action) => {
            state.isLoading = false;
            state.isTransferSuccess = true;
            state.ewallet = action.payload;
         })
         .addCase(updateEwallet.rejected, (state, action) => {
            state.isLoading = false;
            state.isError = true;
            state.message = action.payload;
         })
         .addCase(AdminUpdateEwallet.pending, (state) => {
            state.isLoading = true;
         })
         .addCase(AdminUpdateEwallet.fulfilled, (state, action) => {
            state.isLoading = false;
            state.isSuccess = true;
            state.ewallet = action.payload;
         })
         .addCase(AdminUpdateEwallet.rejected, (state, action) => {
            state.isLoading = false;
            state.isError = true;
            state.message = action.payload;
         })
         .addCase(getEwallet.pending, (state) => {
            state.isLoading = true;
         })
         .addCase(getEwallet.fulfilled, (state, action) => {
            state.isLoading = false;
            state.isSuccess = true;
            state.ewallet2 = action.payload;
         })
         .addCase(getEwallet.rejected, (state, action) => {
            state.isLoading = false;
            state.isError = true;
            state.message = action.payload;
         })
         .addCase(getPV.pending, (state) => {
            state.isLoading = true;
         })
         .addCase(getPV.fulfilled, (state, action) => {
            state.isLoading = false;
            state.isSuccess = true;
            state.ewallet = action.payload;
         })
         .addCase(getPV.rejected, (state, action) => {
            state.isLoading = false;
            state.isError = true;
            state.message = action.payload;
         })
         .addCase(getCenterTransfer.pending, (state) => {
            state.isLoading = true;
         })
         .addCase(getCenterTransfer.fulfilled, (state, action) => {
            state.isLoading = false;
            state.isSuccess = true;
            state.ewallets = action.payload;
         })
         .addCase(getCenterTransfer.rejected, (state, action) => {
            state.isLoading = false;
            state.isError = true;
            state.message = action.payload;
         })
         .addCase(getCenterDeposit.pending, (state) => {
            state.isLoading = true;
         })
         .addCase(getCenterDeposit.fulfilled, (state, action) => {
            state.isLoading = false;
            state.isSuccess = true;
            state.ewallets = action.payload;
         })
         .addCase(getCenterDeposit.rejected, (state, action) => {
            state.isLoading = false;
            state.isError = true;
            state.message = action.payload;
         })
         .addCase(getByMonth.pending, (state) => {
            state.isLoading = true;
         })
         .addCase(getByMonth.fulfilled, (state, action) => {
            state.isLoading = false;
            state.isSuccess = true;
            state.ewallets = action.payload;
         })
         .addCase(getByMonth.rejected, (state, action) => {
            state.isLoading = false;
            state.isError = true;
            state.message = action.payload;
         });
   },
});

export const { reset } = ewalletSlice.actions;
export default ewalletSlice.reducer;
