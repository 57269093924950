// axiosConfig.js
import axios from 'axios';
import { useDispatch } from 'react-redux';
import { logout } from '../features/auth/authSlice';
import Cookies from 'js-cookie';

// Determine API URL based on environment
let API_URL = '';
if (process.env.NODE_ENV === 'development') {
  API_URL = 'http://localhost:5000/api/users/';
} else {
 // API_URL = '//api.siamprai-login.com/api/users/';
}


// Configure Axios
axios.defaults.baseURL = API_URL;
axios.defaults.withCredentials = true; // Ensure credentials are sent with requests

// Interceptor to handle 401 error and logout
axios.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config;
    if (error.response.status === 401 && !originalRequest._retry) {

        
        originalRequest._retry = true;
        const { default: store } = await import('../store'); // Dynamically import store
   //     const { logout } = await import('../features/auth/authSlice'); // Dynamically import logout action
        console.log('Dispatching logout action'); // Debugging log
     //   store.dispatch(logout()); // Dispatch the logout action
      //  dispatch(logout())


      localStorage.clear();
      Cookies.remove('token');
      axios.defaults.headers.common['Authorization'] = '';
    }
    return Promise.reject(error);
  }
);

export default axios;
