import React, { useEffect, useState } from 'react';
import DefaultLayout from '../components/DefaultLayout';
import { Container } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import {
   getBonusWeak2,
   getUsersBonusDetail,
} from '../features/commission/commissionSlice';
import Loading from '../components/Loading';
import { Table, ConfigProvider } from 'antd';
import moment from 'moment';
import { v4 } from 'uuid';
import { FaArrowRight, FaArrowLeft } from 'react-icons/fa6';
import 'moment/locale/th'; // Import the Thai locale
import WeekPicker from '../components/WeekPicker';
import commaNumber from 'comma-number';
import styled from 'styled-components';


// Set the locale to Thai
moment.locale('th');

const BonusesUsers = ({ onChange }) => {
   const dispatch = useDispatch();

   const [loadingPage, setLoadingPage] = useState(true);

   const [week2, setWeek2] = useState(null);

   const { userInfo } = useSelector((state) => state.auth);

   const {bonusDetail, isLoading } = useSelector(
      (state) => state.commission
   );
   const [open, setOpen] = useState(false);
   const [date, setDate] = useState(moment());
   const [week, setWeek] = useState({
      firstDay: moment().startOf('isoWeek'),
      lastDay: moment().endOf('isoWeek'),
   });

   useEffect(() => {
      onChange && onChange(week);
   }, [week]);

   const isLeapYear = (year) => {
      return moment([year]).isLeapYear();
   };

   const convertDate = (date) => {
      return date.format('DD/MM/YYYY');
   };

   const handleClick = (e) => {
      let localDate;
      if (e.target.id.includes('prev')) {
         localDate = moment(date).date(1);
      } else if (e.target.id.includes('next')) {
         localDate = moment(date).date(date.daysInMonth());
      } else {
         localDate = moment(date).date(parseInt(e.target.id));

         console.log('localDate: ', moment(localDate).format('DD/MM/YYYY'));
      }
      setDate(localDate);
      setWeek({
         firstDay: localDate.startOf('isoWeek'),
         lastDay: localDate.endOf('isoWeek'),
      });
 
   };

   const days = {
      1: 31,
      2: isLeapYear(date.year()) ? 29 : 28,
      3: 31,
      4: 30,
      5: 31,
      6: 30,
      7: 31,
      8: 31,
      9: 30,
      10: 31,
      11: 30,
      12: 31,
   };

   const renderDays = () => {
      let month = date.month() + 1;
      let ar = [];
      for (let i = 1; i <= days[month]; i++) {
         let currentDate = moment(date).date(i);
         let cName = 'single-number ';
         if (
            currentDate.isSameOrAfter(week.firstDay) &&
            currentDate.isSameOrBefore(week.lastDay)
         ) {
            cName += 'selected-week';
         }

         ar.push(
            <div key={v4()} id={i} className={cName} onClick={handleClick}>
               {i}
            </div>
         );
      }

      const displayDate = moment(date).date(1);
      let dayInTheWeek = displayDate.isoWeekday();
      if (dayInTheWeek < 1) {
         dayInTheWeek = 7;
      }
      let prevMonth = [];
      let prevMonthDays = moment(date).month();
      if (prevMonthDays === 0) {
         prevMonthDays = 12;
      }
      for (let i = dayInTheWeek; i > 1; i--) {
         let previousMonth = moment(date).subtract(1, 'month');
         let currentDate = moment(previousMonth).date(
            days[prevMonthDays] - i + 2
         );
         //  let cName = "single-number other-month";
         //  if (currentDate.isSameOrAfter(week.firstDay) && currentDate.isSameOrBefore(week.lastDay)) {
         //    cName = "single-number selected-week";
         //  }

         let cName = 'single-number other-month';
         if (
            currentDate.isSameOrAfter(week.firstDay, 'day') &&
            currentDate.isSameOrBefore(week.lastDay, 'day')
         ) {
            cName = 'single-number selected-week';
         }

         prevMonth.push(
            <div
               onClick={handleClick}
               key={v4()}
               id={'prev-' + i}
               className={cName}
            >
               {days[prevMonthDays] - i + 2}
            </div>
         );
      }

      let nextMonth = [];
      let fullDays = 35;
      if ([...prevMonth, ...ar].length > 35) {
         fullDays = 42;
      }

      for (let i = 1; i <= fullDays - [...prevMonth, ...ar].length; i++) {
         //  let cName = 'single-number other-month';
         //  const lastDay = week.lastDay;
         //  const lastDayOfMonth = moment(date).date(date.daysInMonth());
         let cName = 'single-number other-month';
         const lastDayOfMonth = moment(date).date(date.daysInMonth());

         if (
            lastDayOfMonth.isSameOrBefore(week.lastDay, 'day') &&
            week.firstDay.month() === lastDayOfMonth.month()
         ) {
            cName = 'single-number selected-week';
         }

         //  if (
         //    lastDayOfMonth.isSameOrBefore(lastDay) &&
         //    week.firstDay.month() == lastDayOfMonth.month()
         //  ) {
         //    cName = 'single-number selected-week';
         //  }

         nextMonth.push(
            <div
               onClick={handleClick}
               key={v4()}
               id={'next-' + i}
               className={cName}
            >
               {i}
            </div>
         );
      }
      return [...prevMonth, ...ar, ...nextMonth];
   };

   const handleDate = (next) => {
      let localDate = moment(date);
      if (next) {
         localDate = localDate.add(1, 'month');
      } else {
         localDate = localDate.subtract(1, 'month');
      }
      setDate(moment(localDate));
   };

   useEffect(() => {
      dispatch(getBonusWeak2(userInfo.userId));
   }, [dispatch]);

   let data = null

   if(bonusDetail){
    data = bonusDetail
   }


   const columns = [
      {
          title: 'วันที่',
          dataIndex: 'date',
          width: '4%',
          key: 'date',
          render: (value) => (
            <b>
               {moment(value).format('DD/MM/YYYY')}
            </b>
          ),
      },
   
  
      {
         title: 'สมาชิก',
         dataIndex: 'user',
         width: '2%',
         key: 'user',
         render: (user) => {
             if (user && user.length) {
                 return user.length + " รหัส";
             } else if (user && user.userId) {
                 return (
                  <b>{user.userId}</b>
                 )
             } else {
                 return '-';
             }
         },
         // render: (user) => (
         //    <b> {user.userId} </b>
         // )
     },
     {
      title: 'จำนวน',
      dataIndex: 'amount',
      width: '2%',
      key: 'amount',
      render: (value) => (
        <b> {commaNumber(value)}</b>
      )
  },
    
  ];
  
  const groupDataByDate = (data) => {
      return data.reduce((acc, curr) => {
          const date = curr.date.split('T')[0];
          if (!acc[date]) {
              acc[date] = [];
          }
          acc[date].push(curr);
          return acc;
      }, {});
  };
  
  const prepareDataSource = (data) => {
      const groupedData = groupDataByDate(data);
      const sortedDates = Object.keys(groupedData).sort();
  
      return sortedDates.map((date, index) => {
          const children = groupedData[date].map((item, subIndex) => ({
              key: `${date}-${subIndex}`,
              ...item,
          }));
  
          const totalAmount = children.reduce((sum, item) => sum + item.amount, 0);
          const uniqueUsers = new Set(children.map((item) => item.user.userId)).size;
  
          return {
              key: index,
              date: date,
              userCount: uniqueUsers + ' รหัส',
              totalAmount: totalAmount,
              children: children,
          };
      });
  };
  

  const dataSource = prepareDataSource(data);

  console.log('dataSource: ', dataSource);
  console.log('bonusDetail: ', bonusDetail);

  const totalAmount = bonusDetail.reduce((sum, record) => sum + record.amount, 0);

   const handleWeekChange = ({ startOfWeek, endOfWeek }) => {
      const newWeek = { startOfWeek, endOfWeek };
      setWeek2(newWeek);
      
      const queryString = encodeURIComponent(JSON.stringify(newWeek));
      dispatch(getUsersBonusDetail(queryString));
   };

   return (
      <DefaultLayout>
         <Container>
            <div className='my-5'>
               <h3>
                  <b>สรุปค่าคอมมิชชั่นรายสัปดาห์</b>
               </h3>
            </div>

            {isLoading ? (
               <>
                  <Loading />
               </>
            ) : (
               <>
                  <div className='d-flex flex-row gap-3 justify-content-center'>
                     <WeekPicker className='justify-center' onWeekChange={handleWeekChange} />
                     {week2 && (
                     <>
                           <span>
                           <b>
                              {moment(week2.startOfWeek).format('DD/MM/YYYY')} -{' '}
                              {moment(week2.endOfWeek).format('DD/MM/YYYY')}
                           </b>
                        </span>
                     </>
                     )}
                  </div>
                  <br />
                 {dataSource.length >= 1 ? (
                   <>
                   
                   <Table columns={columns} dataSource={dataSource} 
                  
                   pagination={false} // Optional: add pagination if you have a lot of data
                 />
                        <br />
                <Card className=''>
           
                <h5 className='text-center'> <span><b>ยอดรวมโบนัสรายสัปดาห์</b> = </span>  <b>{commaNumber(totalAmount)} บาท</b></h5>
                </Card>
                   </>
                 ) : (
                  <>
                  <Card className='text-center'>
                  <span style={{fontSize: '18px'}} className=''>
                  <b >
                        เลือกวันเพื่อแสดงข้อมูล
                     </b>
                  </span>
                  </Card>
                  </>
                 )}
               </>
            )}
         </Container>
      </DefaultLayout>
   );
};


const Card = styled.div`
   background-color: ${(props) => props.backgroundColor || '#fff'};
   border-radius: 8px;
   box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
   padding: 16px;
   width: 350px;
   height: auto;
   margin-left: auto;
   margin-right: auto;
`;

export default BonusesUsers;
