import { createSlice } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';

const cartItemsFromStorage = localStorage.getItem('cartItems')
   ? JSON.parse(localStorage.getItem('cartItems'))
   : [];

const initialState = {
   //    cartItems: cartItemsFromStorage ? cartItemsFromStorage : null,
   cartItems: localStorage.getItem('cartItems')
      ? JSON.parse(localStorage.getItem('cartItems'))
      : [],
   cartTotalQuantity: 0,
   cartTotalAmount: 0,
   cartTotalPv: 0,
};

const cartSlice = createSlice({
   name: 'cart',
   initialState,
   reducers: {
      addToCart(state, action) {
         //  const item = action.payload;
         //  console.log(item);
         //  const existItem = state.cartItems.find(
         //     (x) => x.product === item.product
         //  );
         //  console.log(state.cartItems);
         //  if (existItem) {
         //     return {
         //        ...state,
         //        cartItems: state.cartItems.map((x) =>
         //           x.product === existItem.product ? item : x
         //        ),
         //     };
         //  } else {
         //     return {
         //        ...state,
         //        cartItems: [...state.cartItems, item],
         //     };
         //  }
         //  localStorage.setItem('cartItems', JSON.stringify(state.cartItems));

         const existingIndex = state.cartItems.findIndex(
            (item) => item.id === action.payload._id
         );

         const existItem = state.cartItems.find(
            (x) => x.id === action.payload._id
         );

         // if (existingIndex >= 0) {
         //    state.cartItems[existingIndex] = {
         //       ...state.cartItems[existingIndex],
         //       cartQuantity: action.payload.quantity,
         //    };
         //    toast.info('เพิ่มจำนวนสินค้า', {
         //       position: 'bottom-left',
         //    });
         // } else {
         //    let tempProductItem = {
         //       ...action.payload.product,
         //       cartQuantity: action.payload.quantity,
         //    };
         //    state.cartItems.push(tempProductItem);
         //    toast.success('สินค้าเพิ่มในตระกร้าสำเร็จ', {
         //       position: 'bottom-left',
         //    });
         // }

         if (existItem) {
            return {
               ...state,
               cartItems: state.cartItems.map((x) =>
                  x === existingIndex ? action.payload : x
               ),
            };
         } else {
            let tempProductItem = {
               ...action.payload,
               cartQuantity: action.payload.quantity,
            };
            state.cartItems.push(tempProductItem);
            toast.success('สินค้าเพิ่มในตระกร้าสำเร็จ', {
               position: 'bottom-left',
            });
         }

         localStorage.setItem('cartItems', JSON.stringify(state.cartItems));
      },

      increaseCart(state, action) {
         console.log(action.payload);
         const itemIndex = state.cartItems.findIndex(
            (item) => item.id === action.payload._id
         );

         if (state.cartItems[itemIndex].cartQuantity >= 1) {
            state.cartItems[itemIndex].cartQuantity += 1;

            toast.success('เพิ่มจำนวนสินค้าในตระกร้า', {
               position: 'bottom-left',
            });
         }

         localStorage.setItem('cartItems', JSON.stringify(state.cartItems));
      },

      decreaseCart(state, action) {
         console.log(action.payload);
         const itemIndex = state.cartItems.findIndex(
            (item) => item.id === action.payload.id
         );

         if (state.cartItems[itemIndex].cartQuantity > 1) {
            state.cartItems[itemIndex].cartQuantity -= 1;

            toast.success('ลดจำนวนสินค้าในตระกร้า', {
               position: 'bottom-left',
            });
         } else if (state.cartItems[itemIndex].cartQuantity === 1) {
            const nextCartItems = state.cartItems.filter(
               (item) => item.id !== action.payload.id
            );

            state.cartItems = nextCartItems;

            toast.error('ลบสินค้าออกจากตระกร้า', {
               position: 'bottom-left',
            });
         }

         localStorage.setItem('cartItems', JSON.stringify(state.cartItems));
      },
      removeFromCart(state, action) {
         state.cartItems.map((cartItem) => {
            if (cartItem.id === action.payload.id) {
               const nextCartItems = state.cartItems.filter(
                  (item) => item.id !== cartItem.id
               );

               state.cartItems = nextCartItems;

               toast.error('ลบสินค้าออกจากตระกร้า', {
                  position: 'bottom-left',
               });
            }
            localStorage.setItem('cartItems', JSON.stringify(state.cartItems));
            return state;
         });
      },
      getTotals(state, action) {
         let { total, quantity, pvTotal } = state.cartItems.reduce(
            (cartTotal, cartItem) => {
               const { cartQuantity } = cartItem;
               const { price, pv } = cartItem;
               const itemTotal = price * cartQuantity;
               const pvTotal2 = pv * cartQuantity;

               cartTotal.total += itemTotal;
               cartTotal.quantity += cartQuantity;
               cartTotal.pvTotal += pvTotal2;

               return cartTotal;
            },
            {
               total: 0,
               pvTotal: 0,
               quantity: 0,
            }
         );
         total = parseFloat(total.toFixed(2));
         state.cartTotalQuantity = quantity;
         state.cartTotalAmount = total;
         state.cartTotalPv = pvTotal;
      },
      clearCart(state, action) {
         state.cartItems = [];
         localStorage.setItem('cartItems', JSON.stringify(state.cartItems));
         toast.error('Cart cleared', { position: 'bottom-left' });
      },
   },
});

export const {
   addToCart,
   decreaseCart,
   removeFromCart,
   getTotals,
   clearCart,
   increaseCart,
} = cartSlice.actions;

export default cartSlice.reducer;
