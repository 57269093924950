import { message } from 'antd';
import axios from 'axios';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

// const API_URL = 'http://localhost:5000/api/users/';
// const API_URL2 = 'http://localhost:5000/api/usersbe/';

let API_URL = '';
let API_URL2;
if (process.env.NODE_ENV === 'development') {
   API_URL = 'http://localhost:5000/api/users/';
   API_URL2 = 'http://localhost:5000/api/usersbe/';
} else {
   API_URL = '//api.siamprai-login.com/api/users/';
   API_URL2 = '//api.siamprai-login.com/api/usersbe/';
}

// API_URL.interceptors.request.use((req) => {
//    if (localStorage.getItem('userInfo')) {
//       req.headers.Authorization = `Bearer ${
//          JSON.parse(localStorage.getItem('userInfo')).token
//       }`;
//    }
//    return req;
// });

// Gets Centers

// GET ALL USERS
const getUsers = async (token) => {
   const config = {
      headers: {
         Authorization: `Bearer ${token}`,
      },
   };

   const response = await axios.get(API_URL2, config);
   return response.data;
};

const getAllUsers = async (token) => {
   const config = {
      headers: {
         Authorization: `Bearer ${token}`,
      },
   };

   const response = await axios.get(API_URL + 'getAllUsers', config);
   return response.data;
};

const getUser = async (id, token) => {
   const config = {
      headers: {
         Authorization: `Bearer ${token}`,
      },
   };

   const response = await axios.get(API_URL + id, config);
   return response.data;
};

const getUserByUserId = async (id, token) => {
   const config = {
      headers: {
         Authorization: `Bearer ${token}`,
      },
   };

   const response = await axios.get(API_URL + 'getUserByUserId/' + id, config);
   return response.data;
};

const getNewUserForBinary = async (data, token) => {
   const config = {
      headers: {
         Authorization: `Bearer ${token}`,
      },
   };

   const response = await axios.get(
      API_URL + `getNewUserForBinary/${data.id}/${data.side}`,
      config
   );
   return response.data;
};

// UPDATE USER
const updateUser = async (userData, token) => {
   const config = {
      headers: {
         Authorization: `Bearer ${token}`,
      },
   };

   const res = await axios.patch(API_URL + `updateUser`, userData, config);
   return res.data;
};

// UPDATE PASSWORD
const updatePassword = async (passwordData, token) => {
   const config = {
      headers: {
         Authorization: `Bearer ${token}`,
      },
   };

   const response = await axios.post(
      API_URL + 'password',
      passwordData,
      config
   );
   return response.data;
};

// GET UPLINE
const getUpline = async (id, token) => {
   const config = {
      headers: {
         Authorization: `Bearer ${token}`,
      },
   };

   const response = await axios.get(API_URL + 'upline/' + id, config);

   return response.data;
};

// GET NEW UPLINE
const getNewUpline = async (id, token) => {
   const config = {
      headers: {
         Authorization: `Bearer ${token}`,
      },
   };

   const response = await axios.get(API_URL + 'GetUpline/' + id, config);

   return response.data;
};

// REGISTER
const register = async (user, token) => {
   const config = {
      headers: {
         Authorization: `Bearer ${token}`,
      },
   };
   const response = await axios.post(API_URL, user, config);

   return response.data;
};

// REGISTER 3
const register3 = async (user, token) => {
   const config = {
      headers: {
         Authorization: `Bearer ${token}`,
      },
   };

   const response = await axios.post(API_URL + 'register3', user, config);

   return response.data;
};

// REGISTER 7
const register7 = async (user, token) => {
   const config = {
      headers: {
         Authorization: `Bearer ${token}`,
      },
   };
   console.log(user);
   const response = await axios.post(API_URL + 'register7', user, config);

   return response.data;
};

// REGISTER 7
const register15 = async (user, token) => {
   const config = {
      headers: {
         Authorization: `Bearer ${token}`,
      },
   };
   console.log(user);
   const response = await axios.post(API_URL + 'register15', user, config);

   return response.data;
};

// CHECK USER MEMBER LINE
const checkMemberLine = async (dataId, token) => {
   const config = {
      headers: {
         Authorization: `Bearer ${token}`,
      },
   };

   const { recommendedBy, userUplineId } = dataId;

   const response = await axios.post(
      API_URL + `check/${recommendedBy}`,
      dataId,
      config
   );
   return response.data;
};

// UPDATE TO CENTER
const userToCenter = async (id, token) => {
   const config = {
      headers: {
         Authorization: `Bearer ${token}`,
      },
   };

   const res = await axios.put(API_URL2 + `updateToCenter/${id}`, {}, config);

   return res.data;
};

const getAllCenters = async (token) => {
   const config = {
      headers: {
         Authorization: `Bearer ${token}`,
      },
   };

   const res = await axios.get(API_URL2 + `centers`, config);

   return res.data;
};

const getUpTopIdUplineUser = async (id, token) => {
   const config = {
      headers: {
         Authorization: `Bearer ${token}`,
      },
   };

   const res = await axios.get(API_URL + `getIdUpTopUplineUser/${id}`, config);
   return res.data;
};

const getUsersByCardId = async (id, token) => {
   const config = {
      headers: {
         Authorization: `Bearer ${token}`,
      },
   };

   const res = await axios.get(API_URL + `getUsersByCardId/${id}`, config);
   return res.data;
};

const getTotalLeft = async (id, token) => {
   const config = {
      headers: {
         Authorization: `Bearer ${token}`,
      },
   };

   const res = await axios.get(
      API_URL + `getTotalLeft/${id}`,
      config
   );
   return res.data;
};
const getTotalRight = async (id, token) => {
   const config = {
      headers: {
         Authorization: `Bearer ${token}`,
      },
   };

   const res = await axios.get(
      API_URL + `getTotalRight/${id}`,
      config
   );
   return res.data;
};

const getOldTotalBonusBinary = async (id, token) => {
   const config = {
      headers: {
         Authorization: `Bearer ${token}`,
      },
   };

   const res = await axios.get(
      API_URL + `getOldTotalBonusBinary/${id}`,
      config
   );
   return res.data;
};
const getDatafromCardId = async (data, token) => {
   const config = {
      headers: {
         Authorization: `Bearer ${token}`,
      },
   };

   const res = await axios.get(API_URL + `getDatafromCardId/${data.cardId}/${data.uplineId}`, config);
   return res.data;
};

const userService = {
   getUsers,
   getUser,
   updatePassword,
   updateUser,
   getUpline,
   checkMemberLine,
   register,
   register3,
   register7,
   register15,
   userToCenter,
   getAllCenters,
   getUpTopIdUplineUser,
   getUsersByCardId,
   getNewUpline,
   getTotalLeft,
   getTotalRight,
   getOldTotalBonusBinary,
   getUserByUserId,
   getNewUserForBinary,
   getDatafromCardId,
   getAllUsers
};

export default userService;
