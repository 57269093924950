import React from 'react'
import ClipLoader from 'react-spinners/ClipLoader'

const LoadingSpinner = () => {
  return (
    <div 
    className='d-flex justify-content-center' 
    style={{marginLeft: 'auto', marginRight: 'auto', marginTop: '20%'}}
    >
        <ClipLoader color="#14213D" />
    </div>
  )
}

export default LoadingSpinner