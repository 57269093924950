import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Avatar, Button, List, Modal } from 'antd';

import { useDispatch, useSelector } from 'react-redux';
import Cookies from 'js-cookie';

const SearchForCart = ({ sendDataToParent }) => {
   const [query, setQuery] = useState('');

   const [isError, setIsError] = useState(false);

   const [data, setData] = useState({});
   const [modalOpen, setModalOpen] = useState(false);

   const dispatch = useDispatch();

   const [userUplineSelect, setUserUplineSelect] = useState({});

   // console.log('data : ' + JSON.stringify(data));
   // console.log('userUplineSelect: ' + JSON.stringify(userUplineSelect));

   const { userInfo } = useSelector((state) => state.auth);
   const { check } = useSelector((state) => state.user);

   useEffect(() => {
      const fetchData = async () => {
        const res = await axios.get(`//api.siamprai-login.com/api/users/search/${query}`, {
    //   const res = await axios.get(`http://localhost:5000/api/users/search/${query}`, {
            headers: {
               Authorization: `Bearer ${Cookies.get('token')}`,
            },
         });
         setData(res.data);
      };

      const fecthDataByUserId = async () => {
         try {
           const res = await axios.get(`//api.siamprai-login.com/api/users/searchByUserId/${query}`, {
      //      const res = await axios.get(`http://localhost:5000/api/users/searchByUserId/${query}`, {
               headers: {
                  Authorization: `Bearer ${Cookies.get('token')}`,
               },
            });
            setData(res.data);

            setIsError(false);
         } catch (error) {
            console.log(error);
            setIsError(true);
         }
      };

    //   if (query.length > 1) {
    //      fetchData();
    //      sendDataToParent(data);
    //   }

      if (query.length === 7) {
         fecthDataByUserId();
         sendDataToParent(data);
      }

      if (check === true) setModalOpen(true);
   }, [query, check]);

   const recId = localStorage.getItem('recomId');
   const recName = localStorage.getItem('recomName');

   return (
      <>
         {recId === null ? (
            <>
               <div>
                
                  <input
                     style={searchStyle}
                     className='search'
                     placeholder='ค้นหารหัสสมาชิก....'
                     onChange={(e) => setQuery(e.target.value.toLowerCase())}
                  />
               </div>
            </>
         ) : (
            // <input
            //    style={searchStyle}
            //    className='search'
            //    placeholder={userUplineSelect.name}
            //    values={userUplineSelect.name}
            // />
            <>
               {/* <h5 style={resultSearch}>
                  {userUplineSelect.userId} {userUplineSelect.name}
               </h5> */}
               <h5 style={resultSearch}>
                  {recId} - {recName}
               </h5>
            </>
         )}

         {isError && <b className='m-1 text-danger'> ไม่พบสิ่งที่ค้นหา </b>}

         {/* {data && (
            <Button
               type='danger'
               onClick={() => {
                  setData([]);
                  setUserUplineSelect('');
               }}>
               X
            </Button>
         )} */}
         <div className='m-2 text-primary'>
            {data &&
               data.map &&
               data.map((e) => (
                  <li
                     key={e._id}
                     style={{ cursor: 'pointer' }}
                     onClick={() => {
                        setData([]);
                        setUserUplineSelect(e);
                        sendDataToParent(e);
                        //    dispatch(checkMemberLine(userUplineSelect));
                     }}>
                     {' '}
                     {e.name} - {e.userId}55
                  </li>
               ))}
         </div>
         <div className='m-2 text-primary'>
            {data.userId ? (
               <p
                  style={{ cursor: 'pointer', color: 'red' }}
                  onClick={() => {
                     setUserUplineSelect(data);
                     sendDataToParent(data);
                     localStorage.setItem('recomId', data.userId);
                     localStorage.setItem('recomName', data.name);
                     setData([]);
                     // dispatch(checkMemberLine(userUplineSelect));
                  }}>
            <b>      {data.userId} - {data.name}</b>
               </p>
            ) : null}
         </div>
         {/* {data &&
            data.map &&
            data.map((e) => (
               <List
                  itemLayout='horizontal'
                  dataSource={data}
                  renderItem={(item) => (
                     <List.Item>
                        <List.Item.Meta title={item.name} />
                     </List.Item>
                  )}
               />
            ))} */}
         {/* {modalOpen && (
            <Modal
               width={700}
               title={'text...'}
               visible={modalOpen}
               footer={false}
               onCancel={() => {
                  setModalOpen(false);
               }}></Modal>
         )} */}
      </>
   );
};

export default SearchForCart;

const searchStyle = {
   padding: '4px 11px',
   border: '1px solid #d9d9d9',
   borderRadius: '2px',
   width: '100%',
};

const resultSearch = {
   borderBottom: '.5px dotted black',
};
