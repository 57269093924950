import React, { useEffect } from 'react';
import DefaultLayout from '../components/DefaultLayout';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getNewUserForBinary, reset } from '../features/users/usersSlice';
import { Table } from 'antd';
import { Container } from 'react-bootstrap';
import Loading from '../components/Loading';
import moment from 'moment';
import commaNumber from 'comma-number';

const LiveScoreDetail = () => {
   const params = useParams();
   const navigate = useNavigate();
   const dispatch = useDispatch();
   const { userInfo } = useSelector((state) => state.auth);
   const { users, isLoading } = useSelector((state) => state.user);

   const data = {
      id: userInfo._id,
      side: params.side,
   };

   useEffect(() => {
      dispatch(getNewUserForBinary(data));

      return () => {
         dispatch(reset());
      };
   }, [dispatch]);

   console.log('uu : ', users);

   const columns = [
      {
         title: 'ลำดับ',
         dataIndex: 'depth',
         key: 'depth',
         render: (text, record, index) => <span>{index + 1}</span>,
      },
      {
         title: 'ชื่อ',
         dataIndex: 'name',
      },
      {
         title: 'รหัส',
         dataIndex: 'userId',
      },
      {
         title: 'ชนิดรหัส',
         dataIndex: 'role',
      },

      {
         title: 'Pv',
         dataIndex: 'totalPvSum',
      },
   ];

   let totalSum = users.reduce((acc, current) => acc + current.totalPvSum, 0);

   const today = new Date();

   return (
      <DefaultLayout>
         <Container>
            {isLoading ? (
               <>
                  <Loading />
               </>
            ) : (
               <div className='my-5'>
                  <h4 className='text-center mb-1'>
                     <b>
                        สมาชิกใหม่ วันที่ : {moment(today).format('DD/MM/YYYY')}
                     </b>
                  </h4>
                  <h6 className='text-center my-3'>
                     <b>ยอดรวม : {commaNumber(totalSum)}</b>
                  </h6>
                  <div className=''>
                     <Table
                        style={{
                           width: '800px',
                           marginRight: 'auto',
                           marginLeft: 'auto',
                        }}
                        columns={columns}
                        dataSource={users}
                     />
                  </div>
               </div>
            )}
         </Container>
      </DefaultLayout>
   );
};

export default LiveScoreDetail;
