import { Table } from 'antd';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import DefaultLayout from '../components/DefaultLayout';
import Loading from '../components/Loading';
import { getCenterDeposit, getEwallet } from '../features/ewallet/ewalletSlice';

const HistoryDepositEwallet = () => {
   const dispatch = useDispatch();

   const { userInfo } = useSelector((state) => state.auth);
   const { ewallets, isLoading, isError, message } = useSelector(
      (state) => state.ewallet
   );

   console.log(ewallets);

   // console.log(rw2);
   const [loadingPage, setLoadingPage] = useState(true);

   useEffect(() => {
      if (loadingPage) {
         setTimeout(() => {
            setLoadingPage(false);
         }, 1500);
      }
      dispatch(getCenterDeposit());
   }, []);

   const columns = [
      // {
      //    title: 'วันที่',
      //    dataIndex: ['transfer,recieve'],
      //    key: 'date',
      //    render: (_, row) => (
      //       <>
      //          {row['transfer'].length > 0 ? (
      //             <>
      //                <p>{row['transfer'].map((e) => e.date)}</p>
      //             </>
      //          ) : (
      //             <>
      //                <p>{row['recieve'].map((e) => e.date)}</p>
      //             </>
      //          )}
      //       </>
      //    ),
      // },
      // {
      //    title: 'รายการ',
      //    dataIndex: ['transfer,recieve'],
      //    key: 'date',
      //    render: (_, row) => (
      //       <>
      //          {row['transfer'].length > 0 ? (
      //             <>
      //                โอนให้
      //                <p>{row['transfer'].map((e) => e.date)}</p>
      //             </>
      //          ) : (
      //             <>
      //                รับโอน
      //                <p>{row['recieve'].map((e) => e.admin)}</p>
      //             </>
      //          )}
      //       </>
      //    ),
      // },
      // {
      //    title: 'วันที่',
      //    dataIndex: 'action',
      //    key: 'action',
      //    render: (action) => <p> {action.map((e) => e.date)} </p>,
      // },
      {
         title: 'วันที่',
         dataIndex: 'date',
         key: 'date',
         render: (text) => (
            <div>
               <p> {moment(text).format('D/M/YYYY')} </p>
               <p> {moment(text).format('h:mm:ss a')} </p>
            </div>
         ),
      },
      {
         title: 'รายการ',
         dataIndex: ['userTransfer', 'userRecieve', 'note'],
         render: (_, row) => (
            <>
               {row['userTransfer'] ? (
                  <>
                     <p className='text-danger'>
                        {' '}
                        {row['note']} - {row['userTransfer'].userId}{' '}
                     </p>
                     <p className='text-info'> {row['userTransfer'].name} </p>
                  </>
               ) : (
                  <>
                     <p>
                        {' '}
                        {row['note']} - {row['userRecieve'].userId}{' '}
                     </p>
                     <p className='text-info'> {row['userRecieve'].name} </p>
                  </>
               )}
            </>
         ),
      },
      {
         title: 'ยอด/คงเหลือ',
         dataIndex: ['amount', 'previosAmount', 'transfer'],
         render: (_, row) => (
            <>
               {row['transfer'] === true ? (
                  <p className='text-danger'>
                     {' '}
                     - ฿{row['amount'].toLocaleString()}{' '}
                  </p>
               ) : (
                  <p className='text-info'>
                     {' '}
                     + ฿{row['amount'].toLocaleString()}{' '}
                  </p>
               )}
               <p> ฿{row['previosAmount'].toLocaleString()}</p>
            </>
         ),
      },
   ];

   return (
      <DefaultLayout>
         {isLoading ? (
            <>
               <Loading />{' '}
            </>
         ) : (
            <>
               Center ประวัติการเติม Wallet{' '}
               <Table columns={columns} dataSource={ewallets} />
            </>
         )}
      </DefaultLayout>
   );
};

export default HistoryDepositEwallet;
