import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Avatar, Button, List, Modal } from 'antd';
import { BsDashLg } from 'react-icons/bs';
import { useDispatch, useSelector } from 'react-redux';
import { checkMemberLine } from '../../features/users/usersSlice';
import Cookies from 'js-cookie';


const SearchInputForUpline = ({sendDataToParent2}) => {

    const [query, setQuery] = useState('');

    const [isError, setIsError] = useState(false);
 
    const [data, setData] = useState({});
    console.log(query);
    const [modalOpen, setModalOpen] = useState(false);
 
    const dispatch = useDispatch();
 
    const [userUplineSelect, setUserUplineSelect] = useState({});
 

    const { userInfo } = useSelector((state) => state.auth);
   const { check } = useSelector((state) => state.user);

   useEffect(() => {
      const fetchData = async () => {
    //    const res = await axios.get(`http://localhost:5000/api/users/search/searchForRegister/${query}`, {
        const res = await axios.get(`//api.siamprai-login.com/api/users/search/searchForRegister/${query}`, {
            headers: {
               Authorization: `Bearer ${Cookies.get('token')}`,
            },
         });
         setData(res.data);
      };

      const fecthDataByUserId = async () => {
         try {
    //     const res = await axios.get(`http://localhost:5000/api/users/searchByUserId/${query}`, {
        const res = await axios.get(`//api.siamprai-login.com/api/users/searchByUserId/${query}`, {
               headers: {
                  Authorization: `Bearer ${Cookies.get('token')}`,
               },
            });
            setData(res.data);
            setIsError(false);
         } catch (error) {
            console.log(error);
            setIsError(true);
         }
      };

     
      // if (query.length > 1) {
      //    fetchData();
      //    sendDataToParent2(data);
      // }
      if (query.length === 7) {
         fetchData();
     //    sendDataToParent(data);
      }

      if (check === true) setModalOpen(true);
   }, [query, check]);

   const uplineId = localStorage.getItem('uplineId');
   const uplineName = localStorage.getItem('uplineName');


   console.log('data:' ,data);
   return (
    <>
       {uplineId === null ? (
          <>
             <div>
                <input
                   style={searchStyle}
                   className='search'
                   placeholder='ค้นหาข้อมูล...'
                   onChange={(e) => setQuery(e.target.value.toLowerCase())}
                />
             </div>
          </>
       ) : (
          
          <>
            
             <h5 style={resultSearch}>
                {uplineId} - {uplineName}
             </h5>
          </>
       )}

       {data.message === 'ฝั่งซ้ายและขวาเต็มแล้ว' && <b className='m-1 text-danger'> ฝั่งซ้ายและขวาเต็มแล้ว </b>}

   

        <div className='m-2 text-danger'>
        {data.userUpline?.userId ? (
             <p
                style={{ cursor: 'pointer', color: '#00bbf0' }}
                onClick={() => {
                   setUserUplineSelect(data);
                   sendDataToParent2(data);
                   localStorage.setItem('uplineId', data.userUpline?.userId);
                   localStorage.setItem('uplineName', data.userUpline?.name);
                   // dispatch(checkMemberLine(userUplineSelect));
                }}>
                {data.userUpline?.userId} - {data.userUpline?.name}
             </p>
          ) : null}
       </div>
       
       
    </>
 );
}

export default SearchInputForUpline

const searchStyle = {
    padding: '4px 11px',
    border: '1px solid #d9d9d9',
    borderRadius: '2px',
    width: '100%',
 };
 
 const resultSearch = {
    borderBottom: '.5px dotted black',
 };