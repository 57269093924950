import { Table } from 'antd';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import DefaultLayout from '../components/DefaultLayout';
import Loading from '../components/Loading';
import { getRecLine } from '../features/commission/commissionSlice';

const RecommenderPage = () => {
   const [loadingPage, setLoadingPage] = useState(true);

   const { userInfo } = useSelector((state) => state.auth);
   const {
      recommendLine,
      isError,
      isSuccess,
      message,
      isLoading,
   } = useSelector((state) => state.commission);

   const dispatch = useDispatch();

   useEffect(() => {
      dispatch(getRecLine());
   }, []);

   console.log(recommendLine);
   const columns = [
      {
         title: 'ลำดับ',
         dataIndex: 'depth',
         key: 'depth',
         render: (text, record, index) => <span>{index + 1}</span>,
      },
      {
         title: 'ชื่อ',
         dataIndex: 'user_docs',
         key: 'user_docs',
         render: (user_docs) => (
            <>
               {' '}
               <p>
                  <b>{user_docs.map((e) => e.role)}</b> -
                  <span>{user_docs.map((e) => e.userId)} </span> -
                  <span> {user_docs.map((e) => e.name)}</span>
               </p>
            </>
         ),
      },
      {
         title: 'วันที่สมัคร',
         dataIndex: 'user_docs',
         key: 'user_docs',
         render: (user_docs) => (
            <>
               {' '}
               <p>
                  {user_docs.map((e) => moment(e.createdAt).format('D/M/YYYY'))}
               </p>
            </>
         ),
      },
      {
         title: 'ชั้น',
         dataIndex: 'depth',
         key: 'depth',
         render: (record) => (
            <>
               <p> {record + 1} </p>
            </>
         ),
      },
   ];

   return (
      <DefaultLayout>
         {isLoading ? (
            <>
               <Loading />
            </>
         ) : (
            <>
               <h3>สายงานแนะนำ</h3>
               <h3> ชั้นที่ : 1</h3>
               <h3> [{userInfo.userId} ]</h3>
               <h2> {userInfo.username} </h2>
               <Table columns={columns} dataSource={recommendLine} />{' '}
            </>
         )}
      </DefaultLayout>
   );
};

export default RecommenderPage;
