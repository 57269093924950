import { Button, Statistic, Table } from 'antd';
import React, { useEffect } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import DefaultLayout from '../components/DefaultLayout';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { getBonusMobileDetail } from '../features/commission/commissionSlice';
import { Container } from 'react-bootstrap';

const BonusMobileDetails = () => {
   const location = useLocation();
   const navigate = useNavigate();
   const { date } = useParams();

   const dispatch = useDispatch();

   const { comMobile, isError, isSuccess, message, isLoading } = useSelector(
      (state) => state.commission
   );

   const { userInfo } = useSelector((state) => state.auth);

   useEffect(() => {
      dispatch(getBonusMobileDetail(date));
   }, []);

   let bonus = null;

   if (comMobile?.length >= 1) {
      bonus = comMobile;
   }

   const columns = [
      {
         title: 'รหัสสมาชิก',
         dataIndex: 'userRegister',
         key: 'userRegister',
         render: (value) => <p> {value ? value.userId : 'N/A'} </p>,
      },
      {
         title: 'ชื่อ',
         dataIndex: 'userRegister',
         key: 'userRegister',
         render: (value) => <p> {value ? value.name : 'N/A'} </p>,
      },
      {
         title: 'จำนวนรหัส',
         dataIndex: 'numberOfRegister',
         key: 'numberOfRegister',
         render: (value) => <b className='text-primary'> {value} </b>,
      },
      {
         title: 'โบนัส',
         dataIndex: 'amount',
         key: 'amount',
         render: (value) => <b className='text-primary'> {value} </b>,
      },
   ];

   return (
      <DefaultLayout>
         <Container>
            <div>
               <div className='my-5 gap-3 d-flex'>
                  <Button
                     className='btn-danger'
                     onClick={() => navigate('/bonusHistory')}
                  >
                     {' '}
                     กลับไป{' '}
                  </Button>
                  <h3>
                     {' '}
                     <b>
                        {' '}
                        <span>ที่มาโบนัสโมบาย - วันที่</span>{' '}
                        <span> {moment(date).format('DD/MM/YYYY')}</span>
                     </b>
                  </h3>
               </div>
               <div className='mb-3'>
                  {bonus?.length >= 1 && (
                     <Table
                        summary={(bonus) => {
                           let totalAmount = 0;

                           bonus.forEach(({ amount }) => {
                              totalAmount += amount;
                           });

                           return (
                              <>
                                 <Table.Summary.Row>
                                    <Table.Summary.Cell>
                                       {' '}
                                       <b> รวม</b>{' '}
                                    </Table.Summary.Cell>
                                    <Table.Summary.Cell></Table.Summary.Cell>

                                    <Table.Summary.Cell></Table.Summary.Cell>
                                    <Table.Summary.Cell type='danger'>
                                       <b>
                                          {' '}
                                          <Statistic
                                             suffix='฿'
                                             value={totalAmount}
                                          />
                                       </b>
                                    </Table.Summary.Cell>
                                    <Table.Summary.Cell></Table.Summary.Cell>
                                 </Table.Summary.Row>
                              </>
                           );
                        }}
                        dataSource={bonus}
                        columns={columns}
                     />
                  )}
               </div>
            </div>
         </Container>
      </DefaultLayout>
   );
};

export default BonusMobileDetails;
