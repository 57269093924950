import axios from 'axios';

// const API_URL = '//api.siamprai-login.com/api/order/';

 const API = axios.create({ baseURL: '/api/order' });

let API_URL = ''

if(process.env.NODE_ENV === 'development') {
   API_URL = 'http://localhost:5000/api/order/';

} else {
   API_URL = '//api.siamprai-login.com/api/order/';
 
}

API.interceptors.request.use((req) => {
   if (localStorage.getItem('userInfo')) {
      req.headers.Authorization = `Bearer ${
         JSON.parse(localStorage.getItem('userInfo')).token
      }`;
   }
   return req;
});

// create order inhaler
const createOrderInhaler = async (data, token) => {
   const config = {
      headers: {
         Authorization: `Bearer ${token}`,
      },
   };

   const response = await axios.post(API_URL + '/inhaler', data, config);

   return response.data;
};

// Create  order
const createOrder = async (orderData, token) => {
   const config = {
      headers: {
         Authorization: `Bearer ${token}`,
      },
   };

   const response = await axios.post(API_URL, orderData, config);


   return response.data;
};

const createOrderRenew = async (orderData, token) => {
   const config = {
      headers: {
         Authorization: `Bearer ${token}`,
      },
   };

   const response = await axios.post(API_URL + '/addOrderRenew', orderData, config);


   return response.data;
};


// Gets Orders
const getOrders = async (token) => {
   const config = {
      headers: {
         Authorization: `Bearer ${token}`,
      },
   };

   const response = await axios.get(API_URL, config);
   return response.data;
};

// Gets getOrderById
const getOrderById = async (id, token) => {
   const config = {
      headers: {
         Authorization: `Bearer ${token}`,
      },
   };

   const response = await axios.get(API_URL + '/' + id, config);
   return response.data;
};
const getOrderDetailById = async (id, token) => {
   const config = {
      headers: {
         Authorization: `Bearer ${token}`,
      },
   };

   const response = await axios.get(API_URL + '/detail/' + id, config);
   return response.data;
};

const orderService = {
   createOrder,
   getOrders,
   getOrderById,
   createOrderInhaler,
   getOrderDetailById,
   createOrderRenew
};

export default orderService;
