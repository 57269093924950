import { Button, Form, Input, Modal } from 'antd';
import React, { useEffect, useState } from 'react';
import DefaultLayout from '../components/DefaultLayout';
import { AiOutlineLock } from 'react-icons/ai';
import { MdPassword } from 'react-icons/md';
import { Container } from 'react-bootstrap';
import { updatePassword, reset } from '../features/users/usersSlice';
import { useDispatch, useSelector } from 'react-redux';
import { FaTimes } from 'react-icons/fa';
import { toast } from 'react-toastify';

const ResetPasswordPage = () => {
   const dispatch = useDispatch();
   const [currentPassword, setCurrentPassword] = useState('');
   const [newPassword, setNewPassword] = useState('');
   const [ConfirmNewPassword, setConfirmNewPassword] = useState('');

   const [modalError,setModalError] = useState(false)

   const {
      user,
      isUpdatePasswordSuccess,
      isError,
      message,
   } = useSelector((state) => state.user);

   const onFinish = (values) => {
      if (ConfirmNewPassword !== newPassword) {
         message.error('รหัสผ่านไม่ตรงกัน');
      } else {
         dispatch(updatePassword(values));
        
      }
   };

   useEffect(() => {
      if (isUpdatePasswordSuccess) toast.success('เปลี่ยนรหัสผ่านสำเร็จ');
      if(isError){
         setModalError(true)
      }

   //     return () => {
   //     dispatch(reset());
   //  };

   },[isError,isUpdatePasswordSuccess])

   return (
      <DefaultLayout>
         <Container>
            <div className='text-center mt-5 form-block'>
               <h4>เปลี่ยนรหัสผ่าน </h4>{' '}
               <Form
                  name='normal_login'
                  className='login-form'
                  onFinish={onFinish}>
                  <Form.Item
                     name='password'
                     rules={[
                        {
                           required: true,
                           message: 'Please input your Username!',
                        },
                     ]}>
                     <Input.Password
                        prefix={
                           <AiOutlineLock className='site-form-item-icon' />
                        }
                     
                        placeholder='รหัสผ่านเก่า'
                     />
                  </Form.Item>
                  <Form.Item
                     name='newPassword'
                     validateStatus='warning'
                     rules={[
                        {
                           required: true,
                           message: 'Please input your newPassword!',
                        },
                     ]}>
                           <Input.Password
                        prefix={<MdPassword className='site-form-item-icon' />}
                    
                        placeholder='รหัสผ่านใหม่'
                        onChange={(e) => setNewPassword(e.target.value)}
                     />
                  </Form.Item>

                  <Form.Item
                     validateStatus='warning'
                     name='ConfirmNewPassword'
                     rules={[
                        {
                           required: true,
                           message: 'Please input your ConfirmNewPassword!',
                        },
                     ]}>
                             <Input.Password
                        prefix={<MdPassword className='site-form-item-icon' />}
                  
                        placeholder='ยืนยันรหัสผ่าน'
                        onChange={(e) => setConfirmNewPassword(e.target.value)}
                     />
                  </Form.Item>

                  <Form.Item>
                     <Button
                        type='primary'
                        htmlType='submit'
                        className='login-form-button'>
                        เปลี่ยนรหัสผ่าน
                     </Button>
                  </Form.Item>
               </Form>
            </div>
         </Container>
         <Modal
         title='ทำรายการไม่สำเร็จ'
         style={{height: '60%'}}
         visible={modalError}
         footer={false}
         onCancel={() => {
            setModalError(false);
            dispatch(reset())
         }}
         >
<div className='text-center'>
               <FaTimes size={128} color='red' />
               <h5>
                  <b>{message}</b>
               </h5>
            </div>
         </Modal>
      </DefaultLayout>
   );
};

export default ResetPasswordPage;
