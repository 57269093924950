import React, { useEffect, useState } from "react";
import DefaultLayout from "../components/DefaultLayout";
import { Container } from "react-bootstrap";

import {
  Layout,
  Menu,
  Image,
  Drawer,
  Space,
  Row,
  Col,
  Badge,
  Avatar,
  List,
  Button,
  Statistic,
  Form,
  Input,
  Descriptions,
  Checkbox,
  Modal,
} from "antd";
import { useDispatch, useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";
import SearchForCart from "../components/form/SearchForCart";
import { useNavigate, useParams } from "react-router-dom";
import { getProducts } from "../features/product/productSlice";
import styled from "styled-components";
import { createOrders, reset } from "../features/order/orderSlice";
import { FaTimes } from "react-icons/fa";
import { getUserByUserId } from "../features/users/usersSlice";
import { TbAlertTriangleFilled } from "react-icons/tb";
import { GiConfirmed } from "react-icons/gi";

const OrderToPay = () => {
  const navigate = useNavigate();
  const { name, pv } = useParams();

  const dispatch = useDispatch();
  const { userInfo } = useSelector((state) => state.auth);
  const isMobileL = useMediaQuery({ maxWidth: 450 });
  const [isCheckBox, setIsCheckBox] = useState(false);

  const [modalError, setModalError] = useState(false);
  const [modalSuccess, setModalSuccess] = useState(false);

  const [modalConfirm, setModalConfirm] = useState(false);

  const [recommendedBy, setRecommendedBy] = useState("");
  const recId = localStorage.getItem("recomId");
  const recName = localStorage.getItem("recomName");
  const [upLineData, setUpLineData] = useState(recId + " - " + recName);
  const sendDataToParent = (i) => {
    setRecommendedBy(i.userId);
  };

  const { products } = useSelector((state) => state.product);

  const { isError, message, isCreateError, isCreateSuccess } = useSelector(
    (state) => state.order
  );
  const { user } = useSelector((state) => state.user);

  console.log("isCreateSuccess:", isCreateSuccess);

  useEffect(() => {
    dispatch(getProducts());

    // return () => {
    //    dispatch(reset());
    // };
  }, [dispatch]);

  useEffect(() => {
    if (recId) {
      dispatch(getUserByUserId(recId));
    }

    if (isCreateError) {
      setModalError(true);
    }
    if(isCreateSuccess){
      setModalSuccess(true);
    }

    if (recId) {
      setIsCheckBox(true);
    }
    // if (isCreateSuccess) {
    //    setModalSuccess(true);
    //    console.log('Success');
    // }
  }, [isCreateError,isCreateSuccess, recId]);

  let ProductToPay;
  if (products) {
    ProductToPay = products.find((e) => e.name === name);
  }

  const orderCreateHandle = (event) => {
    event.preventDefault();
    console.log("create order");

    dispatch(
      createOrders({
        userId: recId ? recId : userInfo.userId,
        totalPrice: ProductToPay.price,
        totalPv: ProductToPay.pv,
      })
    );

   // setModalSuccess(true);
    // Clear the success modal after 15 seconds
    setTimeout(() => {
      setModalSuccess(false);
    }, 15000);

    // localStorage.removeItem("recomId");
    // localStorage.removeItem("recomName");

    setModalConfirm(false);
  };

  return (
    <DefaultLayout>
      <Container>
        <div className="mt-5">
          <Button className="" type="primary" onClick={() => navigate(-1)}>
            ย้อนกลับ
          </Button>
        </div>
        <div className="text-start d-flex flex-column gap-1 mt-5 ">
          <Checkbox
            checked={isCheckBox}
            onChange={(e) => {
              setIsCheckBox(e.target.checked);
            }}
          >
            {" "}
            <h5 style={{ fontSize: "16px" }}>ซื้อให้รหัสมาชิกอื่น</h5>
          </Checkbox>

          {isCheckBox && (
            <div>
              <SearchForCart
                upLineData={recId}
                value={recommendedBy}
                sendDataToParent={sendDataToParent}
              />
              <Button
                type="danger"
                onClick={() => {
                  localStorage.removeItem("recomId");
                  localStorage.removeItem("recomName");
                  window.location.reload();
                }}
              >
                CLEAR
              </Button>
            </div>
          )}
        </div>
        <Container fluid="sm">
          <div
            className="text-center"
            style={{ border: "0.5px solid gray", padding: "10px" }}
          >
            <div>
              <h4 className="text-start mb-4 mt-4">สรุปรายการสั่งซื้อสินค้า</h4>

              <div>
                <Row>
                  <Col lg={6} xl={6} md={8} sm={8} xs={8}>
                    <Image
                      preview={false}
                      width={isMobileL ? 100 : 150}
                      src={ProductToPay?.picUrl?.url}
                    />
                  </Col>
                  <Col lg={6} xl={6} md={8} sm={8} xs={8}>
                    <h3 className="text-start">
                      <b style={{ fontSize: isMobileL ? "14px" : "18px" }}>
                        Topup รหัสเป็น {name}
                      </b>{" "}
                    </h3>
                  </Col>
                  <Col lg={8} xl={8} md={8} sm={8} xs={8}>
                    {" "}
                    <h3>
                      {" "}
                      {/* <span className="amount text-end">
                        <Statistic value={pv} suffix="PV" />
                      </span> */}
                      <b style={{ fontSize: isMobileL ? "14px" : "18px" }}>
                        {pv} <span>PV</span>
                      </b>{" "}
                    </h3>
                    {/* <h5 className="mt-2" style={{ fontSize: "16px" }}>
                      {ProductToPay?.name}
                    </h5> */}
                  </Col>
                </Row>
              </div>
            </div>
          </div>
          <hr />
          <div>
             <h4><b>สรุปการ Topup รหัส</b></h4>
             
              <h5 style={{marginLeft: '50px'}} >รหัส Topup : <span> {recId} </span></h5>   
              <h5 style={{marginLeft: '50px'}}>ท็อปอัพเป็น : <span> {name} </span> </h5>    
              <h5 style={{marginLeft: '50px'}} >จำนวน PV : {pv} </h5>
            
          </div>
          <hr />
          <div className={`${isMobileL ? "text-center" : "text-end"}`}>
            <ButtonPay
              onClick={() => {
                setModalConfirm(true);
              }}
            >
              ยืนยัน Top up
            </ButtonPay>
          </div>
        </Container>
        <br />
      </Container>
      <Modal
        title="ยืนยันการทำรายการ"
        visible={modalConfirm}
        footer={false}
        onCancel={() => {
          setModalConfirm(false);
        }}
      >
        <div className="text-center">
          <div className="text-center">
            {" "}
            <TbAlertTriangleFilled
              size={128}
              color="#ffda30"
              className="text-center"
            />
          </div>
          <h5
            style={{
              fontSize: "30px",
              textAlign: "center",
              color: "red",
            }}
          >
            <b> ยืนยันการ Top up</b>{" "}
          </h5>
          {recId && (
            <>
              <p
                className="mb-2"
                style={{ fontSize: "16px", textAlign: "center" }}
              >
                <b>คุณยืนยันต้องการ Top up ให้กับสมาชิก หรือ ไม่</b>
              </p>
              <p
                className="mb-0"
                style={{ fontSize: "16px", textAlign: "center" }}
              >
                <b>
                  {" "}
                  รหัสสมาชิกรับการ Top up :{" "}
                  <span className="text-danger">{recId}</span>{" "}
                </b>
              </p>
            </>
          )}
          <br />
          <div className="d-flex">
            <br />
            <br />
            <Button
              style={{ backgroundColor: "#ffda30" }}
              block
              size="large"
              onClick={() => setModalConfirm(false)}
            >
              <h5>
                <b>ยกเลิก</b>
              </h5>
            </Button>{" "}
            <Button
              block
              size="large"
              type="primary"
              htmlType="submit"
              onClick={(event) => orderCreateHandle(event)}
            >
              <h5 style={{ color: "#ffda30" }}>
                <b>ยืนยัน</b>
              </h5>
            </Button>
          </div>
        </div>
      </Modal>
      <Modal
        title="รายการไม่สำเร็จ"
        visible={modalError}
        footer={false}
        onCancel={() => {
          setModalError(false);
          window.location.reload();
        }}
      >
        <div className="text-center">
          <FaTimes size={128} color="red" />
          <h5>
            <b>{message}</b>
          </h5>
        </div>
      </Modal>
      <Modal
        title="ทำรายการสำเร็จ"
        style={{ height: "60%" }}
        visible={modalSuccess}
        // footer={[
        //    <Button
        //    style={{backgroundColor: '#ffda30'}}
        //                            block
        //                            size='large'

        //                            onClick={() => setModalOpen(false)}
        //                         >
        //                            <h5><b>ยกเลิก</b></h5>
        //                            </Button>
        //  ]}
        footer={false}
        onCancel={() => {
          setModalSuccess(false);
          localStorage.removeItem("recomId");
          localStorage.removeItem("recomName");
          window.location.reload()
        }}
      >
        <div className="text-center mb-3">
          <GiConfirmed size={64} color="#4cbf2d" />
        </div>
        <h4
          style={{ fontSize: "30px" }}
          className="text-center mb-4 text-danger"
        >
          {" "}
          <b>ทำรายการ Top up สำเร็จ</b>{" "}
        </h4>

        <h5 className="text-center">
          <b>รหัสสมาชิกรับการ Top up: {recId}</b>
        </h5>
        <h5 className="text-center">
          <b>ชื่อสมาชิกรับการ Top up: {recName}</b>
        </h5>

        {/* <div className='my-2 text-center'>
                     <Image src={SuccessIcon} width='50%' preview={false} />
                  </div> */}
        <br />
      </Modal>
    </DefaultLayout>
  );
};

const sizes = {
  mobileS: "320px",
  mobileM: "375px",
  mobileL: "558px",
  tablet: "768px",
  laptop: "1024px",
  laptopL: "1440px",
  laptopXL: "1740px",
  desktop: "2560px",
};

const device = {
  mobileS: `(max-width: ${sizes.mobileS})`,
  mobileM: `(max-width: ${sizes.mobileM})`,
  mobileL: `(max-width: ${sizes.mobileL})`,
  tablet: `(max-width: ${sizes.tablet})`,
  laptop: `(max-width: ${sizes.laptop})`,
  laptopL: `(max-width: ${sizes.laptopL})`,
  laptopXL: `(max-width: ${sizes.laptopXL})`,
  desktop: `(max-width: ${sizes.desktop})`,
};

const ButtonPay = styled.button`
  background-color: #14213d;
  color: white;
  width: 20%;
  height: 40px;

  @media ${device.mobileL} {
    width: 90%;
    height: 40px;
  }
`;

export default OrderToPay;
