import React from 'react';

const Permission = () => {
   return (
      <div>
         <div>
            <span className='list-number'>1.</span>
            <span>
               ข้าพเจ้ามีอายุไม่น้อยกว่า <span className='list-number'>20</span>{' '}
               ปีบริบูรณ์ในวันที่ทำการสมัคร
               และมีเอกสารรับรองเพื่อใช้ในการสมัครสมาชิก
               หากข้อความดังกล่าวไม่เป็นจริง ข้าพเจ้ายินยอมให้ทางบริษัทฯ
               เพิกถอนสมาชิก และดำเนินคดีจนถึงที่สุด
            </span>
         </div>
         <br />
         <div>
            <span className='list-number'>2.</span>
            <span>
               ข้อตกลงระหว่างสมาชิกของ กับสมาชิก ข้อตกลงนี้เป็นข้อตกลงระหว่าง
               กับผู้สั่งซื้อสินค้าและสมัครสมาชิก มีรายละเอียดดังนี้
               ทำธุรกิจจำหน่ายสินค้าเพื่อสุขภาพ ฯลฯ ซึ่งต่อไปนี้จะเรียกว่า
               “บริษัท” สมาชิก
               คือผู้สั่งซื้อสินค้าของทางบริษัทและสมัครเป็นสมาชิกเครือข่ายของบริษัท
               เพื่อช่วยกันจำหน่ายสินค้าหรือกระจายสินค้า และ
               สามารถสั่งซื้อสินค้าได้ในราคาที่บริษัทกำหนด
               และยังได้รับค่าตอบแทนที่ท่านแนะนำท่านอื่นมาซื้อสินค้าและเป็นสมาชิกอีกด้วย
               ซึ่งต่อไปนี้จะเรียกว่า “สมาชิก”
               ผู้ประกอบธุรกิจขายตรงที่จะจ่ายผลตอบแทนให้กับผู้จำหน่ายอิสระตามแผนการจ่ายผลตอบแทนที่ได้จดทะเบียนไว้ต่อนายทะเบียน
               ณ สำนักงานคณะกรรมการคุ้มครองผู้บริโภค สคบ
               ตามที่ปรากฏในแผนการจ่ายผลตอบแทนของผู้ประกอบธุรกิจขายตรงที่มอบแก่ผู้จำหน่ายอิสระ
            </span>
         </div>
         <br />
         <div>
            <span className='list-number'>3.</span>
            <span>
               ผู้ประกอบธุรกิจขายตรงยินยอมที่จะรับซื้อคืนสินค้า
               วัสดุอุปกรณ์ส่งเสริมการขาย ชุดคู่มือ
               หรืออุปกรณ์ส่งเสริมธุรกิจจากผู้จำหน่ายอิสระ
               ในกรณีที่ผู้จำหน่ายอิสระประสงค์จะใช้สิทธิ์คืนสินค้าดังกล่าว
               โดยที่ผู้จำหน่ายอิสระจะต้องแสดงความจำนงใช้สิทธิ์ดังกล่าวต่อผู้ประกอบธุรกิจขายตรงภายในเวลา
               <span className='list-number'>30.</span> นับแต่วันที่ได้สินค้า
            </span>
         </div>
         <br />
         <div>
            <span className='list-number'>4.</span>
            <span>
               เมื่อผู้จำหน่ายอิสระใช้สิทธิ์คืนสินค้า วัสดุอุปกรณ์ส่งเสริมการขาย
               ชุดคู่มือ หรืออุปกรณ์ส่งเสริมธุรกิจ
               ที่ได้ซื้อไปจากผู้ประกอบธุรกิจขายตรง
               ให้ผู้ประกอบธุรกิจขายตรงซื้อคืนตามราคาที่ผู้จำหน่ายอิสระได้จ่ายไป
               ภายใน
               <span className='list-number'>15.</span>
               วัน นับตั้งแต่วันที่ผู้จำหน่ายอิสระได้ใช้สิทธิ์คืน
               แต่ในการใช้สิทธิ์ดังกล่าว กรณีที่สิ้นสุดระยะเวลาลงตามข้อ
               <span className='list-number'>3.</span>
               ผู้ประกอบธุรกิจขายตรงมีสิทธิ์หักค่าดำเนินการต่างๆได้
               และมีสิทธิ์หักลบกลบหนี้ใดๆอันเกี่ยวกับสัญญาข้อ
               <span className='list-number'>1.</span>{' '}
               ที่ผู้จำหน่ายอิสระต้องชำระได้
            </span>
         </div>
         <br />
         <div>
            <span className='list-number'>5.</span>
            <span>
               ข้าพเจ้าตกลงยินยอมและเข้าใจดีว่า
               การสั่งซื้อสินค้าและสมัครเป็นสมาชิกเป็นการสั่งซื้อสินค้าล่วงหน้าตามเงื่อนไขและแผนการตลาดของทางบริษัท
               และจะได้รับสินค้าจากบริษัทภายใน
               <span className='list-number'>60.</span>
               วัน
               นับจากวันที่สั่งซื้อสินค้าและสมัครเป็นสมาชิกสินค้าที่สั่งซื้อแล้ว
               และได้มีการคิดคำนวณผลตอบแทนไปแล้วนั้น
               จะไม่สามารถคืนได้ไม่ว่ากรณีใดๆ
               นอกจากนี้เงินค่าสินค้าที่ผู้สั่งซื้อสินค้าตามเงื่อนไขและแผนการตลาดของบริษัทฯ
               บริษัทฯไม่สามารถคืนให้แก่ลูกค้าได้เนื่องจากตามเงื่อนไขและแผนการตลาด
               มีค่าบริหารจัดการ ค่าการตลาด ค่าภาษีอากร และค่าใช้จ่ายอื่น
               ๆซึ่งมีการคำนวณผลประโยชน์ตอบแทนหรือโบนัสให้แก่สมาชิกจากผลประกอบการและผลกำไรจากการขายสินค้า
               เป็นระบบทั้งระบบ ดังนั้น จึงไม่สามารถคืนให้แก่ลูกค้าได้
            </span>
         </div>
         <br />
         <div>
            <span className='list-number'>6.</span>
            <span>
               ข้อกำหนดอื่น ๆ
               ที่ผู้ประกอบการขายตรงกำหนดได้ภายในกรอบกฎหมายการขายตรงและการตลาดทางตรง
               และข้อผูกพันนั้นต้องไม่ขัดต่อความสงบเรียบร้อยและศีลธรรมอันดี
            </span>
         </div>
         <br />
         <div>
            <span className='list-number'>7.</span>
            <span>
               ข้าพเจ้าตกลงและเข้าใจว่า
               การสั่งซื้อผลิตภัณฑ์และการสมัครเป็นสมาชิกของบริษัท
               ไม่มีส่วนเกี่ยวข้องกับบริษัทในฐานะผู้ถือหุ้น กรรมการ ผู้บริหาร
               พนักงาน เจ้าหน้าที่ พนักงาน หรือตัวแทนของบริษัท ดังนั้น
               ข้าพเจ้าไม่มีอำนาจในการทำการใด ๆ ในทางกฎหมายแทนบริษัทกับบุคคลอื่น
               ฯลฯ
            </span>
         </div>
         <br />
         <div>
            <span className='list-number'>8.</span>
            <span>
               ข้าพเจ้าตกลงและเข้าใจว่า
               การสั่งซื้อผลิตภัณฑ์และการสมัครเป็นสมาชิกของบริษัท
               ข้าพเจ้าไม่ได้รับการชักชวนหรือจูงใจ
               แต่ได้รับคำแนะนำจากบุคคลที่ใช้ผลิตภัณฑ์ก่อนและมีสถานะเป็นสมาชิกของบริษัทเท่านั้น
               ด้วยวัตถุประสงค์ในการบอกต่อเพื่อกระจายผลิตภัณฑ์และจัดจำหน่ายผลิตภัณฑ์ให้กับผู้บริโภคเพื่อผลกำไร
            </span>
         </div>
         <br />
         <div>
            <span className='list-number'>9.</span>
            <span>
               ข้าพเจ้าตกลง และเข้าใจว่า จะขายผลิตภัณฑ์ในราคาที่บริษัทฯ
               กำหนดโดยถ้าขายต่ำกว่าราคาดังกล่าว ข้าพเจ้ายินยอมให้บริษัทฯ
               แจ้งความดำเนินคดีเรียกร้องค่าเสียหาย
               ต่อการกระทำดังกล่าวของข้าพเจ้าได้ ทั้งทางแพ่งและอาญา
            </span>
         </div>
         <br />
         <div>
            <span className='list-number'>10.</span>
            <span>ข้าพเจ้าตกลงและเข้าใจว่า การเป็นสมาชิกจะสิ้นสุดเมื่อ</span>
         </div>
         <br />
         <div>
            <span className='list-number'>10.1</span>
            <span>
               เสียชีวิตและไม่มีทายาทที่สืบมรดกมาถ่ายโอนสถานะสมาชิกภายใน 1
               ปีนับจากวันที่สมาชิกเสียชีวิต
            </span>
         </div>
         <br />
         <div>
            <span className='list-number'>10.2</span>
            <span>ลาออก</span>
         </div>
         <br />
         <div>
            <span className='list-number'>10.3</span>
            <span>ถูกถอนจากสมาชิก</span>
         </div>
         <br />
         <div>
            <span className='list-number'>11</span>
            <span>
               ข้าพเจ้าตกลงและเข้าใจว่า
               ในกรณีที่สถานะสมาชิกสิ้นสุดด้วยการลาออกหรือด้วยเหตุผลใด ๆ
               สมาชิกที่มีสถานะสิ้นสุดสามารถสมัครเป็นสมาชิกได้หลังจาก 6
               เดือนนับจากวันที่ลาออกหรือวันที่สถานะสิ้นสุด
            </span>
         </div>
         <br />
         <div>
            <span className='list-number'>12</span>
            <span>
               ข้าพเจ้าตกลงและเข้าใจว่า
               ในกรณีที่สมาชิกไม่สามารถถ่ายโอนสถานะสมาชิกด้วยตนเองได้ด้วยเหตุผลใด
               ๆ บริษัทจะถ่ายโอนสถานะสมาชิกให้กับทายาทตามที่ระบุในใบสมัคร
            </span>
         </div>
         <br />
         <div>
            <span className='list-number'>12.1</span>
            <span>ในกรณีที่สมาชิกเสียชีวิต</span>
         </div>
         <br />
         <div>
            <span className='list-number'>12.2</span>
            <span>เมื่อแพทย์สรุปว่าผู้ป่วยเป็นบ้า</span>
         </div>
         <br />
         <div>
            <span className='list-number'>12.3</span>
            <span>
               เมื่อศาลมีคำสั่งให้เป็นคนไร้ความสามารถหรือเสมือนไร้ความสามารถ
            </span>
         </div>
         <br />
         <div>
            <span className='list-number'>13.</span>
            <span>
               ข้าพเจ้าตกลงและเข้าใจว่า
               ผู้ซื้อสินค้าและสมาชิกของสมาคมธุรกิจอิสระต้องประพฤติตนเหมาะสมกับอาชีพดังต่อไปนี้
            </span>
         </div>
         <br />
         <div>
            <span className='list-number'>13.1</span>
            <span>ให้บริการที่ดีสม่ำเสมอ</span>
         </div>
         <br />
         <div>
            <span className='list-number'>13.2</span>
            <span>
               ซื่อสัตย์และตรงไปตรงมากับลูกค้า บริษัท และเพื่อนร่วมงาน
               ซึ่งจะไม่แย่งงานจากเพื่อนร่วมงาน
               ไม่ก่อให้เกิดความเสียหายต่อลูกค้า บริษัท และเพื่อนร่วมงาน
               หากสมาชิกมีพฤติกรรมที่ผิดจรรยาบรรณเกี่ยวกับการแย่งงานหรือลูกค้า
               บริษัทจะตัดหรือถอนการเป็นสมาชิกจากบริษัททันที
               โดยไม่ต้องจ่ายเงินหรือผลประโยชน์ใด ๆ
               คืนให้กับสมาชิกที่มีพฤติกรรมที่ผิดจรรยาบรรณ
            </span>
         </div>
         <br />
         <div>
            <span className='list-number'>13.3</span>
            <span>ไม่ลดหรือเสนอการลดผลประโยชน์เพื่อดึงดูดลูกค้า</span>
         </div>
         <br />
         <div>
            <span className='list-number'>13.4</span>
            <span>
               ไม่เปรียบเทียบ ใส่ร้าย
               หรือพูดให้ร้ายที่อาจก่อให้เกิดความเสียหายต่อบริษัท ผู้บริหาร
               และเพื่อนร่วมงานโดยไม่เป็นธรรม ไม่ว่าทางตรงหรือทางอ้อม
            </span>
         </div>
         <br />
         <div>
            <span className='list-number'>13.5</span>
            <span>
               ศึกษาอย่างสม่ำเสมอเพื่อเพิ่มพูนความรู้ในอาชีพเป็นประจำ
               เข้าร่วมการฝึกอบรมหรือเข้าร่วมการประชุมที่บริษัทจัดขึ้นเป็นประจำ
            </span>
         </div>
         <br />
         <div>
            <span className='list-number'>13.6</span>
            <span>
               พัฒนาการแต่งกายและการตรงต่อเวลา
               ให้ความร่วมมืออย่างดีในกิจกรรมที่จัดโดยบริษัท
            </span>
         </div>
         <br />
         <div>
            <span className='list-number'>13.7</span>
            <span>
               ประพฤติตนด้วยคุณธรรมและจริยธรรม
               รักษาเกียรติและชื่อเสียงของคุณธรรม
            </span>
         </div>
         <br />
         <div>
            <span className='list-number'>14.</span>
            <span>
               ข้าพเจ้าตกลงและเข้าใจว่า
               บริษัทตกลงที่จะจ่ายค่าตอบแทนให้กับผู้ซื้อและสมาชิกตามผลงานและแผนการตลาดหรือจากผลกำไรจากการขายของบริษัท
            </span>
         </div>
         <br />
         <div>
            <span className='list-number'>15.</span>
            <span>
               ข้าพเจ้าตกลงและเข้าใจว่า การชักชวนหรือการเชิญบุคคลใด ๆ
               ให้สมัครเป็นสมาชิกโดยใช้สื่อหรือโฆษณาใด ๆ ที่เป็นการหลอกลวง
               โดยอ้างถึงผลประโยชน์เกินจริง นอกเหนือจากแผนหรือ นโยบายของบริษัท
               และไม่เป็นผลจากการยินยอมหรือรับรองจากบริษัท หรือการกระทำใด ๆ
               ที่อ้างสิทธิ์จากบริษัทโดยก่อให้เกิดความเสียหาย
               เป็นความรับผิดชอบของข้าพเจ้าแต่เพียงผู้เดียว ไม่ใช่ของบริษัท
               บริษัทไม่ต้องรับผิดชอบใด ๆ
               และข้าพเจ้ายินยอมรับผิดชอบทางกฎหมายทั้งทางแพ่งและอาญา
            </span>
         </div>
         <br />
         <div>
            <span className='list-number'>16.</span>
            <span>
               ข้าพเจ้าตกลงและเข้าใจว่า
               ข้าพเจ้าจะช่วยบริษัทขายผลิตภัณฑ์ด้วยวาจาและจะดูแลบริการลูกค้าที่ข้าพเจ้าแนะนำด้วยค่าใช้จ่ายของตนเอง
            </span>
         </div>
         <br />
         <div>
            <span className='list-number'>17.</span>
            <span>
               ข้าพเจ้าตกลงและเข้าใจว่า
               บริษัทห้ามสมาชิกละทิ้งลูกค้าและงานของตนโดยให้บริการที่ดีและสม่ำเสมอแก่ลูกค้าเมื่อสมัครเป็นสมาชิก
               ในพื้นที่ทำงานของตนต้องให้คำแนะนำวิธีการดำเนินธุรกิจของบริษัทอย่างถูกต้องโดยช่วยขายผลิตภัณฑ์ของบริษัท
               บอกคุณภาพของผลิตภัณฑ์ บริษัทให้กำลังใจ ให้ข้อมูล
               และแนะนำเข้าสู่แผนการฝึกอบรมต่าง ๆ
               ตามที่บริษัทจัดให้ถูกต้องสม่ำเสมอ
            </span>
         </div>
         <br />
         <div>
            <span className='list-number'>18.</span>
            <span>
               ข้าพเจ้าตกลงและเข้าใจว่า สมาชิกไม่มีสิทธิ์เรียกร้องเงินเดือน
               ผลประโยชน์ เงินทุนสนับสนุน และ/หรือรายได้อื่น ๆ จากบริษัท
               นอกจากรายได้ ค่าตอบแทน
               และผลประโยชน์พิเศษที่สมาชิกรับจากผลงานหรือกำไรจากการขายผลิตภัณฑ์ของบริษัทตามที่กำหนดในแผนของบริษัท
            </span>
         </div>
         <br />
         <div>
            <span className='list-number'>19.</span>
            <span>
               ข้าพเจ้าตกลงและเข้าใจว่า หากสมาชิกกระทำการใด ๆ
               ที่ก่อให้เกิดความรับผิดชอบต่อบริษัทด้วยเหตุผลใด ๆ และจำนวนเงินใด
               ๆ บริษัทมีสิทธิ์นำผลประโยชน์ของสมาชิกไปหักหนี้สินนั้น
            </span>
         </div>
         <br />
         <div>
            <span className='list-number'>20.</span>
            <span>
               ข้าพเจ้าตกลงและเข้าใจว่า ข้าพเจ้าจะไม่รับการทุจริตในรูปแบบใด ๆ
               จากฝ่ายอื่นเพื่อสนับสนุนการสรรหาหรือสนับสนุนผลิตภัณฑ์ของบริษัทที่จะส่งผลต่อสถานะการเป็นผู้จัดจำหน่ายของข้าพเจ้า
            </span>
         </div>
         <br />
         <div>
            <span className='list-number'>21.</span>
            <span>
               ข้าพเจ้าตกลงและเข้าใจว่า
               การติดต่อกับหน่วยงานของรัฐหรือองค์กรเอกชนใด ๆ เพื่อทำธุรกิจ
               ต้องได้รับการอนุมัติเป็นลายลักษณ์อักษรจากบริษัท
            </span>
         </div>
         <br />
         <div>
            <span className='list-number'>22.</span>
            <span>
               ข้าพเจ้าตกลงและเข้าใจว่า ผลิตภัณฑ์ของบริษัท อุปกรณ์ส่งเสริมการขาย
               สื่อสิ่งพิมพ์ เครื่องหมายการค้า ชื่อ
               และอุปกรณ์ที่เป็นกรรมสิทธิ์ของบริษัท ห้ามใช้
               ปลอมแปลงหรือทำซ้ำหรือแก้ไขเพื่อวัตถุประสงค์ใด ๆ ก็ตาม
            </span>
         </div>
         <br />
         <div>
            <span className='list-number'>23.</span>
            <span>
               ข้าพเจ้าตกลงและเข้าใจว่า สมาชิกไม่ได้รับอนุญาตให้ใช้สิทธิ์ใด ๆ
               เหนือเครื่องหมายการค้า เครื่องหมายบริการ โลโก้
               รูปภาพของเครื่องหมายที่ประดิษฐ์ขึ้น
               รวมถึงทรัพย์สินทางปัญญาทั้งหมดของบริษัทโดยไม่ได้รับความยินยอมเป็นลายลักษณ์อักษรจากกรรมการหรือผู้แทนที่ได้รับอนุญาตของบริษัท
            </span>
         </div>

         <br />
         <div>
            <span className='list-number'>24.</span>
            <span>
               ข้าพเจ้าตกลงและเข้าใจว่า ข้าพเจ้าจะนำเสนอผลิตภัณฑ์ แผนธุรกิจ
               และการฝึกอบรมของบริษัท
               โดยไม่โฆษณาหรือจูงใจด้วยการกล่าวอ้างหรือคำพูดที่ไม่เป็นความจริง
               นอกเหนือจากที่บริษัทได้รับรองในเอกสารหรือแผนของบริษัท
            </span>
         </div>
         <br />
         <div>
            <span className='list-number'>25.</span>
            <span>
               ข้าพเจ้าตกลงและเข้าใจว่า สมาชิกไม่ได้ถูกบังคับให้ซื้อผลิตภัณฑ์
               รวมถึงการกักตุนหรือการบังคับให้ใช้ผลิตภัณฑ์เกินความจำเป็น
               หรือขยายธุรกิจ
            </span>
         </div>
         <br />
         <div>
            <span className='list-number'>26.</span>
            <span>
               ข้าพเจ้าตกลงและเข้าใจว่า
               สมาชิกห้ามขายหรือวางจำหน่ายผลิตภัณฑ์ของบริษัทในสถานที่ใด ๆ เช่น
               ร้านค้าปลีก ร้านสหกรณ์ ตลาดน้อย ซูเปอร์มาร์เก็ต ห้างสรรพสินค้า
               หรือสถานที่อื่น ๆ หรือโฆษณาขายผลิตภัณฑ์ดังกล่าวผ่านสื่อใด ๆ
               โดยไม่ได้รับอนุมัติเป็นลายลักษณ์อักษรจากบริษัท
               เพื่อไม่ให้เกิดความได้เปรียบเหนือสมาชิกคนอื่น
            </span>
         </div>
         <br />
         <div>
            <span className='list-number'>27.</span>
            <span>
               ข้าพเจ้าตกลงและเข้าใจว่า
               สมาชิกห้ามขายหรือเสนอผลิตภัณฑ์ของบริษัทให้กับหน่วยงานอื่น
               นอกจากนี้ห้ามชักจูงสมาชิกอื่นให้เปลี่ยนสายงานเพื่อหลีกเลี่ยงความสับสน
               ทำลายความเป็นอันหนึ่งอันเดียวกัน
               และส่งผลต่อการจัดการตลาดของสมาชิกในสาขาอื่น ๆ
            </span>
         </div>
         <br />
         <div>
            <span className='list-number'>28.</span>
            <span>
               ข้าพเจ้าตกลงและเข้าใจว่า จะเข้าใจถูกต้องเกี่ยวกับบริษัท
               ไม่บิดเบือนข้อมูล
               พูดเกินจริงหรือนำเสนอข้อมูลที่อาจทำให้เข้าใจผิดเกี่ยวกับการดำเนินงานของผลิตภัณฑ์หรือระบบการชดเชยของบริษัท
               ซึ่งต้องให้ข้อเท็จจริงแก่ลูกค้าอย่างชัดเจนว่า ธุรกิจของ
               สยามไพรพลัส (อินเตอร์) จำกัด
               เป็นโอกาสในการสร้างผลลัพธ์การขายให้กับบริษัทที่นำรายได้จากแผนการตลาด
               คำนวณจากยอดการซื้อ ทั้งยอดการขายส่วนบุคคลและยอดการขายทีม
               ไม่ได้นำมาจากการหาจำนวนคนเข้าร่วมเครือข่ายการทำงาน
            </span>
         </div>
         <br />
         <div>
            <span className='list-number'>29.</span>
            <span>
               ข้าพเจ้าตกลงและเข้าใจว่า
               จะไม่ขายผลิตภัณฑ์ของบริษัทในราคาที่ต่ำกว่าราคาสมาชิก
               และสมาชิกบริษัทไม่มีสิทธิ์กำหนดราคานอกเหนือจากราคาของบริษัท
               เพื่อให้สอดคล้องกับความต้องการของตลาดสูงสุดเพื่อประโยชน์สูงสุดแก่สมาชิกคนอื่น
            </span>
         </div>
         <br />
         <div>
            <span className='list-number'>30.</span>
            <span>
               ข้าพเจ้าตกลงและเข้าใจว่า
               ห้ามขายหรือเสนอผลิตภัณฑ์อื่นนอกจากผลิตภัณฑ์ของบริษัทที่เป็นคู่แข่งกับผลิตภัณฑ์ของบริษัทอย่างเคร่งครัด
            </span>
         </div>
         <br />
         <div>
            <span className='list-number'>31.</span>
            <span>
               ข้าพเจ้าตกลงและเข้าใจว่า หากเกิดข้อพิพาทระหว่างสมาชิกกับสมาชิก
               หรือสมาชิกกับลูกค้า หรือสมาชิกกับบริษัท ไม่ว่ากรณีใด ๆ
               คำตัดสินของคณะกรรมการบริษัทถือเป็นที่สุด
            </span>
         </div>
         <br />
         <div>
            <span className='list-number'>32.</span>
            <span>
               ข้าพเจ้าตกลงและเข้าใจว่า
               ในกรณีที่รายได้และ/หรือค่าตอบแทนที่บริษัทจ่ายเกินกว่าสิทธิ์ที่ข้าพเจ้าได้รับ
               ข้าพเจ้าต้องคืนเงินให้บริษัทโดยเร็วที่สุดเมื่อทราบหรือได้รับแจ้ง
               โดยชำระคืนเป็นเงินสดหรือยอมให้บริษัทหักจากรายได้เกิน
            </span>
         </div>
         <br />
         <div>
            <span className='list-number'>33.</span>
            <span>
               ข้าพเจ้าตกลงและเข้าใจว่า ข้าพเจ้าอนุญาตให้บริษัทเก็บและส่งข้อมูล
               (ข้อมูลส่วนบุคคล ข้อมูลลูกค้า ข้อมูลการขายตรง และข้อมูลอื่น ๆ
               ทั้งหมดที่เกี่ยวข้อง) ด้วยวิธีการทางอิเล็กทรอนิกส์
            </span>
         </div>
         <br />
         <div>
            <span className='list-number'>34.</span>
            <span>
               ข้าพเจ้าตกลงและเข้าใจว่า
               ข้าพเจ้ายินยอมรับกฎระเบียบและจะปฏิบัติตามกฎหมาย
               ซึ่งรวมถึงพระราชบัญญัติการขายตรงและการขายตรง พ.ศ. 2545 ทั้งหมด
            </span>
         </div>
         <br />
         <div>
            <span className='list-number'>35.</span>
            <span>
               ข้าพเจ้าตกลงและเข้าใจว่า
               การกระทำของสมาชิกที่นำไปสู่การลงโทษมีดังนี้
            </span>
         </div>
         <br />
         <div>
            <span className='list-number'>35.1</span>
            <span>สมาชิกฝ่าฝืนกฎระเบียบและจรรยาบรรณของบริษัท</span>
         </div>
         <br />
         <div>
            <span className='list-number'>35.2</span>
            <span>
               สมาชิกกระทำการใด ๆ
               ที่ก่อให้เกิดความเสียหายแก่บริษัทและผลิตภัณฑ์ของบริษัท
            </span>
         </div>
         <br />
         <div>
            <span className='list-number'>35.3</span>
            <span>
               สมาชิกกระทำการใด ๆ
               ที่ก่อให้เกิดความเสียหายแก่บริษัทในการดำเนินธุรกิจ
            </span>
         </div>
         <br />
         <div>
            <span className='list-number'>35.4</span>
            <span>
               สมาชิกแจ้งหรือแสดงข้อมูลเกี่ยวกับสมาชิกหรือข้อมูลเกี่ยวกับบริษัทที่ไม่ตรงกับข้อเท็จจริง
            </span>
         </div>
         <br />
         <div>
            <span className='list-number'>36</span>
            <span>
               ข้าพเจ้าตกลงและเข้าใจว่า
               มาตรการลงโทษของบริษัทเรียงตามความรุนแรงของการกระทำดังนี้
            </span>
         </div>
         <br />
         <div>
            <span className='list-number'>36.1</span>
            <span>การเตือนเป็นลายลักษณ์อักษร</span>
         </div>
         <br />
         <div>
            <span className='list-number'>36.2</span>
            <span>ไม่ให้สิทธิ์ใด ๆ ซึ่งควรได้รับในรอบการชำระเงินนั้น</span>
         </div>
         <br />
         <div>
            <span className='list-number'>36.3</span>
            <span>ถอนสถานะการเป็นสมาชิก</span>
         </div>
      </div>
   );
};

export default Permission;
