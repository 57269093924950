import { Statistic, Table } from 'antd';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

const UserAutoShipTable = () => {
   const dispatch = useDispatch();
   const { spPoint } = useSelector((state) => state.user);

   const spPointNumber = spPoint.spPoints;

   const column = [
      {
         title: 'วันที่',
         dataIndex: 'date',
         render: (text) => <div>{text.toString().substring(0, 10)} </div>,
      },
      {
         title: 'จำนวน',
         dataIndex: 'amount',
      },
   ];

   return (
      <div>
         <Table
            summary={(spPointNumber) => {
               let totalAmount = 0;
               spPointNumber.forEach(({ amount }) => {
                  totalAmount += amount;
               });

               return (
                  <>
                     <Table.Summary.Row>
                        <Table.Summary.Cell>
                           {' '}
                           <b> รวม Auto Ship ทั้งหมด</b>{' '}
                        </Table.Summary.Cell>
                        <Table.Summary.Cell type='danger'>
                           <b>
                              {' '}
                              <Statistic suffix='SP' value={totalAmount} />
                           </b>
                        </Table.Summary.Cell>
                     </Table.Summary.Row>
                  </>
               );
            }}
            columns={column}
            dataSource={spPointNumber}
         />
      </div>
   );
};

export default UserAutoShipTable;
