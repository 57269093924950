import {
   Alert,
   Button,
   Card,
   Col,
   InputNumber,
   Modal,
   Result,
   Row,
   Tooltip,
} from 'antd';
import React, { useEffect, useState } from 'react';
import { Container } from 'react-bootstrap';
import { BsFillCartPlusFill } from 'react-icons/bs';
import { TbPackage } from 'react-icons/tb';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import DefaultLayout from '../components/DefaultLayout';
import Loading from '../components/Loading';
import UserAutoShipTable from '../components/Table/UserAutoShipTable';
import { Inhaler } from '../daumpData';
import { addToCart } from '../features/cartSlice';
import { createOrdersInhaler } from '../features/order/orderSlice';
import { getProducts } from '../features/product/productSlice';
import styled from 'styled-components';

const SpExchange = () => {
   const dispatch = useDispatch();

   const [modalConfirm, setModalConfirm] = useState(false);
   const [modalSpNull, setModalSpNull] = useState(false);
   const [modaleWalletLess, setModaleWalletLess] = useState(false);

   const [quantity, setQty] = useState(1);
   const [data, setData] = useState({});

   const { userInfo } = useSelector((state) => state.auth);
   const { ewallet } = useSelector((state) => state.ewallet);
   const { spPoint } = useSelector((state) => state.user);
   const { products, isLoading, isError, message } = useSelector(
      (state) => state.product
   );

   const {
      order,
      isLoading: orderLoading,
      isError: orderError,
      message: orderMessage,
   } = useSelector((state) => state.order);

   let Inhaler = products.filter((e) => e.isPromotion === true);

   useEffect(() => {
      dispatch(getProducts());
   }, []);

   const onChange = (value) => {
      setQty(value);
   };

   const handleOk = () => {
      setModalConfirm(false);
   };
   const handleCancel = () => {
      setModalConfirm(false);
   };

   const handleOrder = (data) => {
      // if (spPoint.spPoint === 0) setModalSpNull(true);

      // eslint-disable-next-line no-const-assign
      //  setQty(quantity + 1);
      const totalPrice = data.product.price * data.quantity;
      const priceLeft = totalPrice - spPoint.spPoint;

      if (ewallet.eWallet < priceLeft) {
         setModaleWalletLess(true);
      }

      if (totalPrice > spPoint.spPoint) {
         // toast.error('ยอด SP ของท่านไม่เพียงพอ', {
         //    position: 'top-center',
         //    autoClose: 5000,
         //    hideProgressBar: false,
         //    closeOnClick: true,
         //    pauseOnHover: true,
         //    draggable: true,
         //    progress: undefined,
         //    theme: 'colored',
         // });

         const priceLeft = totalPrice - spPoint.spPoint;

         // dispatch(createOrdersInhaler(data));
         // console.log('ยอด SP ของท่านไม่เพียงพอ');

         let confirmUseEwallet = window.confirm(
            'ท่านต้องการใช้ eWallet เพื่อจ่ายส่วนต่างเพิ่ม' +
               priceLeft +
               'หรือไม่'
         );

         // setTimeout(() => {
         //    confirmUseEwallet = window.confirm(
         //       'ท่านต้องการใช้ eWallet เพื่อจ่ายส่วนต่างเพิ่ม' +
         //          priceLeft +
         //          'หรือไม่'
         //    );
         // }, 2000);

         if (confirmUseEwallet === true) {
            console.log('test');

            if (priceLeft >= ewallet.ewallet) {
               // toast.error('ยอด eWallet ของท่านไม่เพียงพอ', {
               //    position: 'top-center',
               //    autoClose: 5000,
               //    hideProgressBar: false,
               //    closeOnClick: true,
               //    pauseOnHover: true,
               //    draggable: true,
               //    progress: undefined,
               //    theme: 'colored',
               // });
               console.log('ยอด eWallet ของท่านไม่เพียงพอ');
            } else {
               console.log('OK');
               dispatch(createOrdersInhaler(data));
            }
            // console.log('ตกลง');
         } else {
            console.log('ไม่');
         }
      }
      // dispatch(createOrdersInhaler(value));
   };

   const checkAsCanPay = (data) => {
      const totalPrice = data.product.price * data.quantity;

      if (totalPrice > spPoint.spPoint) {
         setModalConfirm(true);
      } else {
         //  dispatch(addToCart(data));

         const check = window.confirm(
            'ท่านต้องการใช้แต้ม' + ' ' + totalPrice + ' ' + 'แลกสินค้า ?'
         );

         if (check) {
            dispatch(createOrdersInhaler(data));
         } else {
         }
      }
   };

   return (
      <DefaultLayout>
         {isLoading ? (
            <>
               {' '}
               <Loading />{' '}
            </>
         ) : (
            <Container>
               <div>
                  {orderError && (
                     <Alert
                        style={{ width: '50%' }}
                        message={orderMessage}
                        type='error'
                        showIcon
                     />
                  )}
               </div>
               <br />
               <br />
               <div>
                  <UserAutoShipTable />
               </div>
               <TextHeader>
                  <h2 className='text-center'>
                     {' '}
                     Auto Ship คงเหลือ <span> {spPoint.spPoint} </span>{' '}
                  </h2>
               </TextHeader>
               <Row gutter={[100, 30]}>
                  {Inhaler.map((product) => {
                     return (
                        <Col
                           key={product.id}
                           sm={24}
                           xs={24}
                           md={24}
                           lg={10}
                           xl={8}>
                           <Card
                              size='big'
                              hoverable
                              style={{
                                 width: 300,
                                 marginRight: 'auto',
                                 marginLeft: 'auto',
                              }}
                              cover={
                                 <img
                                    alt='siamprai'
                                    style={{
                                       objectFit: 'contain',
                                       height: '300px !important',
                                    }}
                                    src={product.picUrl.url}
                                 />
                              }>
                              {/* <Meta title={product.name} /> */}
                              <h4 className='fw-bold'> {product.name}</h4>
                              <p className='fw-light fs-6'> {product.desc} </p>
                              <h5 className='fw-bold'> {product.price} SP </h5>

                              <Row
                                 justify='space-between'
                                 style={{
                                    textAlign: 'center',
                                    alignItems: 'center',
                                 }}>
                                 <Col span={4}>
                                    <h2 style={{ color: '#0092ca' }}>
                                       {' '}
                                       <TbPackage
                                          style={{
                                             fontSize: '36px',
                                             marginTop: '5px',
                                          }}
                                       />{' '}
                                    </h2>
                                 </Col>
                                 <Col span={8}>
                                    <InputNumber
                                       onChange={onChange}
                                       min={1}
                                       defaultValue={1}
                                       value={quantity}
                                    />
                                 </Col>
                                 <Col span={10}>
                                    <Tooltip title='สั่งซื้อสินค้า'>
                                       <Button
                                          type='primary'
                                          shape='circle'
                                          icon={<BsFillCartPlusFill />}
                                          size='large'
                                          onClick={() => {
                                             // handleOrder({ product, quantity })
                                             if (spPoint.spPoint === 0) {
                                                setModalSpNull(true);
                                             } else {
                                                setData({ product, quantity });
                                                checkAsCanPay({
                                                   product,
                                                   quantity,
                                                });
                                             }
                                             //setModalConfirm(true);
                                          }}
                                       />
                                    </Tooltip>
                                 </Col>
                              </Row>
                           </Card>
                        </Col>
                     );
                  })}
               </Row>
               <Modal
                  title='Confirm'
                  visible={modalConfirm}
                  onOk={handleOk}
                  onCancel={handleCancel}
                  footer={false}>
                  <Result
                     status='warning'
                     title='ยอด SP ของท่านไม่เพียงพอ'
                     subTitle='ยอด SP ของท่านไม่เพียงพอ'
                     extra={[
                        <Button
                           block
                           className='btn-warning mb-3'
                           onClick={() => {
                              handleOrder(data);
                              setModalConfirm(false);
                           }}>
                           {' '}
                           ใช้ eWallet จ่ายส่วนต่าง{' '}
                        </Button>,
                        <Button
                           block
                           onClick={() => setModalConfirm(false)}
                           className='btn-danger'>
                           {' '}
                           ยกเลิก
                        </Button>,
                     ]}></Result>
                  {/* <div className='text-center'>
                     <p> ยอด SP ของท่านไม่เพียงพอ </p>
                     <p></p>
                  </div> */}
                  {/* <div className='d-flex justify-content-around'>
                     <Button
                        className='btn-warning'
                        onClick={() => handleOrder(data)}>
                        {' '}
                        ใช้ eWallet จ่ายส่วนต่าง{' '}
                     </Button>
                     <Button
                        onClick={() => setModalConfirm(false)}
                        className='btn-danger'>
                        {' '}
                        ยกเลิก
                     </Button>
                  </div> */}
               </Modal>
               <Modal
                  title='SP ของท่านไม่เพียงพอ'
                  visible={modalSpNull}
                  onCancel={() => setModalSpNull(false)}
                  footer={false}>
                  <Result
                     status='warning'
                     title='ยอด SP ของท่านไม่เพียงพอ '
                     extra={
                        <Button
                           block
                           onClick={() => setModalSpNull(false)}
                           className='btn-danger'>
                           {' '}
                           ปิด
                        </Button>
                     }
                  />
                  {/* <div className='text-center'>
                     <p> ยอด SP ของท่านไม่เพียงพอ </p>
                  </div>
                  <div className='d-flex justify-content-around'>
                     <Button
                        onClick={() => setModalSpNull(false)}
                        className='btn-danger'>
                        {' '}
                        ปิด
                     </Button>
                  </div> */}
               </Modal>

               <Modal
                  title='eWallet ของท่านไม่เพียงพอ'
                  visible={modaleWalletLess}
                  onCancel={() => setModaleWalletLess(false)}
                  footer={false}>
                  <Result
                     status='error'
                     title='ยอด eWalletของท่านไม่เพียงพอ 
                     กรุณาเติม eWallet '
                     extra={[
                        <Button
                           block
                           onClick={() => setModaleWalletLess(false)}
                           className='btn-danger'>
                           {' '}
                           ปิด
                        </Button>,
                     ]}></Result>
               </Modal>
            </Container>
         )}
      </DefaultLayout>
   );
};

const TextHeader = styled.div`
   margin: 20px auto;
   border: 5px solid;
   padding: 20px;
   border-radius: 10px;
   color: gray;
   width: 50%;
`;

export default SpExchange;
