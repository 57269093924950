import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import productService from './productService';
import Cookies from 'js-cookie';

const initialState = {
   products: [],
   product: {},
   isError: false,
   isSuccess: false,
   isLoading: false,
   getLoading: false,
   getSuccess: false,
   createSuccess: false,
   deleteSuccess: false,
   updateSuccess: false,
   message: '',
};

export const getProducts = createAsyncThunk(
   'product/getProducts',
   async (_, thunkAPI) => {
      try {
         const token = Cookies.get('token')
         return await productService.getProducts(token);
      } catch (error) {
         const message =
            (error.response &&
               error.response.data &&
               error.response.data.message) ||
            error.message ||
            error.toString();
         return thunkAPI.rejectWithValue(message);
      }
   }
);

// GET PRODUCT AUTOSHIP
export const getProductsAutoShip = createAsyncThunk(
   'product/getProductsAutoShip',
   async (_, thunkAPI) => {
      try {
         const token = Cookies.get('token')
         return await productService.getProductsAutoShip(token);
      } catch (error) {
         const message =
            (error.response &&
               error.response.data &&
               error.response.data.message) ||
            error.message ||
            error.toString();
         return thunkAPI.rejectWithValue(message);
      }
   }
);

// CREATE PRODUCT
export const createProduct = createAsyncThunk(
   'product/createProduct',
   async (data, thunkAPI) => {
      try {
         const token = Cookies.get('token')
         return await productService.createProduct(data, token);
      } catch (error) {
         const message =
            (error.response &&
               error.response.data &&
               error.response.data.message) ||
            error.message ||
            error.toString();
         return thunkAPI.rejectWithValue(message);
      }
   }
);

// CREATE PRODUCT PROMOTION
export const createProductPromotion = createAsyncThunk(
   'product/createProductPromotion',
   async (data, thunkAPI) => {
      try {
         const token = Cookies.get('token')
         return await productService.createProductPromotion(data, token);
      } catch (error) {
         const message =
            (error.response &&
               error.response.data &&
               error.response.data.message) ||
            error.message ||
            error.toString();
         return thunkAPI.rejectWithValue(message);
      }
   }
);

// UPDATE PRODUCT
export const updateProduct = createAsyncThunk(
   'product/updateProduct',
   async (data, thunkAPI) => {
      try {
         const token = Cookies.get('token')
         return await productService.updateProduct(data, token);
      } catch (error) {
         const message =
            (error.response &&
               error.response.data &&
               error.response.data.message) ||
            error.message ||
            error.toString();
         return thunkAPI.rejectWithValue(message);
      }
   }
);

// UPDATE PRODUCT
export const deleteProduct = createAsyncThunk(
   'product/deleteProduct',
   async (data, thunkAPI) => {
      try {
         const token = Cookies.get('token')
         return await productService.deleteProduct(data, token);
      } catch (error) {
         const message =
            (error.response &&
               error.response.data &&
               error.response.data.message) ||
            error.message ||
            error.toString();
         return thunkAPI.rejectWithValue(message);
      }
   }
);

export const updatePromotion = createAsyncThunk(
   'product/updatePromotion',
   async (id, thunkAPI) => {
      try {
         const token = Cookies.get('token')
         console.log(id);
         return await productService.productPromotion(id, token);
      } catch (error) {
         const message =
            (error.response &&
               error.response.data &&
               error.response.data.message) ||
            error.message ||
            error.toString();
         return thunkAPI.rejectWithValue(message);
      }
   }
);

const productSlice = createSlice({
   name: 'product',
   initialState,
   reducers: {
      reset: (state) => initialState,
   },
   extraReducers: (builder) => {
      builder
         .addCase(getProducts.pending, (state) => {
            state.getLoading = true;
         })
         .addCase(getProducts.fulfilled, (state, action) => {
            state.getLoading = false;
            state.getSuccess = true;
            state.products = action.payload;
         })
         .addCase(getProducts.rejected, (state, action) => {
            state.getLoading = false;
            state.isError = true;
            state.message = action.payload;
         })
         .addCase(getProductsAutoShip.pending, (state) => {
            state.getLoading = true;
         })
         .addCase(getProductsAutoShip.fulfilled, (state, action) => {
            state.getLoading = false;
            state.getSuccess = true;
            state.products = action.payload;
         })
         .addCase(getProductsAutoShip.rejected, (state, action) => {
            state.getLoading = false;
            state.isError = true;
            state.message = action.payload;
         })
         .addCase(createProduct.pending, (state) => {
            state.isLoading = true;
         })
         .addCase(createProduct.fulfilled, (state, action) => {
            state.isLoading = false;
            state.createSuccess = true;
            state.product = action.payload;
         })
         .addCase(createProduct.rejected, (state, action) => {
            state.isLoading = false;
            state.isError = true;
            state.message = action.payload;
         })
         .addCase(createProductPromotion.pending, (state) => {
            state.isLoading = true;
         })
         .addCase(createProductPromotion.fulfilled, (state, action) => {
            state.isLoading = false;
            state.createSuccess = true;
            state.product = action.payload;
         })
         .addCase(createProductPromotion.rejected, (state, action) => {
            state.isLoading = false;
            state.isError = true;
            state.message = action.payload;
         })
         .addCase(updateProduct.pending, (state) => {
            state.isLoading = true;
         })
         .addCase(updateProduct.fulfilled, (state, action) => {
            state.isLoading = false;
            state.updateSuccess = true;
            state.product = action.payload;
         })
         .addCase(updateProduct.rejected, (state, action) => {
            state.isLoading = false;
            state.isError = true;
            state.message = action.payload;
         })
         .addCase(updatePromotion.pending, (state) => {
            state.isLoading = true;
         })
         .addCase(updatePromotion.fulfilled, (state, action) => {
            state.isLoading = false;
            state.isSuccess = true;
            state.products.push(action.payload);
         })
         .addCase(updatePromotion.rejected, (state, action) => {
            state.isLoading = false;
            state.isError = true;
            state.message = action.payload;
         })
         .addCase(deleteProduct.pending, (state) => {
            state.isLoading = true;
         })
         .addCase(deleteProduct.fulfilled, (state, action) => {
            state.isLoading = false;
            state.isSuccess = true;
            state.products = state.products.filter(
               (product) => product._id !== action.payload.id
            );
         })
         .addCase(deleteProduct.rejected, (state, action) => {
            state.isLoading = false;
            state.isError = true;
            state.message = action.payload;
         });
   },
});

export const { reset } = productSlice.actions;
export default productSlice.reducer;
