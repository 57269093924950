import React , {CSSProperties} from 'react';
import FadeIn from 'react-fade-in';
import Lottie from 'react-lottie';
import * as loadingData from '../assets/loading.json';
//import * as loadingData from '../assets/loadingProgress.json';
//import * as loadingData from '../assets/LoadingCircle.json';
import ClipLoader from 'react-spinners/ClipLoader'

const defaultOptions = {
   loop: true,
   autoplay: true,
   animationData: loadingData.default,
   rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
   },
};

const Loading = () => {


   return (
      <div className='loading'>
         <FadeIn>
            <Lottie options={defaultOptions} height={140} width={140}  />
            {/* <ClipLoader color="#36d7b7" /> */}
         </FadeIn>
      </div>
   );
};

export default Loading;
