import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { Form, Input, Button, Col, Row, Spin } from 'antd';
import DefaultLayout from '../components/DefaultLayout';
import { getCenterId } from '../features/center/centerSlice';

const CenterDetail = () => {
   const { state } = useLocation();
   const { centerId } = state;

   const dispatch = useDispatch();

   const { userInfo } = useSelector((state) => state.auth);

   const {
      center,
      isLoading,
      isError: centerUpdateError,
      isSuccess: centerUpdateSuccess,
      message: messageCenters,
   } = useSelector((state) => state.centers);
   //    const [form] = Form.useForm();

   useEffect(() => {
      dispatch(getCenterId(centerId));

      //   form.setFieldsValue({
      //      nameCenter: center.name,
      //      numberCenter: center.number,
      //      address: center.address,
      //      province: center.province,
      //      amphoe: center.amphoe,
      //      district: center.district,
      //      zipCode: center.zipCode,
      //      centerId: center.centerId,
      //   });
   }, [dispatch]);

   console.log(center);

   return <DefaultLayout></DefaultLayout>;
};

export default CenterDetail;
