import axios from 'axios';

// const API_URL = 'http://localhost:5000/api/setting/';
const API_URL2 = 'http://localhost:5000/api/users/';

let API_URL = ''
if(process.env.NODE_ENV === 'development') {
   API_URL = 'http://localhost:5000/api/setting/';
} else {
   API_URL =  '//api.siamprai-login.com/api/setting/';

}

const createSetting = async (data, token) => {
   const config = {
      headers: {
         Authorization: `Bearer ${token}`,
      },
   };

   const response = await axios.post(API_URL, data, config);
   return response.data;
};

const getSetting = async (token) => {
   const config = {
      headers: {
         Authorization: `Bearer ${token}`,
      },
   };

   const response = await axios.get(API_URL, config);
   return response.data;
};

const updateSetting = async (data, token) => {
   const config = {
      headers: {
         Authorization: `Bearer ${token}`,
      },
   };

   const response = await axios.put(API_URL + 'update', data, config);
   return response.data;
};




const settingService = {
   getSetting,
   updateSetting,
   createSetting,
 
};

export default settingService;
