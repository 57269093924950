import React from 'react'
import ClipLoader from 'react-spinners/ClipLoader'

const LoadingSpinner = () => {
  return (
    <div 
  
    >
        <ClipLoader color="#14213D" />
    </div>
  )
}

export default LoadingSpinner