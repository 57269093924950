import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import userService from './userService';
import { toast } from 'react-toastify';
import Cookies from 'js-cookie';

const initialState = {
   users: [],
   centers: [],
   user: {},
   userFormCardId: {},
   upline: {},
   totalLeft : {},
   totalRight: {},
   oldBonus: [],
   UpTopIdUplineUser: {},
   getUpTopSuccess: false,
   check: null,
   isError: false,
   userFormCardIdError: false,
   isSuccess: false,
   isUpdatePasswordSuccess: false, 
   isUpdateSuccess: false,
   registerSuccess: false,
   isLoading: false,
   message: '',
   userFormCardIdMessage: '',
};

//get user by id
// export const getUser = createAsyncThunk(
//    'user/getUser',
//    async (id, { rejectWithValue }) => {
//       try {
//          const response = await api.getUser(id);
//          return response.data;
//       } catch (err) {
//          return rejectWithValue(err.response.data);
//       }
//    }
// );


// GET UpTopIdUplineUser
export const getUpTopIdUplineUser = createAsyncThunk(
   'user/getUpTopIdUplineUser',
   async (id, thunkAPI) => {
      try {
         const token = Cookies.get('token');
         return await userService.getUpTopIdUplineUser(id,token);
      } catch (error) {
         const message =
            (error.response &&
               error.response.data &&
               error.response.data.message) ||
            error.message ||
            error.toString();
         return thunkAPI.rejectWithValue(message);
      }
   }
);

export const getOldTotalBonusBinary = createAsyncThunk(
   'user/getOldTotalBonusBinary',
   async (id, thunkAPI) => {
      try {
         const token = Cookies.get('token');
         return await userService.getOldTotalBonusBinary(id,token);
      } catch (error) {
         const message =
            (error.response &&
               error.response.data &&
               error.response.data.message) ||
            error.message ||
            error.toString();
         return thunkAPI.rejectWithValue(message);
      }
   }
);


// GET ALL USERS
export const getUsers = createAsyncThunk(
   'user/getUsers',
   async (_, thunkAPI) => {
      try {
         const token = Cookies.get('token');
         return await userService.getUsers(token);
      } catch (error) {
         const message =
            (error.response &&
               error.response.data &&
               error.response.data.message) ||
            error.message ||
            error.toString();
         return thunkAPI.rejectWithValue(message);
      }
   }
);
export const getAllUsers = createAsyncThunk(
   'user/getAllUsers',
   async (_, thunkAPI) => {
      try {
         const token = Cookies.get('token');
         return await userService.getAllUsers(token);
      } catch (error) {
         const message =
            (error.response &&
               error.response.data &&
               error.response.data.message) ||
            error.message ||
            error.toString();
         return thunkAPI.rejectWithValue(message);
      }
   }
);


export const getUsersByCardId = createAsyncThunk(
   'user/getUsersByCardId',
   async (id, thunkAPI) => {
      try {
         const token = Cookies.get('token');
         return await userService.getUsersByCardId(id,token);
      } catch (error) {
         const message =
            (error.response &&
               error.response.data &&
               error.response.data.message) ||
            error.message ||
            error.toString();
         return thunkAPI.rejectWithValue(message);
      }
   }
);
export const getDatafromCardId = createAsyncThunk(
   'user/getDatafromCardId',
   async (data, thunkAPI) => {
      try {
         const token = Cookies.get('token');
         return await userService.getDatafromCardId(data,token);
      } catch (error) {
         const message =
            (error.response &&
               error.response.data &&
               error.response.data.message) ||
            error.message ||
            error.toString();
         return thunkAPI.rejectWithValue(message);
      }
   }
);

export const getNewUserForBinary = createAsyncThunk(
   'user/getNewUserForBinary',
   async (data, thunkAPI) => {
      try {
         const token = Cookies.get('token');
         return await userService.getNewUserForBinary(data,token);
      } catch (error) {
         const message =
            (error.response &&
               error.response.data &&
               error.response.data.message) ||
            error.message ||
            error.toString();
         return thunkAPI.rejectWithValue(message);
      }
   }
);

export const getTotalLeft = createAsyncThunk(
   'user/getTotalLeft',
   async (id, thunkAPI) => {
      try {
         const token = Cookies.get('token');
         return await userService.getTotalLeft(id,token);
      } catch (error) {
         const message =
            (error.response &&
               error.response.data &&
               error.response.data.message) ||
            error.message ||
            error.toString();
         return thunkAPI.rejectWithValue(message);
      }
   }
);

export const getTotalRight = createAsyncThunk(
   'user/getTotalRight',
   async (id, thunkAPI) => {
      try {
         const token = Cookies.get('token');
         return await userService.getTotalRight(id,token);
      } catch (error) {
         const message =
            (error.response &&
               error.response.data &&
               error.response.data.message) ||
            error.message ||
            error.toString();
         return thunkAPI.rejectWithValue(message);
      }
   }
);

// GET ALL CENTERS
export const getCenters = createAsyncThunk(
   'user/getCenters',
   async (_, thunkAPI) => {
      try {
         const token = Cookies.get('token');
         return await userService.getAllCenters(token);
      } catch (error) {
         const message =
            (error.response &&
               error.response.data &&
               error.response.data.message) ||
            error.message ||
            error.toString();
         return thunkAPI.rejectWithValue(message);
      }
   }
);

// GET USER
export const getUser = createAsyncThunk(
   'user/getUser',
   async (id, thunkAPI) => {
      try {
         const token = Cookies.get('token');
         return await userService.getUser(id, token);
      } catch (error) {
         const message =
            (error.response &&
               error.response.data &&
               error.response.data.message) ||
            error.message ||
            error.toString();
         return thunkAPI.rejectWithValue(message);
      }
   }
);

export const getUserByUserId = createAsyncThunk(
   'user/getUserByUserId',
   async (id, thunkAPI) => {
      try {
         const token = Cookies.get('token');
         return await userService.getUserByUserId(id, token);
      } catch (error) {
         const message =
            (error.response &&
               error.response.data &&
               error.response.data.message) ||
            error.message ||
            error.toString();
         return thunkAPI.rejectWithValue(message);
      }
   }
);

// REGISTER
export const register = createAsyncThunk(
   'user/register',
   async (user, thunkAPI) => {
      try {
         const token = Cookies.get('token');
         return await userService.register(user, token);
      } catch (error) {
         const message =
            (error.response &&
               error.response.data &&
               error.response.data.message) ||
            error.message ||
            error.toString();
         return thunkAPI.rejectWithValue(message);
      }
   }
);

// REGISTER3
export const register3 = createAsyncThunk(
   'user/register3',
   async (user, thunkAPI) => {
      try {
         const token = Cookies.get('token');
         return await userService.register3(user, token);
      } catch (error) {
         const message =
            (error.response &&
               error.response.data &&
               error.response.data.message) ||
            error.message ||
            error.toString();
         return thunkAPI.rejectWithValue(message);
      }
   }
);

// REGISTER7
export const register7 = createAsyncThunk(
   'user/register7',
   async (user, thunkAPI) => {
      try {
         const token = Cookies.get('token');
         return await userService.register7(user, token);
      } catch (error) {
         const message =
            (error.response &&
               error.response.data &&
               error.response.data.message) ||
            error.message ||
            error.toString();
         return thunkAPI.rejectWithValue(message);
      }
   }
);

// REGISTER7
export const register15 = createAsyncThunk(
   'user/register15',
   async (user, thunkAPI) => {
      try {
         const token = Cookies.get('token');
         return await userService.register15(user, token);
      } catch (error) {
         const message =
            (error.response &&
               error.response.data &&
               error.response.data.message) ||
            error.message ||
            error.toString();
         return thunkAPI.rejectWithValue(message);
      }
   }
);

// UPDATE USER
export const updateUser = createAsyncThunk(
   'user/updateUser',
   async (userData, thunkAPI) => {
      try {
         const token = Cookies.get('token');
         return await userService.updateUser(userData, token);
      } catch (error) {
         const message =
            (error.response &&
               error.response.data &&
               error.response.data.message) ||
            error.message ||
            error.toString();
         return thunkAPI.rejectWithValue(message);
      }
   }
);

// UPDATE USER
export const updateUserToCenter = createAsyncThunk(
   'user/updateUserToCenter',
   async (id, thunkAPI) => {
      try {
         const token = Cookies.get('token');
         return await userService.userToCenter(id, token);
      } catch (error) {
         const message =
            (error.response &&
               error.response.data &&
               error.response.data.message) ||
            error.message ||
            error.toString();
         return thunkAPI.rejectWithValue(message);
      }
   }
);

// UPDATE PASSWORD
export const updatePassword = createAsyncThunk(
   'user/updatePassword',
   async (passwordData, thunkAPI) => {
      try {
         const token = Cookies.get('token');
         return await userService.updatePassword(passwordData, token);
      } catch (error) {
         const message =
            (error.response &&
               error.response.data &&
               error.response.data.message) ||
            error.message ||
            error.toString();
         return thunkAPI.rejectWithValue(message);
      }
   }
);

// GET UPLINE
export const getUpline = createAsyncThunk(
   'user/getUpline',
   async (id, thunkAPI) => {
      try {
         const token = Cookies.get('token');
         return await userService.getUpline(id, token);
      } catch (error) {
         const message =
            (error.response &&
               error.response.data &&
               error.response.data.message) ||
            error.message ||
            error.toString();
         return thunkAPI.rejectWithValue(message);
      }
   }
);

// GET NEW UPLINE
export const getNewUpline = createAsyncThunk(
   'user/getNewUpline',
   async (id, thunkAPI) => {
      try {
         const token = Cookies.get('token');

         return await userService.getNewUpline(id, token);
      } catch (error) {
         const message =
            (error.response &&
               error.response.data &&
               error.response.data.message) ||
            error.message ||
            error.toString();
         return thunkAPI.rejectWithValue(message);
      }
   }
);

// CHECK USER MEMBER LINE
export const checkMemberLine = createAsyncThunk(
   'user/checkMemberLine',
   async (dataId, thunkAPI) => {
      try {
         const token = Cookies.get('token');
         return await userService.checkMemberLine(dataId, token);
      } catch (error) {
         const message =
            (error.response &&
               error.response.data &&
               error.response.data.message) ||
            error.message ||
            error.toString();
         return thunkAPI.rejectWithValue(message);
      }
   }
);


const userSlice = createSlice({
   name: 'user',
   initialState,
   reducers: {
      reset: (state) => initialState,
   },
   extraReducers: (builder) => {
      builder
         .addCase(register.pending, (state) => {
            state.isLoading = true;
         })
         .addCase(register.fulfilled, (state, action) => {
            state.isLoading = false;
            state.registerSuccess = true;
            state.user = action.payload;
         })
         .addCase(register.rejected, (state, action) => {
            state.isLoading = false;
            state.isError = true;
            state.message = action.payload;
            state.user = null;
         })
         .addCase(register3.pending, (state) => {
            state.isLoading = true;
         })
         .addCase(register3.fulfilled, (state, action) => {
            state.isLoading = false;
            state.registerSuccess = true;
            state.user = action.payload;
         })
         .addCase(register3.rejected, (state, action) => {
            state.isLoading = false;
            state.isError = true;
            state.message = action.payload;
            state.user = null;
         })
         .addCase(register7.pending, (state) => {
            state.isLoading = true;
         })
         .addCase(register7.fulfilled, (state, action) => {
            state.isLoading = false;
            state.registerSuccess = true;
            state.user = action.payload;
         })
         .addCase(register7.rejected, (state, action) => {
            state.isLoading = false;
            state.isError = true;
            state.message = action.payload;
            state.user = null;
         })
         .addCase(register15.pending, (state) => {
            state.isLoading = true;
         })
         .addCase(register15.fulfilled, (state, action) => {
            state.isLoading = false;
            state.registerSuccess = true;
            state.user = action.payload;
         })
         .addCase(register15.rejected, (state, action) => {
            state.isLoading = false;
            state.isError = true;
            state.message = action.payload;
            state.user = null;
         })
         .addCase(getUsers.pending, (state) => {
            state.isLoading = true;
         })
         .addCase(getUsers.fulfilled, (state, action) => {
            state.isLoading = false;
            state.isSuccess = true;
            state.users = action.payload;
         })
         .addCase(getUsers.rejected, (state, action) => {
            state.isLoading = false;
            state.isError = true;
            state.message = action.payload;
         })
         .addCase(getAllUsers.pending, (state) => {
            state.isLoading = true;
         })
         .addCase(getAllUsers.fulfilled, (state, action) => {
            state.isLoading = false;
            state.isSuccess = true;
            state.users = action.payload;
         })
         .addCase(getAllUsers.rejected, (state, action) => {
            state.isLoading = false;
            state.isError = true;
            state.message = action.payload;
         })
         .addCase(getUsersByCardId.pending, (state) => {
            state.isLoading = true;
         })
         .addCase(getUsersByCardId.fulfilled, (state, action) => {
            state.isLoading = false;
            state.isSuccess = true;
            state.users = action.payload;
         })
         .addCase(getUsersByCardId.rejected, (state, action) => {
            state.isLoading = false;
            state.isError = true;
            state.message = action.payload;
         })
         .addCase(getNewUserForBinary.pending, (state) => {
            state.isLoading = true;
         })
         .addCase(getNewUserForBinary.fulfilled, (state, action) => {
            state.isLoading = false;
            state.isSuccess = true;
            state.users = action.payload;
         })
         .addCase(getNewUserForBinary.rejected, (state, action) => {
            state.isLoading = false;
            state.isError = true;
            state.message = action.payload;
         })
         .addCase(getUser.pending, (state) => {
            state.isLoading = true;
         })
         .addCase(getUser.fulfilled, (state, action) => {
            state.isLoading = false;
            state.isSuccess = true;
            state.user = action.payload;
         })
         .addCase(getUser.rejected, (state, action) => {
            state.isLoading = false;
            state.isError = true;
            state.message = action.payload;
         })
         .addCase(getDatafromCardId.pending, (state) => {
          //  state.isLoading = true;
         })
         .addCase(getDatafromCardId.fulfilled, (state, action) => {
            state.isLoading = false;
            state.isSuccess = true;
            state.userFormCardId = action.payload;
            state.userFormCardIdMessage = action.payload
         })
         .addCase(getDatafromCardId.rejected, (state, action) => {
            state.isLoading = false;
            state.userFormCardIdError = true;
            state.userFormCardIdMessage = action.payload;
         })
         .addCase(getUserByUserId.pending, (state) => {
            state.isLoading = true;
         })
         .addCase(getUserByUserId.fulfilled, (state, action) => {
            state.isLoading = false;
            state.isSuccess = true;
            state.user = action.payload;
         })
         .addCase(getUserByUserId.rejected, (state, action) => {
            state.isLoading = false;
            state.isError = true;
            state.message = action.payload;
         })
         .addCase(getUpTopIdUplineUser.pending, (state) => {
            state.isLoading = true;
         })
         .addCase(getUpTopIdUplineUser.fulfilled, (state, action) => {
            state.isLoading = false;
            state.getUpTopSuccess = true;
            state.UpTopIdUplineUser = action.payload;
         })
         .addCase(getUpTopIdUplineUser.rejected, (state, action) => {
            state.isLoading = false;
            state.isError = true;
            state.message = action.payload;
         })
         .addCase(updatePassword.pending, (state) => {
            state.isLoading = true;
         })
         .addCase(updatePassword.fulfilled, (state, action) => {
            state.isLoading = false;
            state.isUpdatePasswordSuccess = true;
            state.userInfo = action.payload;
         })
         .addCase(updatePassword.rejected, (state, action) => {
            state.isLoading = false;
            state.isError = true;
            state.message = action.payload;
         })
         .addCase(updateUser.pending, (state) => {
            state.isLoading = true;
         })
         .addCase(updateUser.fulfilled, (state, action) => {
            state.isLoading = false;
            state.isUpdateSuccess = true;
            state.userInfo = action.payload;
         })
         .addCase(updateUser.rejected, (state, action) => {
            state.isLoading = false;
            state.isError = true;
            state.message = action.payload;
         })
         .addCase(updateUserToCenter.pending, (state) => {
            state.isLoading = true;
         })
         .addCase(updateUserToCenter.fulfilled, (state, action) => {
            state.isLoading = false;
            state.isSuccess = true;
            state.user = action.payload;
         })
         .addCase(updateUserToCenter.rejected, (state, action) => {
            state.isLoading = false;
            state.isError = true;
            state.user = action.payload;
         })
         .addCase(getUpline.pending, (state) => {
            state.isLoading = true;
         })
         .addCase(getUpline.fulfilled, (state, action) => {
            state.isLoading = false;
            state.isSuccess = true;
            state.upline = action.payload;
         })
         .addCase(getUpline.rejected, (state, action) => {
            state.isLoading = false;
            state.isError = true;
            state.message = action.payload;
         })
         .addCase(getNewUpline.pending, (state) => {
            state.isLoading = true;
         })
         .addCase(getNewUpline.fulfilled, (state, action) => {
            state.isLoading = false;
            state.isSuccess = true;
            state.upline = action.payload;
         })
         .addCase(getNewUpline.rejected, (state, action) => {
            state.isLoading = false;
            state.isError = true;
            state.message = action.payload;
         })
         .addCase(checkMemberLine.pending, (state) => {
            state.isLoading = true;
         })
         .addCase(checkMemberLine.fulfilled, (state, action) => {
            state.isLoading = false;
            state.isSuccess = true;
            state.check = true;
         })
         .addCase(checkMemberLine.rejected, (state, action) => {
            state.isLoading = false;
            state.isError = true;
            state.check = false;
            state.message = action.payload;
         })
         .addCase(getCenters.pending, (state) => {
            state.isLoading = true;
         })
         .addCase(getCenters.fulfilled, (state, action) => {
            state.isLoading = false;
            state.isSuccess = true;
            state.centers = action.payload;
         })
         .addCase(getCenters.rejected, (state, action) => {
            state.isLoading = false;
            state.isSuccess = true;
            state.message = action.payload;
         })
         .addCase(getTotalLeft.pending, (state) => {
            state.isLoading = true;
         })
         .addCase(getTotalLeft.fulfilled, (state, action) => {
            state.isLoading = false;
            state.isSuccess = true;
            state.totalLeft = action.payload;
         })
         .addCase(getTotalLeft.rejected, (state, action) => {
            state.isLoading = false;
            state.isSuccess = true;
            state.message = action.payload;
         })
         .addCase(getTotalRight.pending, (state) => {
            state.isLoading = true;
         })
         .addCase(getTotalRight.fulfilled, (state, action) => {
            state.isLoading = false;
            state.isSuccess = true;
            state.totalRight = action.payload;
         })
         .addCase(getTotalRight.rejected, (state, action) => {
            state.isLoading = false;
            state.isSuccess = true;
            state.message = action.payload;
         })
         .addCase(getOldTotalBonusBinary.pending, (state) => {
            state.isLoading = true;
         })
         .addCase(getOldTotalBonusBinary.fulfilled, (state, action) => {
            state.isLoading = false;
            state.isSuccess = true;
            state.oldBonus = action.payload;
         })
         .addCase(getOldTotalBonusBinary.rejected, (state, action) => {
            state.isLoading = false;
            state.isSuccess = true;
            state.message = action.payload;
         });
         
   },
});
export const { reset } = userSlice.actions;
export default userSlice.reducer;
