import { Button, Image, List, Modal, Space, Table, Tag } from 'antd';
import React, { useEffect, useState } from 'react';
import DefaultLayout from '../components/DefaultLayout';
import Loading from '../components/Loading';
import { Container } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import {
   getAllDeposits,
   getUserDepositsById,
} from '../features/deposit/depositSlice';
import moment from 'moment';
import commaNumber from 'comma-number';

const HistoryDepositPage = () => {
   const [modalProducts, setModalProducts] = useState(false);
   const [totalProducts, setTotalProducts] = useState(null);

   const [loadingPage, setLoadingPage] = useState(true);

   const { userInfo } = useSelector((state) => state.auth);

   const { deposits } = useSelector((state) => state.deposit);

   const dispatch = useDispatch();

   useEffect(() => {
      if (loadingPage) {
         setTimeout(() => {
            setLoadingPage(false);
         }, 1500);
      }

      dispatch(getUserDepositsById(userInfo._id));
   }, [dispatch]);

   const columns = [
      {
         title: 'ลำดับ',
         dataIndex: 'depth',
         key: 'depth',
         render: (text, record, index) => <span>{index + 1}</span>,
      },
      {
         title: 'วันที่',
         dataIndex: 'createdAt',
         key: 'createdAt',
         render: (value) => (
            <span>
               <b> {moment(value).format('DD/MM/YYYY HH:mm:ss')}</b>
            </span>
         ),
         sorter: (a, b) =>
            moment(a.createdAt).unix() - moment(b.createdAt).unix(),
      },
      {
         title: 'ยอดเงิน',
         dataIndex: 'amount',
         key: 'amount',
         render: (value) => (
            <>
               <span>
                  <b>{commaNumber(value)}</b>
               </span>
            </>
         ),
      },
      {
         title: 'จำนวน PV',
         dataIndex: 'totalPv',
         key: 'totalPv',
         render: (value) => (
            <>
               <span>
                  <b>{commaNumber(value)}</b>
               </span>
            </>
         ),
      },
      {
         title: 'สถานะ',
         dataIndex: 'status',
         key: 'status',
      },

      {
         title: 'หมายเหตุ',
         dataIndex: 'totalProduct',
         key: 'totalProduct',
         render: (totalProduct, record) => (
            <>
               <Button
                  type='primary'
                  onClick={() => {
                     setTotalProducts(totalProduct);
                     setModalProducts(true);
                  }}
               >
                  สินค้า
               </Button>
            </>
         ),
      },
   ];

   return (
      <DefaultLayout>
         {loadingPage ? (
            <>
               <Loading />{' '}
            </>
         ) : (
            <>
               <Container>
                  <div className='my-5'>
                     {' '}
                     <h3>
                        <b>ประวัติการเติม PV</b>
                     </h3>
                     {/* <RangePicker picker='week' /> */}
                  </div>
                  <Table
                     columns={columns}
                     dataSource={deposits}
                     scroll={{
                        x: 500,
                     }}
                  />
               </Container>
               <Modal
                  title='รายการสินค้า'
                  width={800}
                  visible={modalProducts}
                  onCancel={() => {
                     setModalProducts(false);
                  }}
                  footer={false}
               >
                  <div>
                     <List
                        header={<strong>รายการสินค้าที่สั่งซื้อ</strong>}
                        bordered
                        dataSource={totalProducts}
                        renderItem={(item) => (
                           <List.Item>
                              <>
                                 <span>{item.name}</span> :{' '}
                                 <span>{item.amount} ชิ้น</span>
                              </>{' '}
                           </List.Item>
                        )}
                     />
                  </div>
               </Modal>
            </>
         )}
      </DefaultLayout>
   );
};

export default HistoryDepositPage;
