import { Statistic, Table, DatePicker } from 'antd';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import DefaultLayout from '../components/DefaultLayout';
import Loading from '../components/Loading';
import { getByMonth, getEwallet } from '../features/ewallet/ewalletSlice';

const { MonthPicker } = DatePicker;

const SummaryWalletMonth = () => {
   const dispatch = useDispatch();

   const { userInfo } = useSelector((state) => state.auth);
   const { ewallets, isLoading, isError, message } = useSelector(
      (state) => state.ewallet
   );
   const [ewalletData, setEwalletData] = useState([]);
   console.log(ewallets);

   let ewallets2 = null;
   if (ewallets.length > 0) {
      ewallets2 = ewallets.flat();
   }
   console.log(ewallets2);

   console.log(ewalletData);

   // let rw2 = null;
   // if (ewallets) {
   //    rw2 = ewallets.map((e) => e.action);
   //    rw2 = rw2.flat();
   // }
   // console.log(rw2);

   const [loadingPage, setLoadingPage] = useState(true);

   useEffect(() => {
      if (loadingPage) {
         setTimeout(() => {
            setLoadingPage(false);
         }, 1500);
      }
      dispatch(getByMonth());

      if (ewallets2 && ewallets2.length > 0) {
         setEwalletData(ewallets2);
      }
   }, []);

   const columns = [
      // {
      //    title: 'วันที่',
      //    dataIndex: ['transfer,recieve'],
      //    key: 'date',
      //    render: (_, row) => (
      //       <>
      //          {row['transfer'].length > 0 ? (
      //             <>
      //                <p>{row['transfer'].map((e) => e.date)}</p>
      //             </>
      //          ) : (
      //             <>
      //                <p>{row['recieve'].map((e) => e.date)}</p>
      //             </>
      //          )}
      //       </>
      //    ),
      // },
      // {
      //    title: 'รายการ',
      //    dataIndex: ['transfer,recieve'],
      //    key: 'date',
      //    render: (_, row) => (
      //       <>
      //          {row['transfer'].length > 0 ? (
      //             <>
      //                โอนให้
      //                <p>{row['transfer'].map((e) => e.date)}</p>
      //             </>
      //          ) : (
      //             <>
      //                รับโอน
      //                <p>{row['recieve'].map((e) => e.admin)}</p>
      //             </>
      //          )}
      //       </>
      //    ),
      // },
      // {
      //    title: 'วันที่',
      //    dataIndex: 'action',
      //    key: 'action',
      //    render: (action) => <p> {action.map((e) => e.date)} </p>,
      // },
      {
         title: 'วันที่',
         dataIndex: 'date',
         key: 'date',
         render: (text) => (
            <div>
               <p> {moment(text).format('D/M/YYYY')} </p>
               <p> {moment(text).format('h:mm:ss a')} </p>
            </div>
         ),
      },
      {
         title: 'รายการ',
         dataIndex: ['userTransfer', 'userRecieve', 'note'],
         render: (_, row) => (
            <>
               {row['userTransfer'] ? (
                  <>
                     <p className='text-danger'>
                        {' '}
                        {row['note']} - {row['userTransfer'].userId}{' '}
                     </p>
                     <p className='text-info'> {row['userTransfer'].name} </p>
                  </>
               ) : (
                  <>
                     {row['userRecieve'] ? (
                        <>
                           <p>
                              {' '}
                              {row['note']} - {row['userRecieve'].userId}{' '}
                           </p>
                           <p className='text-info'>
                              {' '}
                              {row['userRecieve'].name}{' '}
                           </p>
                        </>
                     ) : null}
                  </>
               )}
            </>
         ),
      },
      {
         title: 'ยอด/คงเหลือ',
         dataIndex: ['amount', 'previosAmount', 'transfer'],
         render: (_, row) => (
            <>
               {row['transfer'] === true ? (
                  <>
                     <p className='text-danger'>
                        {' '}
                        - ฿{row['amount'].toLocaleString('en')}{' '}
                     </p>
                     <p> ฿{row['previosAmount'].toLocaleString('en')}</p>
                     <b style={{ color: 'green !important' }}>
                        <Statistic
                           value={row['previosAmount'] - row['amount']}
                        />
                     </b>
                  </>
               ) : (
                  <>
                     <p className='text-info'>
                        {' '}
                        + ฿{row['amount'].toLocaleString('en')}{' '}
                     </p>
                     <p> ฿{row['previosAmount'].toLocaleString('en')} </p>
                     <b className='text-success'>
                        <Statistic
                           value={row['amount'] + row['previosAmount']}
                        />
                     </b>
                  </>
               )}
               {/* <p> ฿{row['previosAmount']}</p> */}
            </>
         ),
      },
   ];

   const onChange = (date, dateString) => {
      const ew2 = ewallets2.filter(
         (e) =>
            moment(e.date).format('YYYY-M') ===
            moment(dateString).format('YYYY-M')
      );
      setEwalletData(ew2);
   };

   return (
      <DefaultLayout>
         {loadingPage ? (
            <>
               <Loading />{' '}
            </>
         ) : (
            <>
               {ewalletData.length > 1 ? (
                  <>
                     <div className='d-flex justify-content-between'>
                        {' '}
                        Center สรุป Wallet รายเดือน
                        <MonthPicker onChange={onChange} />
                     </div>
                     <br />
                     <Table columns={columns} dataSource={ewalletData} />
                  </>
               ) : (
                  <>
                     <div className='d-flex justify-content-between'>
                        {' '}
                        Center สรุป Wallet รายเดือน
                        <MonthPicker onChange={onChange} />
                     </div>
                     <p>ไม่พบข้อมูล</p>
                  </>
               )}
            </>
         )}
      </DefaultLayout>
   );
};

export default SummaryWalletMonth;
