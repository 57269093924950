import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import DefaultLayout from '../components/DefaultLayout';
import { createCenter, getCenters } from '../features/center/centerSlice';
import {
   Button,
   Card,
   Col,
   List,
   Row,
   Modal,
   message,
   Input,
   Form,
} from 'antd';
import { FiHome } from 'react-icons/fi';
import { Container } from 'react-bootstrap';
import { BsTelephoneFill } from 'react-icons/bs';
import { MdDriveFileRenameOutline, MdNoEncryption } from 'react-icons/md';
import AddressFormTypeahead from 'react-thailand-address-typeahead';
import Loading from '../components/Loading';

const CenterPage = () => {
   const navigate = useNavigate();
   const dispatch = useDispatch();

   const [modalOpen, setModalOpen] = useState(false);
   const [addressObj, setAddressObj] = useState({});

   const loading = true;

   const { userInfo } = useSelector((state) => state.auth);
   const {
      centers,
      isLoading,
      isSuccess,
      isError,
      message: messageShow,
   } = useSelector((state) => state.centers);

   const onFinish = (values) => {
      let data = {
         ...values,
         ...addressObj,
      };
      dispatch(createCenter(data));

      if (isSuccess) {
         message.success('login Success');
         setModalOpen(false);
      }

      if (isError) message.error(messageShow);
   };

   useEffect(() => {
      dispatch(getCenters());
   }, [dispatch, isError]);

   return (
      <DefaultLayout>
         {isLoading ? (
            <>
               <Loading />
            </>
         ) : (
            <>
               <Container>
                  <Button
                     className='my-3'
                     shape='round'
                     onClick={() => setModalOpen(true)}>
                     {' '}
                     สร้างเซ็นเตอร์{' '}
                  </Button>
                  <br />

                  <div>
                     <List
                        grid={{
                           gutter: [24, 20],
                           column: 4,
                           lg: 3,
                           md: 2,
                           xs: 1,
                           sm: 1,
                        }}
                        dataSource={centers}
                        renderItem={(item) => (
                           <List.Item>
                              <Card
                                 onClick={() =>
                                    navigate('/center/:id', {
                                       state: {
                                          centerId: item._id,
                                       },
                                    })
                                 }
                                 className=' text-center shadow p-3 mb-5 bg-white rounded'
                                 bordered={false}
                                 title={<FiHome />}>
                                 <h2> {item.name}</h2>
                              </Card>
                           </List.Item>
                        )}
                     />
                  </div>
               </Container>
            </>
         )}

         {modalOpen && (
            <Modal
               width={700}
               title={'สร้างเซ็นเตอร์'}
               visible={modalOpen}
               footer={false}
               onCancel={() => {
                  setModalOpen(false);
               }}>
               <Form className='login-form' onFinish={onFinish}>
                  <Form.Item
                     name='name'
                     rules={[
                        {
                           required: true,
                           message: 'กรุณากรอกชื่อเซ็นเตอร์',
                        },
                     ]}>
                     <Input
                        prefix={
                           <MdDriveFileRenameOutline className='site-form-item-icon' />
                        }
                        placeholder='ชื่อเซ็นเตอร์'
                     />
                  </Form.Item>
                  <Form.Item
                     name='number'
                     rules={[
                        {
                           required: true,
                           message: 'กรุณากรอกเบอร์โทรติดต่อเซ็นเตอร์',
                        },
                     ]}>
                     <Input
                        prefix={
                           <BsTelephoneFill className='site-form-item-icon' />
                        }
                        placeholder='เบอร์โทร'
                     />
                  </Form.Item>
                  <Form.Item
                     name='password'
                     rules={[
                        {
                           required: true,
                           message: 'กรุณากรอกรหัสเข้าใช้เซ็นเตอร์',
                        },
                     ]}>
                     <Input
                        prefix={
                           <MdNoEncryption className='site-form-item-icon' />
                        }
                        placeholder='รหัสเข้าใช้เซ็นเตอร์'
                     />
                  </Form.Item>
                  <Form.Item name='address'>
                     <Input.TextArea placeholder='ที่อยู่' />
                  </Form.Item>
                  <AddressFormTypeahead
                     onAddressSelected={(addressObject) =>
                        setAddressObj(addressObject)
                     }
                  />{' '}
                  <br />
                  <Form.Item>
                     <Button
                        type='primary'
                        htmlType='submit'
                        className='login-form-button'>
                        SAVE
                     </Button>
                  </Form.Item>
               </Form>
            </Modal>
         )}
      </DefaultLayout>
   );
};

export default CenterPage;
