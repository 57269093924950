import React, { useState } from 'react'
import DatePicker from 'react-datepicker';
import moment from 'moment';
import 'react-datepicker/dist/react-datepicker.css';
import { Button } from 'antd';

moment.updateLocale('th', {
    week: {
      dow: 1, // Monday is the first day of the week
      doy: 1,
    },
  });

const WeekPicker = ({onWeekChange }) => {
    const [startDate, setStartDate] = useState(moment().startOf('week').toDate());
    const [endDate, setEndDate] = useState(moment().endOf('week').toDate());
    const [hoveredDate, setHoveredDate] = useState(null);

    const [isOpen, setIsOpen] = useState(false);
  

    const handleChange = date => {
      const startOfWeek = moment(date).startOf('week').toDate();
      const endOfWeek = moment(date).endOf('week').toDate();
      setStartDate(startOfWeek);
      setEndDate(endOfWeek);
      onWeekChange({ startOfWeek, endOfWeek });
    };
  
    const handleMouseEnter = date => {
      setHoveredDate(date);
    };
  
    const handleMouseLeave = () => {
      setHoveredDate(null);
    };
  
    const renderCustomHeader = ({
      date,
      decreaseMonth,
      increaseMonth,
    }) => (
      <div className="custom-header">
        <button onClick={decreaseMonth}>{"<"}</button>
        <span>{moment(date).format('MMMM YYYY')}</span>
        <button onClick={increaseMonth}>{">"}</button>
      </div>
    );
  
    const highlightWeek = (date) => {
      const startOfWeek = moment(startDate).startOf('week');
      const endOfWeek = moment(startDate).endOf('week');
      return date >= startOfWeek.toDate() && date <= endOfWeek.toDate();
    };
  
    const hoverHighlightWeek = (date) => {
      if (!hoveredDate) return false;
      const startOfWeek = moment(hoveredDate).startOf('week');
      const endOfWeek = moment(hoveredDate).endOf('week');
      return date >= startOfWeek.toDate() && date <= endOfWeek.toDate();
    };

    const handleClick = (e) => {
      e.preventDefault();
      setIsOpen(!isOpen);
    };
  
    return (
    <>
      <DatePicker
      
      startDate={startDate}
      
      endDate={endDate}
        //selected={startDate}
        onChange={handleChange}
        renderCustomHeader={renderCustomHeader}
        dateFormat="dd/MM/yyyy"
        showMonthDropdown
        useShortMonthInDropdown
        customInput={
          // <input type="text" value={`${moment(startDate).format('MM/DD/YYYY')} - ${moment(endDate).format('MM/DD/YYYY')}`} />
          <Button type='primary'> เลือกวัน </Button>
        }
        minDate={moment().startOf('year').toDate()}
        maxDate={moment().endOf('year').toDate()}
        highlightDates={[
          {
            "react-datepicker__day--highlighted-week": [...Array(7).keys()].map(i =>
              moment(startDate).startOf('week').add(i, 'days').toDate()
            ),
          },
        ]}
        dayClassName={date => highlightWeek(date) ? "highlight-week" : hoverHighlightWeek(date) ? "hover-highlight-week" : undefined}
        calendarStartDay={1} // Start the week with Monday
        onDayMouseEnter={handleMouseEnter}
        onDayMouseLeave={handleMouseLeave}
        placeholderText='กรุณาเลือกสัปดาห์ที่ต้องการแสดงโบนัส'
      />
    </>
    );
  };
  
export default WeekPicker