import { Badge, Card, Col, Row, Table, Tag } from 'antd';
import React, { useEffect } from 'react';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import DefaultLayout from '../components/DefaultLayout';
import { getOrderById, getOrders } from '../features/order/orderSlice';
import { Container } from 'react-bootstrap';
import moment from 'moment';

const OrdersPage = () => {
   const dispatch = useDispatch();
   const navigate = useNavigate();

   const { userInfo } = useSelector((state) => state.auth);
   const { orders, isSuccess, isLoading, isError } = useSelector(
      (state) => state.order
   );

   useEffect(() => {
      dispatch(getOrderById(userInfo._id));
   }, [dispatch]);

   const columns = [
      {
         title: 'เลขที่อ้างอิง',
         dataIndex: 'refNumber',
         key: 'refNumber',
         render: (text, record) => (
            <Link
               to={`/orders/orderDetail/${record._id}`}
               // onClick={() =>
               //    navigate('orderDetail', {
               //       state: {
               //          data: record,
               //       },
               //    })
               // }
            >
               <b>{text} </b>
            </Link>
         ),
      },

      {
         title: 'สมาชิก',
         dataIndex: 'userOrder',
         render: (userOrder) => (
            <div className='d-flex flex-column text-center'>
               <div
                  style={{
                     backgroundColor: '#ababab',
                     width: '70%',
                     padding: '10px',
                     borderRadius: '20px',
                  }}
               >
                  <span>{userOrder.name}</span>
               </div>
               <span className='text-start' style={{ color: '#1890ff' }}>
                  {' '}
                  <span>รหัสสมาชิก : </span> <b> {userOrder.userId}</b>{' '}
               </span>
            </div>
         ),
      },
      // {
      //    title: 'สมาชิก',
      //    dataIndex: 'user',
      //    render: (user) => (
      //       <div>
      //          <span> {user.userId} </span>
      //       </div>
      //    ),
      // },
      {
         title: 'วันที่',
         dataIndex: 'createdAt',
         key: 'createdAt',
         render: (createdAt) => (
            <div> {createdAt.toString().substring(0, 10)} </div>
         ),
      },
      // {
      //    title: 'ยอดเงิน/PV',
      //    dataIndex: 'orderItems',
      //    key: 'orderItems',

      //    render: (orderItems) =>
      //       orderItems.map((order) => {
      //          return (
      //             <>
      //                <p>{order.price} ฿</p>
      //                <p style={{ color: 'blue' }}>{order.pv} PV</p>
      //             </>
      //          );
      //       }),
      // },
      // {
      //    title: 'ยอดเงิน/PV',
      //    dataIndex: ['totalPrice', 'totalPv'],
      //    key: 'totalprice',
      //    render: (text, row) => <p>{row['totalprice']} </p>,
      // },
      {
         title: 'ยอดเงิน/PV',
         dataIndex: 'totalPv',
         key: 'totalPv',
      },
      {
         title: 'ประเภท',
         dataIndex: 'note',
         key: 'note',
      },
      {
         title: 'เลขที่เอกสาร',
         dataIndex: 'orderNumber',
         key: 'orderNumber',
         render: (text, record) => (
            <Link
               to={`/orders/orderDetail/${record._id}`}
               // onClick={() =>
               //    navigate('orderDetail', {
               //       state: {
               //          data: record,
               //       },
               //    })
               // }
            >
               <b>{text} </b>
            </Link>
         ),
      },
   ];

   console.log(orders);

   return (
      <DefaultLayout>
         <Container>
            <div className='my-5'>
               <h3>
                  <b>รายการสั่งซื้อ</b>
               </h3>

               <div>
                  <Row gutter={[10, 10]}>
                     {orders.map((e) => {
                        return (
                           <Col sm={24} xs={24} md={12} lg={8}>
                              <Card
                              className='mx-auto'
                                 style={{
                                    width: 400,
                                    borderRadius: '20px',
                                  
                                 }}
                              >
                                 <div className='d-flex flex-row justify-content-between'>
                                    <div>
                                       <span>
                                          เลขที่อ้างอิง{' '}
                                          <span>
                                             <span style={{ fontSize: '20px' }}>
                                                <b>{e.refNumber}</b>
                                             </span>
                                          </span>
                                       </span>
                                       <p style={{ fontSize: '12px' }}>
                                          {moment(e.createdAt).format(
                                             'DD/MM/YYYY'
                                          )}
                                       </p>
                                       <br />
                                       <br />
                                       <p>
                                          <b>{e.user.name}</b>
                                       </p>
                                       <p>
                                          <b>{e.user.userId}</b>
                                       </p>
                                    </div>
                                    <div>
                                       {e.totalPv === 500 ? (
                                          <h5>
                                             <b>1 Holder</b>
                                          </h5>
                                       ) : (
                                          <h5>
                                             <b>1 Member</b>
                                          </h5>
                                       )}
                                       <p>
                                          <b>{e.note}</b>
                                       </p>
                                       <br />
                                       <br />
                                       <p>
                                          <b>{e.totalPv}PV</b>
                                       </p>
                                    </div>
                                 </div>
                              </Card>
                           </Col>
                        );
                     })}
                  </Row>
               </div>
               {/* {orders ? (
            <Table columns={columns} dataSource={orders} />
         ) : (
            <>
               <h1> NO ORDERS</h1>
            </>
         )} */}
            </div>
         </Container>
      </DefaultLayout>
   );
};

export default OrdersPage;
