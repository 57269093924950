import {
   Alert,
   Button,
   Form,
   Input,
   InputNumber,
   Modal,
   Popconfirm,
} from 'antd';
import React, { useEffect, useState } from 'react';
import { Container } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import DefaultLayout from '../components/DefaultLayout';
import SearchInputForEwallet from '../components/form/SearchInputForEwallet';
import Loading from '../components/Loading';
import { getEwallet, updateEwallet } from '../features/ewallet/ewalletSlice';

const TransferEwallet = () => {
   const dispatch = useDispatch();
   const { userInfo } = useSelector((state) => state.auth);
   const { ewallet, isLoading, isError, message } = useSelector(
      (state) => state.ewallet
   );

   const [recommendedBy, setRecommendedBy] = useState('');

   const recId = localStorage.getItem('recomId');
   const recName = localStorage.getItem('recomName');

   const [modalOpen, setModalOpen] = useState(false);

   const [loadingPage, setLoadingPage] = useState(true);

   const sendDataToParent = (i) => {
      setRecommendedBy(i.userId);
   };

   const [data, setData] = useState({
      eWallet: 0,
      id: '',
   });

   useEffect(() => {
      if (loadingPage) {
         setTimeout(() => {
            setLoadingPage(false);
         }, 1500);
      }

      dispatch(getEwallet());
   }, [dispatch]);

   let eWallet = null;

   if (ewallet.length > 0) {
      eWallet = ewallet.map((e) => e.eWallet);
      eWallet = parseInt(eWallet);
   }

   const onFinish = (values) => {
      values.id = recId;

      if (values) {
         setData({ eWallet: values.eWallet, id: recId });
      }
      // console.log(userInfo.eWallet.eWallet);
      // console.log(values.eWallet);

      if (values.eWallet > eWallet) {
         window.alert('ไม่สามารถทำการโอนได้ ยอด wallet ไม่พอ');
         // dispatch(updateEwallet(values));
         // window.location.reload();
      }

      console.log(values);

      setModalOpen(true);
      // dispatch(updateEwallet(values));

      //   window.location.reload();

      //  dispatch(updateEwallet(values));
   };

   const handleConfirm = (e) => {
      if (data.eWallet > eWallet) {
         window.alert('ไม่สามารถทำการโอนได้ ยอด wallet ไม่พอ');
      }

      dispatch(updateEwallet(data));

      // window.location.reload();
   };

   return (
      <DefaultLayout>
         {loadingPage ? (
            <>
               <Loading />{' '}
            </>
         ) : (
            <>
               {' '}
               <Container>
                  <div className=' mt-5 form-block'>
                     {isError && (
                        <Alert
                           message={message}
                           type='error'
                           closable
                           showIcon
                        />
                     )}
                     <br />

                     <h3 className='text-center'>โอน WALLET</h3>
                     <Form
                        name='basic'
                        labelCol={{
                           span: 6,
                        }}
                        wrapperCol={{
                           span: 18,
                        }}
                        initialValues={{
                           remember: true,
                        }}
                        onFinish={onFinish}
                        autoComplete='off'>
                        {/* <Form.Item
                           label='สมาชิก'
                           name='userId'
                           rules={[
                              {
                                 required: true,
                                 message: 'กรุณากรอกรหัสสมาชิก!',
                              },
                           ]}>
                           <Input
                              type='number'
                              placeholder='กรอกรหัสสมาชิก'
                              onChange={(e) => setUserId(e.target.value)}
                           />
                        </Form.Item> */}

                        <Form.Item
                           label={
                              <Button
                                 type='danger'
                                 onClick={() => {
                                    localStorage.removeItem('recomId');
                                    localStorage.removeItem('recomName');
                                    window.location.reload();
                                 }}>
                                 X
                              </Button>
                           }>
                           {/* <SearchInput
                                 upLineData={recId}
                                 value={recommendedBy}
                                 sendDataToParent={sendDataToParent}
                              /> */}
                           <SearchInputForEwallet
                              upLineData={recId}
                              value={recommendedBy}
                              sendDataToParent={sendDataToParent}
                           />
                        </Form.Item>

                        <Form.Item label='eWallet ที่โอนได้'>
                           <Input disabled placeholder={` ${eWallet} ฿`} />
                        </Form.Item>

                        {recId ? (
                           <Form.Item
                              label='eWallet'
                              name='eWallet'
                              rules={[
                                 {
                                    required: true,
                                    message: 'กรอกณากรอกจำนวนเงิน!',
                                 },
                              ]}>
                              <InputNumber />
                           </Form.Item>
                        ) : null}

                        <Form.Item
                           wrapperCol={{
                              offset: 8,
                              span: 16,
                           }}>
                           <Button
                              type='primary'
                              // onClick={() => setModalOpen(true)}
                              htmlType='submit'>
                              ยืนยัน
                           </Button>
                        </Form.Item>
                     </Form>
                  </div>
               </Container>
               <Modal
                  title='ยืนยันการโอน eWallet'
                  visible={modalOpen}
                  footer={false}
                  onCancel={() => {
                     setModalOpen(false);
                  }}>
                  <h5> ยืนยันการโอน </h5>
                  <br />
                  <div>
                     <Button
                        block
                        type='primary'
                        htmlType='submit'
                        onClick={handleConfirm}>
                        ยืนยัน
                     </Button>
                     <br />
                     <br />
                     <Button
                        block
                        type='danger'
                        onClick={() => setModalOpen(false)}>
                        ยกเลิก
                     </Button>{' '}
                  </div>
               </Modal>
            </>
         )}
      </DefaultLayout>
   );
};

export default TransferEwallet;
