import React, { useEffect } from 'react'
import DefaultLayout from '../components/DefaultLayout'
import { Table } from 'antd'
import { useDispatch, useSelector } from 'react-redux';
import { getAllUsers ,reset} from '../features/users/usersSlice';
import commaNumber from 'comma-number'
import { Container } from 'react-bootstrap';
import Loading from '../components/Loading';

const UsersDetail = () => {

    const dispatch = useDispatch();

    const { userInfo } = useSelector((state) => state.auth);
    
   const { users, oldBonus, upline, isLoading } = useSelector((state) => state.user);

   useEffect(() => {

    dispatch(getAllUsers())

    return () => {
        dispatch(reset())
    }

   },[dispatch])

   const columns = [
    {
        title: 'ลำดับ',
        dataIndex: 'depth',
        key: 'depth',
        render: (text, record, index) => <span>{index + 1}</span>,
     },
    {
        title: 'รหัสสมาชิก',
        dataIndex: 'userId',
        render: (value) => (
            <b> {value} </b>
        )
    },
    {
        title: 'รหัส',
        dataIndex: 'role',
        render: (value) => (
            <b> {value} </b>
        )
    },
    {
        title: 'อายุรหัส',
        dataIndex: 'expired',
        render: (value) => (
            <b> {value} </b>
        )
    },
{
    title: 'ยอดโบนัส',
    dataIndex: 'totalAmount',
    render: (value) => (
        <b className='text-center'>  {commaNumber(value)}</b>
    )
}
   ]

  return (
   <DefaultLayout>
 <Container>
 <div className='my-5'>
               {' '}
               <h3>
                  <b>สรุปค่าคอมมิชชั่น</b>
               </h3>
               {/* <RangePicker picker='week' /> */}
            </div>
    {isLoading ? (
        <>
        <Loading />
        </>
    ) : (
        <>
           <div>
        <Table columns={columns} dataSource={users} />
    </div>
        </>
    )}
 </Container>
   </DefaultLayout>
  )
}

export default UsersDetail