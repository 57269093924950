import { Card, Col, Image, List, Row } from 'antd';
import React, { useEffect } from 'react';
import { Container } from 'react-bootstrap';
import { useLocation, useParams } from 'react-router-dom';
import DefaultLayout from '../components/DefaultLayout';
import moment from 'moment'
import { getOrderDetailById } from '../features/order/orderSlice';
import { useDispatch, useSelector } from 'react-redux';

const OrderDetail = () => {
   const location = useLocation();
   const {id} = useParams()
   const dispatch =useDispatch()

   console.log('p: ',id);

   const { state } = location;

   // const { user } = state.data;

   const { order, isSuccess, isLoading, isError } = useSelector(
      (state) => state.order
   );

   console.log('or: ', order);

   useEffect(() => {
      dispatch(getOrderDetailById(id));
   }, [dispatch]);


   return (
      <DefaultLayout>
         <>
            <Container className='my-5'>
               <h3 className='text-center text-start mb-5'> <b>รายละเอียดการสั่งซื้อ</b> </h3>
               <div>
                  <Row className='justify-content-center'>
                     <Col sm={24} xs={24} md={12} lg={12} xl={12}>
                        <div>
                           <h5> ที่อยู่จัดส่ง </h5>
                           <h5> วันที่ {moment(order.createdAt).format('DD/MM/YYYY')} </h5>
                        </div>
                     </Col>
                     <Col span={12}>
                        <div>
                           <h5>เลขที่อ้างอิง: {
                           order.orderNumber}</h5>
                           <h5></h5>
                        </div>
                     </Col>
                  </Row>

                  <Row gutter={[16, 16]}>
                     <Col sm={24} xs={24} md={12} lg={12} xl={12}>
                        <Card bordered={false}>
                  
                           <p> {order.user?.address?.address} </p>
                           <p> {order.user?.address?.district} </p>
                           <p> {order.user?.address?.subdistrict} </p>
                           <p> {order.user?.address?.province} </p>
                           <p> {order.user?.address?.postalCode} </p>
                        </Card>

                        <br />
                        <div className='text-center'>
                           <List
                              style={{ backgroundColor: 'white' }}
                              header={
                                 <>
                                    {' '}
                                    <div className='d-flex justify-content-around'>
                                       <h5>สินค้า</h5>
                                       <h5>ราคา</h5>
                                       <h5>คะแนน</h5>
                                    </div>{' '}
                                 </>
                              }
                              footer={<div></div>}
                              bordered
                              dataSource={
                                 order.orderItems}
                              renderItem={(item) => (
                                 <List.Item key={item._id}>
                                    <List.Item.Meta
                                       avatar={
                                          //   <Avatar

                                          //      size={100}
                                          //      shape='square'
                                          //      src={item.product.image}
                                          //   />
                                          <>
                                             <Image
                                                preview={false}
                                                width={100}
                                                src={item.image}
                                             />
                                             <h5>{item.name}</h5>
                                          </>
                                       }
                                       description={
                                          <div
                                             className='d-flex justify-content-around'
                                             style={{ color: 'black' }}>
                                             <p>
                                                {' '}
                                                {item.price} ฿ <br />{' '}
                                                {item.cartQuantity} SET{' '}
                                             </p>

                                             <p>{item.pv} PV</p>
                                          </div>
                                       }
                                    />
                                 </List.Item>
                              )}></List>
                        </div>
                     </Col>
                     <Col sm={24} xs={24} md={12} lg={12} xl={12}>
                        <Card
                           bordered={false}
                           style={{
                              width: '100%',
                           }}>
                           <List
                              grid={{
                                 gutter: 16,
                                 column: 4,
                              }}>
                              <List.Item>
                                 {' '}
                                 <Row>
                                    <Col span={12}>
                                       <h5>
                                          {' '}
                                          ยอดรวม({
                                             
                                             order.totalItems
                                          })ชิ้น{' '}
                                       </h5>
                                    </Col>
                                    <Col span={12}>
                                       {' '}
                                       <h5>
                                          {' '}
                                          <b>
                                             {' '}
                                             {
                                             order.totalPrice} ฿{' '}
                                          </b>{' '}
                                       </h5>{' '}
                                    </Col>
                                 </Row>{' '}
                              </List.Item>
                              <List.Item>
                                 {' '}
                                 <Row>
                                    <Col span={12}>
                                       <h5> ค่าส่ง</h5>
                                    </Col>
                                    <Col span={12}>
                                       {' '}
                                       <h5>
                                          {' '}
                                          <b> 0 ฿ </b>{' '}
                                       </h5>{' '}
                                    </Col>
                                 </Row>{' '}
                              </List.Item>
                              <List.Item>
                                 {' '}
                                 <Row>
                                    <Col span={12}>
                                       <h5> รวมทั้งสิ้น</h5>
                                    </Col>
                                    <Col span={12}>
                                       {' '}
                                       <h5>
                                          {' '}
                                          <b> 0 ฿ </b>{' '}
                                       </h5>{' '}
                                    </Col>
                                 </Row>{' '}
                              </List.Item>
                              <List.Item>
                                 {' '}
                                 <Row>
                                    <Col span={12}>
                                       <h5> คะแนน</h5>
                                    </Col>
                                    <Col span={12}>
                                       {' '}
                                       <h5>
                                          {' '}
                                          <b> {order.totalPv} PV </b>{' '}
                                       </h5>{' '}
                                    </Col>
                                 </Row>{' '}
                              </List.Item>
                           </List>
                        </Card>
                     </Col>
                  </Row>
               </div>
            </Container>
         </>
      </DefaultLayout>
   );
};

export default OrderDetail;
