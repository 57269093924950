import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import depositService from './depositService';
import Cookies from 'js-cookie';

const initialState = {
   deposits: [],
   deposit: {},
   isError: false,
   isSuccess: false,
   isLoading: false,
   message: '',
};

// CREATE DEPOSIT
export const createDeposit = createAsyncThunk(
   'deposit/createDeposit',
   async (data, thunkAPI) => {
      try {
         const token = Cookies.get('token');
         return await depositService.createDeposit(data, token);
      } catch (error) {
         const message =
            (error.response &&
               error.response.data &&
               error.response.data.message) ||
            error.message ||
            error.toString();
         return thunkAPI.rejectWithValue(message);
      }
   }
);

// GET ALL DEPOSITS
export const getAllDeposits = createAsyncThunk(
   'deposit/getAllDeposits',
   async (_, thunkAPI) => {
      try {
         const token = Cookies.get('token');
         return await depositService.getAllDeposits(token);
      } catch (error) {
         const message =
            (error.response &&
               error.response.data &&
               error.response.data.message) ||
            error.message ||
            error.toString();
         return thunkAPI.rejectWithValue(message);
      }
   }
);
// GET getTotalDepositRequest
export const getTotalDepositRequest = createAsyncThunk(
   'deposit/getTotalDepositRequest',
   async (_, thunkAPI) => {
      try {
         const token = Cookies.get('token');
         return await depositService.getTotalDepositRequest(token);
      } catch (error) {
         const message =
            (error.response &&
               error.response.data &&
               error.response.data.message) ||
            error.message ||
            error.toString();
         return thunkAPI.rejectWithValue(message);
      }
   }
);

// GET ALL BY ID
export const getDepositsById = createAsyncThunk(
   'deposit/getDepositsById',
   async (id, thunkAPI) => {
      try {
         const token = Cookies.get('token');
         return await depositService.getDepositsById(id,token);
      } catch (error) {
         const message =
            (error.response &&
               error.response.data &&
               error.response.data.message) ||
            error.message ||
            error.toString();
         return thunkAPI.rejectWithValue(message);
      }
   }
);

// GET USER DEPOSIT BY ID
export const getUserDepositsById = createAsyncThunk(
   'deposit/getUserDepositsById',
   async (id, thunkAPI) => {
      try {
         const token = Cookies.get('token');
         return await depositService.getUserDepositsById(id,token);
      } catch (error) {
         const message =
            (error.response &&
               error.response.data &&
               error.response.data.message) ||
            error.message ||
            error.toString();
         return thunkAPI.rejectWithValue(message);
      }
   }
);

// UPDATE STATUS DEPOSITS
export const updateStatus = createAsyncThunk(
   'deposit/updateStatus',
   async (data, thunkAPI) => {
      try {
         const token = Cookies.get('token');
         return await depositService.updateStatus(data,token);
      } catch (error) {
         const message =
            (error.response &&
               error.response.data &&
               error.response.data.message) ||
            error.message ||
            error.toString();
         return thunkAPI.rejectWithValue(message);
      }
   }
);

const depositSlice = createSlice({
   name: 'deposit',
   initialState,
   reducers: {
      reset: (state) => initialState,
   },
   extraReducers: (builder) => {
      builder
         .addCase(createDeposit.pending, (state) => {
            state.isLoading = true;
         })
         .addCase(createDeposit.fulfilled, (state, action) => {
            state.isLoading = false;
            state.isSuccess = true;
            state.deposit = action.payload;
         })
         .addCase(createDeposit.rejected, (state, action) => {
            state.isLoading = false;
            state.isError = true;
            state.message = action.payload;
         })
         .addCase(getAllDeposits.pending, (state) => {
            state.isLoading = true;
         })
         .addCase(getAllDeposits.fulfilled, (state, action) => {
            state.isLoading = false;
            state.isSuccess = true;
            state.deposits = action.payload;
         })
         .addCase(getAllDeposits.rejected, (state, action) => {
            state.isLoading = false;
            state.isError = true;
            state.message = action.payload;
         })
         .addCase(getTotalDepositRequest.pending, (state) => {
            state.isLoading = true;
         })
         .addCase(getTotalDepositRequest.fulfilled, (state, action) => {
            state.isLoading = false;
            state.isSuccess = true;
            state.deposits = action.payload;
         })
         .addCase(getTotalDepositRequest.rejected, (state, action) => {
            state.isLoading = false;
            state.isError = true;
            state.message = action.payload;
         })
         .addCase(getDepositsById.pending, (state) => {
            state.isLoading = true;
         })
         .addCase(getDepositsById.fulfilled, (state, action) => {
            state.isLoading = false;
            state.isSuccess = true;
            state.deposits = action.payload;
         })
         .addCase(getDepositsById.rejected, (state, action) => {
            state.isLoading = false;
            state.isError = true;
            state.message = action.payload;
         })
         .addCase(getUserDepositsById.pending, (state) => {
            state.isLoading = true;
         })
         .addCase(getUserDepositsById.fulfilled, (state, action) => {
            state.isLoading = false;
            state.isSuccess = true;
            state.deposits = action.payload;
         })
         .addCase(getUserDepositsById.rejected, (state, action) => {
            state.isLoading = false;
            state.isError = true;
            state.message = action.payload;
         })
         .addCase(updateStatus.pending, (state) => {
            state.isLoading = true;
         })
         .addCase(updateStatus.fulfilled, (state, action) => {
            state.isLoading = false;
            state.isSuccess = true;
            state.deposits = action.payload;
         })
         .addCase(updateStatus.rejected, (state, action) => {
            state.isLoading = false;
            state.isError = true;
            state.message = action.payload;
         });
   },
});

export const { reset } = depositSlice.actions;
export default depositSlice.reducer;
