import React, { useEffect } from 'react'
import DefaultLayout from '../components/DefaultLayout'
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getBonusBinaryDetail } from '../features/commission/commissionSlice';
import { Table } from 'antd';
import moment from 'moment';

const BonusBinaryDetail = () => {

    const location = useLocation();
    const navigate = useNavigate();
   const {date} = useParams()

   const { commissions, isError, isSuccess, message, isLoading } = useSelector(
    (state) => state.commission
 );

 console.log('commissions: ', commissions);
     
  const dispatch = useDispatch();

  useEffect(()=> {
    dispatch(getBonusBinaryDetail(date))
  },[])

  const columns = [
    {
      title: 'วันที่',
      dataIndex: 'date',
      width: '2%',
      key: 'date',
      render: (date) => (
         <div>
            {' '}
            <span style={{ fontSize: '14px' }}>
               <b>{moment(date).format('DD/MM/YYYY')}</b>
            </span>{' '}
         </div>
      ),
      sorter: (a, b) => moment(a.date).unix() - moment(b.date).unix(),
   },
    {
      title: 'PV',
      dataIndex: 'totalPv'
    }
  ]
 
  return (
  <DefaultLayout>
    <div>
      <Table/> 
    </div>
  </DefaultLayout>
  )
}

export default BonusBinaryDetail